import $ from 'jquery';
import React from 'react';
import {showCountrySelectionDialog} from 'app/components/country_selection';
import InvalidEmail from 'app/components/invalid_email';
import MessageNotification from 'app/components/message_notification';
import {showWarningMessage} from 'app/utils';
import {showTermsCommon} from 'app/utils/modals/popups';
import {showPopup} from 'app/utils/modals/ModalsService';
import {showAuthForm} from 'app/components/auth/auth';

const runCallbacks = () => {
    showAuthForm();

    if (window.WARNING_MESSAGE) {
        $(() => showWarningMessage());
    }
    if (window.TERMS_FLAG) {
        $(() => showTermsCommon());
    }
    if (window.INVALID_EMAIL_FLAG) {
        $(() => showPopup('invalid-email', props => <InvalidEmail popupId={props.popupId}/>));
    }
    if (window.USER_SELECT_COUNTRY) {
        $(() => showCountrySelectionDialog());
    }
    if (window.MESSAGE_NOTIFICATION) {
        $(() => showPopup('message-notification', props => <MessageNotification popupId={props.popupId}/>));
    }
};

export default runCallbacks;
