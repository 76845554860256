import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

export default class PokerMainMessage extends React.Component {
    render(): ReactNode {
        return <div className="poker-main-message">
            <div className="poker-main-message-content">
                <p dangerouslySetInnerHTML={{__html: I18n.t('poker.table_unavailable')}}></p>
            </div>
        </div>;
    }
}
