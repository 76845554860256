import React from 'react';
import AffiliatesLandingFaqItem from 'app/components/landing_affiliates/AffiliatesLandingFaqItem';

const FaqList = Array.from({length: 7}, (_, i) => ({
    a: `affiliate.faq_title_${i + 1}`,
    q: `affiliate.faq_description_${i + 1}`
}));

const AffiliatesLandingFaq: React.FC = () => <div className="landing-promo-faq-list">
    {FaqList.map((faqItem, id) => <AffiliatesLandingFaqItem faqItem={faqItem} key={id}/>)}
</div>;

export default AffiliatesLandingFaq;
