import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CountdownTimer from 'app/components/bet_form/timer';
import {CasinoFilterInterface} from 'app/interfaces/stores/CasinoGamesStoreInterfaces';
import I18n from 'app/i18n';
import {LazyLoading} from 'app/utils/lazyLoading/LazyLoading';
import {CasinoGamesController} from 'app/controllers/casinoGamesController';
import {inject, observer} from 'mobx-react';
import {CasinoGamesStore} from 'app/store/CasinoGamesStore';
import {CasinoFilterStore} from 'app/store/CasinoFilterStore';
import SmartLoader from 'app/components/reusable/SmartLoader';
import {runInAction} from 'mobx';
const COUNT_CASINO_GAME = 18;
const COUNT_CASINO_GAME_MOBILE = 4;
const mobileWidth = 480;

interface GroupContainerState {
    accordionAction: boolean
}

interface GroupContainerProps {
    show: boolean
    title: string
    opened?: boolean
    countGames?: number
    ended_at?: number
    filter?: CasinoFilterInterface
    children?: React.ReactNode
    casinoGamesStore?: CasinoGamesStore
}
const IMAGE_ONE = 1;
const IMAGE_TWO = 2;
const IMAGE_THREE = 3;
const LABEL_ONE = 1;
const LABEL_TWO = 2;

@inject('casinoGamesStore')
@observer
export default class GroupContainer extends Component<GroupContainerProps, GroupContainerState> {
    state = {
        accordionAction: this.props.opened
    };

    controller: CasinoGamesController;

    casinoFilterStore: CasinoFilterStore;

    rootElement: React.RefObject<HTMLDivElement> = React.createRef();

    componentDidMount(): void {
        if (this.props.filter) {
            runInAction(() => {
                this.casinoFilterStore = new CasinoFilterStore(this.props.filter.id);
                this.controller = new CasinoGamesController(this.props.casinoGamesStore, this.casinoFilterStore);
            });
        }
    }

    openAccardion = (): void => {
        this.setState({accordionAction: !this.state.accordionAction});
    };

    detectImage = (): string => {
        switch (this.props.filter && this.props.filter.background_image || 'default') {
        case IMAGE_ONE:
            return 'one';
        case IMAGE_TWO:
            return 'two';
        case IMAGE_THREE:
            return 'three';
        default: return 'default';
        }
    };

    textLabel = (count:number): string => {
        const {filter} = this.props;
        return filter[`text_label_${count}_langs`] &&
            filter[`text_label_${count}_langs`][I18n.locale] ||
            filter[`text_label_${count}`];
    };

    loadMoreGames = (): void => {
        if (this.props.filter) {
            this.casinoFilterStore.setLoading(true);
            this.controller.loadMoreGamesByFilters();
        }
    };

    renderLazyLoading(): React.ReactNode {
        const {accordionAction} = this.state;
        const {filter} = this.props;

        return <>
            { filter && accordionAction &&
                    <LazyLoading show={filter.has_more && filter && accordionAction}
                        callback={this.loadMoreGames}/>}
            { filter && this.casinoFilterStore?.loading && <SmartLoader/> }
        </>;
    }

    render(): React.ReactNode {
        const {accordionAction} = this.state;
        const {show, title, countGames, filter} = this.props;
        const viewGamesCount = window.innerWidth > mobileWidth ? COUNT_CASINO_GAME : COUNT_CASINO_GAME_MOBILE;
        const isAccordion = countGames < viewGamesCount ? ' accordion-power_small' : '';
        const backgroundImageSelect = this.detectImage() === 'default'
            ? ''
            : ` casino-game-group_${this.detectImage()}`;
        return <>
            {countGames > 0 &&
        <div className={`casino-game-group${backgroundImageSelect}`}>
            {show &&
            <>
                <div className="casino-game-group__top" ref={this.rootElement}>
                    <div className="left">
                        <p className="casino-game-group-name">{title}
                            {filter && filter.clickable_hint &&
                                <Link to={filter.clickable_hint} className="casino-game-group-about" target="_blank">
                                    <svg className="svg-default">
                                        <use xlinkHref="#svg-icon-casino-about"/>
                                    </svg>
                                </Link>
                            }
                        </p>
                        {
                            filter && (filter.ended_at || filter.text_label_1 || filter.text_label_2) &&
                            <div className="casino-game-group-list-labels">
                                {filter.ended_at > 0 && <span className="casino-game-group-label timer">
                                    <CountdownTimer time={filter.ended_at} plainText/>
                                </span>}
                                {filter.text_label_1 &&
                                <span className="casino-game-group-label">{this.textLabel(LABEL_ONE)}</span>}
                                {filter.text_label_2 &&
                                <span className="casino-game-group-label">{this.textLabel(LABEL_TWO)}</span>}
                            </div>
                        }
                    </div>
                    {isAccordion === '' &&
                    <button className={`casino-game-group__button${accordionAction ? ' active' : ''}`}
                        onClick={this.openAccardion}>
                        <span className="n">{countGames}</span>
                        <svg className="svg-default icon">
                            <use xlinkHref="#svg-arrow-for-menu"/>
                        </svg>
                    </button>}
                </div>
            </>}
            <div className={`accordion-power accordion-power_small${accordionAction || !show || isAccordion !== '' ? ' active' : ''}`}>
                {this.props.children}
            </div>
            {this.renderLazyLoading()}
        </div>}
        </>;
    }
}
