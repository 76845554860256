import React, {Component, ReactNode} from 'react';
import UserMenuBalance from 'app/components/user/menu_balance';
import UserBalanceProps from 'app/interfaces/UserBalanceInterface';

export default class UserBalance extends Component<UserBalanceProps> {
    render(): ReactNode {
        const res = <UserMenuBalance {...this.props}/>;
        return this.props.noWrap ? res : <div className="user-menu__balance">{res}</div>;
    }
}
