import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import {ShopsStoreProps} from 'app/store/ShopStore';
import {userStore} from 'app/store/global';
import {getClassNameActiveLink, getLinkPath} from 'app/utils/link_helper';
import I18n from 'app/i18n';
import {NavLink} from 'react-router-dom';
import {betMoney, faqButton, userSignedIn, scrollToTop} from 'app/utils';
import {PageContent} from 'app/components/page_content';
import SmartFullPageLoader from 'app/components/reusable/smart_loaders/SmartFullPageLoader';
import {UserStore} from 'app/store/UserStore';
import withRouter from 'app/utils/navigate/customWithRouter';
import {Outlet} from 'react-router';

interface Props extends ShopsStoreProps{
    location?: {
        pathname: string
    }
    userStore?: UserStore
    children?: React.ReactNode
}

const THIRTY_SECONDS = 30000;
const DELAY_SCROLL = 10;

@inject('shopStore', 'userStore')
@observer
class Shop extends Component<Props> {
    timer = null;

    componentDidMount(): void {
        const {location: {pathname}} = this.props;

        if (userSignedIn() && pathname === getLinkPath('/shop/my_purchases')) {
            this.openMyPurchases();
        } else {
            if (pathname === getLinkPath('/shop/lootboxes')) {
                this.openLootboxes();
            } else {
                this.openMoney();
            }
            this.startUpdateTimer();
        }
    }

    componentWillUnmount(): void {
        clearTimeout(this.timer);
        setTimeout(() => {
            scrollToTop();
        }, DELAY_SCROLL);
    }

    startUpdateTimer = (): void => {
        this.timer = setTimeout(() => {
            this.props.shopStore.updateShop(false).then(() => this.startUpdateTimer());
        }, THIRTY_SECONDS);
    };

    restartUpdateTimer = (url: string): void => {
        const {updateShop, setShopUrl} = this.props.shopStore;
        setShopUrl(url);
        updateShop(true);
    };

    openMoney = (): void => {
        this.restartUpdateTimer(null);
    };

    openLootboxes = (): void => {
        userSignedIn() && this.onClickHotHidden();
        this.restartUpdateTimer('/shop/lootboxes');
    };

    openMyPurchases = (): void => {
        const {loadMore, setPurchasesPage, setUserPurchases} = this.props.shopStore;
        setPurchasesPage(0);
        setUserPurchases([]);
        loadMore(true);
    };

    onClickHotHidden = (): void => {
        userStore.changeUserHotIconStatus();
    };

    render(): ReactNode {
        const {loading, data: {product_items, user: {points}}} = this.props.shopStore;
        const {location: {pathname}} = this.props;
        const {user} = this.props.userStore;
        return <div className="tabs shop-container">
            <div className="tabs__header js-custom-scroll">
                <div className="tabs__inner__header">
                    <NavLink
                        to={getLinkPath('/shop/money')}
                        onClick={this.openMoney}
                        className={getClassNameActiveLink}>
                        {I18n.t('money_header')}
                    </NavLink>
                    <NavLink
                        to={getLinkPath('/shop/lootboxes')}
                        onClick={this.openLootboxes}
                        className={getClassNameActiveLink}>
                        {I18n.t('lootboxes')}
                    </NavLink>
                    <NavLink
                        to={getLinkPath('/shop/marketplace')}
                        className={getClassNameActiveLink}>
                        {I18n.t('marketplace')}
                    </NavLink>
                    <NavLink
                        to={getLinkPath('/shop/other')}
                        className={getClassNameActiveLink}>
                        {I18n.t('other')}
                    </NavLink>
                    {product_items && product_items.length !== 0 &&
                    <NavLink
                        to={getLinkPath('/shop/items')}
                        className={getClassNameActiveLink}>
                        {I18n.t('items_header')}
                    </NavLink>
                    }
                    {userSignedIn() && <NavLink
                        to={getLinkPath('/shop/my_purchases')}
                        onClick={this.openMyPurchases}
                        className={getClassNameActiveLink}>
                        {I18n.t('my_purchases_header')}
                    </NavLink>}
                </div>
            </div>

            <>
                {loading
                    ? <SmartFullPageLoader/>
                    : <Outlet />}
            </>

            {points !== null && pathname !== getLinkPath('/shop/my_purchases') &&
            <div className="shop-footer">
                <div className="shop-footer__points">
                    <span className="number-points">
                        {I18n.t('user_points')}: {betMoney({}, user.points, true, true)}
                    </span>
                    {faqButton()}
                </div>
            </div>}
            {pathname === getLinkPath('/shop/marketplace') &&
            <PageContent contentUrl="/shop/marketplace/text_content" contentHolder contentKey="shop_skins"/>}
        </div>;
    }
}

export default withRouter(Shop);
