import React, {Component, ReactNode} from 'react';
import Draggable from 'react-draggable';
import I18n from 'app/i18n';
import PlayerInterface from 'app/interfaces/poker/PlayerInterface';
import {selectCard} from 'app/utils/poker/selectCard';

interface Props {
    onMininimize: () => void
    player: PlayerInterface
}

export default class PokerMinimize extends Component<Props> {
    renderStatus = (): ReactNode => {
        const {player} = this.props;

        if (player && player.cards && player.cards.length > 0) {
            return <div className="player-cards">
                <div className="player-card-first statics">
                    <div className="player-card-inner">
                        <span className="player-card-face-first"></span>
                        {selectCard(player.cards[0])}
                    </div>
                </div>
                <div className="player-card-second statics">
                    <div className="player-card-inner">
                        <span className="player-card-face-second"></span>
                        {selectCard(player.cards[1])}
                    </div>
                </div>
            </div>;
        }

        return <span className="poker-minimize-status">$0.05-$0.10</span>;
    };

    render(): ReactNode {
        const {onMininimize} = this.props;

        return <Draggable defaultPosition={{x: 240, y: 480}}>
            <div className="poker-draggable">
                <div className="poker-minimize">
                    <span className="poker-minimize-players">6</span>
                    <span className="poker-minimize-title">{I18n.t('poker.game')}</span>
                    <div className="poker-minimize-content">{this.renderStatus()}</div>
                    <button className="button-maximize" onClick={onMininimize}>
                        <svg className="svg-default">
                            <use xlinkHref="#svg-button-maximize"/>
                        </svg>
                    </button>
                </div>
            </div>
        </Draggable>;
    }
}
