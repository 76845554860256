import {translated} from 'app/utils/translations_helper';
import SendForm from './gift/SendForm';
import React from 'react';
import I18n from 'app/i18n';
import {fixed2, isGuest, userSignedIn} from 'app/utils';
import TimeToStartTimer from 'app/utils/TimeToStartTimer';
import {goToRoute} from 'app/utils/link_helper';
import SwapGift from '../gift/swap_gift';
import {B_DAY_GIFT, GIFT_BOX} from 'app/consts';
import {showPopup} from 'app/utils/modals/ModalsService';
import CountDownPromo from './count_down_promo';
import AsideItem from 'site_version/com/AsideItem';
import nextPrizeProgress from './modules/next_prize_progress';
import FAQButton from './modules/FAQButton';
import ClickableTooltip from 'app/components/reusable/ClickableTooltip';
import {openRegistration} from 'app/components/auth/auth';
import OpenGiftPopup from 'app/components/promo_action/gift/OpenGiftPopup';
import {Provider} from 'mobx-react';
import {promoActionStore} from 'app/store/global';
import {goToMyHero} from 'app/utils/app_links';
import {preloadImage} from 'app/utils/preloadImage';
import GiftLink from './GiftLink';

const handleGuestClick = func => isGuest()
    ? openRegistration()
    : func();


export const showSendForm = (userName, to_send) => {
    handleGuestClick(() => showPopup('send-form', props => <SendForm userName={userName} popupId={props.popupId} numberOfGifts={to_send}/>));
};

export const showSwapForm = userName => {
    handleGuestClick(() => showPopup('swap-gift', props => <SwapGift userName={userName} popupId={props.popupId}/>));
};

export const giftOpening = (promoAction, e) => {
    e && e.preventDefault();
    handleGuestClick(() => showPopup('gift-popup', props => <OpenGiftPopup promoAction={promoAction} popupId={props.popupId}/>));
};

const openGiftFunction = e => {
    giftOpening('PromoActionPresent', e);
};

export class PromoActionPresent extends CountDownPromo {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            converted: false,
            time_to_end: props.time_to_end,
            time_to_start: props.time_to_start
        };
        this.FAQPath = '/faq/gifts';
    }

    componentDidMount() {
        super.componentDidMount();

        preloadImage(GIFT_BOX);
    }

    scatterText = () => I18n.t('promo_action_present.scatter.promo_text');

    footer = () => {
        let timeContent = null;

        if (!this.isStarted()) {
            timeContent = <>
                <span>{I18n.t('start_in')}:</span>
                <TimeToStartTimer time_to_start={this.state.time_to_start} countdown/>
            </>;
        } else if (userSignedIn() && this.isEndingPromo()) {
            timeContent = <>
                <span>{I18n.t('end_of_the_promotion')}:</span>
                <TimeToStartTimer time_to_start={this.state.time_to_end} countdown/>
            </>;
        } else {
            return null;
        }

        return <div className="b-day-aside__time">
            <div className="b-day-aside__time-content">
                {timeContent}
            </div>
        </div>;
    };

    nextPrizeProgress = () => nextPrizeProgress({
        ...this.props,
        isStarted: this.isStarted
    });

    static get promoUrl() {
        return `/promotions/${promoActionStore.currentPromoAction().PromoActionPresent.url}`;
    }

    onClick = () => this.isStarted() ? openGiftFunction() : goToRoute(this.constructor.promoUrl);

    header = count_of_opened_gifts => <div>
        <div className="b-day-aside__title aside-item-heading__title">
            <h3>{translated(this.props, 'promo_action_name', 'promo_action_name_translations')}</h3>
            <FAQButton FAQPath={this.FAQPath}/>
        </div>
        {this.isStarted() && userSignedIn() && <div className="b-day-aside__balance">
            <span key="balance">{fixed2(this.nextPrizeProgress())}%</span>
            <span>{I18n.t('gifts.opened', {count: count_of_opened_gifts})}</span>
        </div>}
    </div>;

    render() {
        const {to_send, to_swap, to_open, count_of_opened_gifts, quantity_in_swap} = this.props;
        const ConvertButton = promoActionStore.convertButton;

        return !this.isEndedPromo() && <AsideItem
            header={this.header(count_of_opened_gifts)}
            itemClassName={'b-day-aside'}
            footer={this.footer}>

            <div className={`b-day-aside-content ${this.isStarted() ? 'b-day-aside-content_started' : ''}`}>
                <div className="b-day-aside__gift-wrap">
                    <div className="b-day-aside__gift" onClick={this.onClick}>
                        <div className="b-day-aside__box">
                            <div className="b-day-aside__box-empty"/>
                            <div className="b-day-aside__box-shape">
                                <div className="b-day-aside__box-shadow"/>
                            </div>
                            <div
                                style={{bottom: `${this.nextPrizeProgress()}%`}}
                                className="b-day-aside__box-full">
                                <img
                                    src={B_DAY_GIFT.BACKGROUND}
                                    alt="Gift background"
                                />
                            </div>
                        </div>
                        <div className="b-day-aside__ribbon">
                            <img
                                src={B_DAY_GIFT.RIBBON}
                                alt="Gift ribbon"
                            />
                        </div>
                    </div>
                </div>
                {this.isStarted() && <div className="b-day-aside__links">
                    <div className="b-day-aside__link">
                        <svg height="16px" width="16px">
                            <use xlinkHref="#svg-send-gift"/>
                        </svg>
                        <GiftLink
                            title={I18n.t('send_gift')}
                            count={to_send}
                            enabled={!userSignedIn()}
                            onClickHandler={() => showSendForm(null, to_send)}/>
                    </div>
                    <div className="b-day-aside__link">
                        <svg height="16px" width="16px">
                            <use xlinkHref="#svg-exchange-gift"/>
                        </svg>
                        <GiftLink
                            title={I18n.t('swap_gift')}
                            count={to_swap}
                            enabled={to_send || quantity_in_swap || !userSignedIn()}
                            onClickHandler={showSwapForm}/>
                    </div>
                    <div className="b-day-aside__link">
                        <svg height="16px" width="16px">
                            <use xlinkHref="#svg-open-gift"/>
                        </svg>
                        <GiftLink
                            title={I18n.t('open')}
                            count={to_open}
                            enabled={!userSignedIn()}
                            onClickHandler={openGiftFunction}/>
                    </div>
                    <div className="b-day-aside__link">
                        <svg height="16px" width="16px">
                            <use xlinkHref="#svg-scatter"/>
                        </svg>
                        <Provider promoActionStore={promoActionStore}>
                            <ConvertButton onClickHandler={goToMyHero} classes={['b-day-aside__scatter-btn']}/>
                        </Provider>
                        <ClickableTooltip text={this.scatterText()}/>
                    </div>
                </div>}
            </div>
        </AsideItem>;
    }
}
