import React, {ReactNode} from 'react';
import DepositBonusTypeButton from './DepositBonusTypeButton';
import {inject, observer} from 'mobx-react';
import {DepositsStoreProps} from 'app/store/DepositsStore';
import I18n from 'app/i18n';
import Post from 'app/utils/Post';
import {toastError} from 'app/components/toasts/liteToast';

export const BETS_BONUS_TYPE = 0;
export const CASINO_BONUS_TYPE = 1;

interface Props extends DepositsStoreProps {
    cryptodeposit?: boolean
}

@inject('depositsStore')
@observer
export default class SelectBonusType extends React.Component<Props> {
    onClick = (selected_bonus: number): void => {
        if (this.props.cryptodeposit) {
            this.onClickCryptoDeposit(selected_bonus);
        } else {
            this.props.depositsStore.updateBonusType(selected_bonus);
        }
    };

    onClickCryptoDeposit = (bonus: number): void => {
        new Post({
            params: {bonus},
            url: '/deposits/crypto_deposit/change_bonus'
        })
            .execute()
            .then(data => data.json())
            .then(data => {
                if (data.success) {
                    this.props.depositsStore.updateBonusType(bonus);
                } else {
                    toastError(I18n.t('error_on_change_bonus'));
                }
            });
    };

    handleClickBetsBonusType = (): void => {
        this.onClick(BETS_BONUS_TYPE);
    };

    handleClickCasinoBonusType = (): void => {
        this.onClick(CASINO_BONUS_TYPE);
    };

    render(): ReactNode {
        const {bonus_type} = this.props.depositsStore;

        return <div className="user-deposit-promotions">
            <span className="form__row-label">{I18n.t('promo_bonus_deposit_title')}</span>
            <div className="list-promotions">
                <DepositBonusTypeButton
                    itemClassName="user-promotion-promo bets"
                    svgIconGameType="svg-tap_bar_play_adaptive"
                    localePromotionTitle="promo_bonus_bets_title"
                    svgIconName="first-deposit"
                    localePromotionText="promo_bonus_bets_text"
                    bannerType="promo"
                    active={bonus_type === BETS_BONUS_TYPE}
                    onClick={this.handleClickBetsBonusType}
                />
                <DepositBonusTypeButton
                    itemClassName="user-promotion-promo casino"
                    localePromotionTitle="promo_bonus_casino_title"
                    svgIconName="first-deposit"
                    svgIconGameType="svg-tap_bar_play_casino"
                    localePromotionText="promo_bonus_casino_text"
                    bannerType="promo"
                    active={bonus_type === CASINO_BONUS_TYPE}
                    onClick={this.handleClickCasinoBonusType}
                />
            </div>
        </div>;
    }
}
