import {action, makeObservable, observable} from 'mobx';
import {getLinkPath, newsArticles, promotionArticles} from 'app/utils';
import Get from 'app/utils/Get';
import PaginateInterface from 'app/interfaces/articles/PaginateInterface';
import ArticleInterface from 'app/interfaces/articles/ArticleInterface';
import ParamsDataInterface from 'app/interfaces/articles/ParamsDataInterface';
import ArticleIndexInterface from 'app/interfaces/articles/ArticleIndexInterface';
import {urlName} from 'app/utils/articles/urlName';
import {updateOrAddObjectToStart} from 'app/utils/updateArraysObject';
import {getLanguageStorage} from 'app/utils/Language';
import {MainArticlesPayLoadInterface} from 'app/interfaces/stores/subcribe/MainArticlesPayLoadInterface';
import {ELEMENT_NOT_FOUND} from 'app/utils/Consts';

interface ArticlePageInterface {
    data: ArticleInterface[]
    loading?: boolean
    paginate: PaginateInterface
    searchValue: string
}

interface PromoNewInterface {
    hot_promo: ArticleInterface[]
    casino_promo: ArticleInterface[]
    permanent_promo: ArticleInterface[]
    loading?: boolean
}

const ARTICLE_TYPE_HOT_PROMO = 0;

const ARTICLE_TYPE_CASINO_PROMO = 1;

const ARTICLE_TYPE_PERMANENT_PROMO = 2;

export class ArticlesStore {
    constructor() {
        makeObservable(this);
    }

    defaultArticleObject = {
        data: [],
        loading: false,
        paginate: {current_page: 1, per_page: 1, total_entries: 0, total_pages: 1},
        searchValue: ''
    };

    @observable blogData: ArticlePageInterface = this.defaultArticleObject;

    @observable newsData: ArticlePageInterface = this.defaultArticleObject;

    @observable promotionOldData: ArticlePageInterface = this.defaultArticleObject;

    @observable hiddenTempleData: ArticlePageInterface = this.defaultArticleObject;

    @observable careersData: ArticlePageInterface = this.defaultArticleObject;

    @observable promoNewData: PromoNewInterface = {
        casino_promo: [],
        hot_promo: [],
        loading: false,
        permanent_promo: []
    };

    @observable activePromoNewCount: number = 0;

    @observable homePageArticles: ArticleInterface[] = [];

    @observable sidebarArticles: ArticleInterface[] = [];

    @observable casinoPromoArticles: ArticleInterface[] = [];

    @observable loadingMainArticles = true;

    fields = {
        blog: 'blogData',
        careers: 'careersData',
        hidden_temple: 'hiddenTempleData',
        hidden_temples: 'hiddenTempleData',
        news: 'newsData',
        promotions_old: 'promotionOldData'
    };

    subscription = null;

    @action
    init = (params: ParamsDataInterface, type: string, search = ''): void => {
        this.loadData(params, search, type);
    };

    @action
    initPromoNew = (search = ''): void => {
        this.loadPromoNewData(search);
    };

    @action
    update = (data: ArticlePageInterface, type: string): void => {
        this.setData(data, type);
    };

    @action
    setData = (data: ArticlePageInterface, type: string): void => {
        this[this.fields[type]] = data;
    };

    @action
    setPromoNewData = (data: PromoNewInterface): void => {
        const {hot_promo, casino_promo, permanent_promo} = data;
        this.activePromoNewCount = hot_promo.length + casino_promo.length + permanent_promo.length;
        this.promoNewData = data;
    };

    @action
    setSearchValue = (value: string, type: string): void => {
        this[this.fields[type]].searchValue = value;
    };

    @action
    setLoadingStatus = (status: boolean, type: string): void => {
        this[this.fields[type]].loading = status;
    };

    @action
    setPromoNewLoadingStatus = (status: boolean): void => {
        this.promoNewData.loading = status;
    };

    setToUserLocale = (article: ArticleInterface): void => {
        const language = getLanguageStorage() === 'ru' ? 'ru' : 'en';
        article.title = article.title_locales[language];
        article.pre_content = article.pre_content_locales[language];
    };

    @action
    setHomePageArticles = (articles: ArticleInterface[]): void => {
        articles.forEach(article => this.setToUserLocale(article));
        this.homePageArticles = articles;
    };

    @action
    setSidebarArticles = (articles: ArticleInterface[]): void => {
        articles.forEach(article => this.setToUserLocale(article));
        this.sidebarArticles = articles;
    };

    @action
    setCasinoPromoArticles = (articles: ArticleInterface[]): void => {
        const casinoArticles: ArticleInterface[] = [];
        Object.keys(articles).forEach(key => {
            casinoArticles.push(...articles[key].filter((article: ArticleInterface) => article?.casino));
        });
        this.casinoPromoArticles = casinoArticles;
    };

    @action
    addOrReplace = (arr: ArticleInterface[], newObj: ArticleInterface): ArticleInterface[] => [...arr.filter(o => o.id !== newObj.id), {...newObj}];

    @action
    updateElementCollection(item: ArticleInterface, data: ArticleInterface[]): void {
        this.setToUserLocale(item);
        if (item.article_type === 'promotion' || item.article_type === 'news') {
            if (item.deleted) {
                this.homePageArticles = this.homePageArticles.filter(a => a.id !== item.id);
            } else if (item.main_image) {
                this.homePageArticles = this.addOrReplace(this.homePageArticles, item)
                    .sort((a, b) => a.priority > b.priority ? 1 : ELEMENT_NOT_FOUND);
            }
        }
        switch (item.promo_type) {
        case ARTICLE_TYPE_HOT_PROMO:
            updateOrAddObjectToStart(this.promoNewData, 'hot_promo', item);
            break;
        case ARTICLE_TYPE_CASINO_PROMO:
            updateOrAddObjectToStart(this.promoNewData, 'casino_promo', item);
            break;
        case ARTICLE_TYPE_PERMANENT_PROMO:
            updateOrAddObjectToStart(this.promoNewData, 'permanent_promo', item);
            break;
        default:
            if (data) {
                updateOrAddObjectToStart(data, 'data', item);
            }
            break;
        }
    }

    getDataByType = (type: string): ArticlePageInterface => this[this.fields[type]];

    getArticlesToAside(type: string): ArticleInterface[] {
        if (type === 'news') {
            return newsArticles(this.sidebarArticles);
        } else if (type === 'promotions') {
            return promotionArticles(this.sidebarArticles);
        }
        return [];
    }

    loadArticleByTypeAndId = (type: string, id: string, setData: (data: ArticleIndexInterface) => void): void => {
        new Get({params: {json: true}, url: getLinkPath(`/${type}/${id}`)})
            .execute()
            .then(response => response.json())
            .then(response => setData(response));
    };

    @action
    setLoadingMainArticles = (loading: boolean): void => {
        this.loadingMainArticles = loading;
    };

    loadMainArticles = (): void => {
        new Get({params: {json: true}, url: getLinkPath('/get_main_articles')})
            .execute()
            .then(response => response.json())
            .then(response => {
                this.setHomePageArticles(response.home_page);
                this.setSidebarArticles(response.sidebar);
            }).finally(() => {
                this.setLoadingMainArticles(false);
            });
    };

    loadData = (params: ParamsDataInterface, search: string, type: string): void => {
        this.setLoadingStatus(true, type);
        new Get({params: {...params, search}, url: urlName(type)})
            .execute()
            .then(response => response.json())
            .then(response => {
                this.setData({
                    data: response.data,
                    loading: false,
                    paginate: response.paginate,
                    searchValue: search
                }, type);
            });
    };

    loadPromoNewData = (search: string): void => {
        this.setPromoNewLoadingStatus(true);
        new Get({params: {search}, url: getLinkPath('/promotions')})
            .execute()
            .then(response => response.json())
            .then(response => {
                this.setPromoNewData(response);
                this.setCasinoPromoArticles(response);
                this.setPromoNewLoadingStatus(false);
            });
    };

    setEmptyPromoNewData = (): void => {
        this.setPromoNewData({casino_promo: [], hot_promo: [], loading: false, permanent_promo: []});
    };

    listenMain = (payload: MainArticlesPayLoadInterface): void => {
        if (payload.update_article &&
            (!payload.update_article.single_locale ||
                payload.update_article.single_locale === getLanguageStorage())) {
            this.updateElementCollection(payload.update_article,
                this[this.fields[payload.update_article.article_type]]);
        }
    };
}
