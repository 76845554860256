import React, {PureComponent} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ToastOptions} from 'react-toastify/dist/types';
import ToastInterface from 'app/interfaces/ToastInterface';
import ToastContent from 'app/components/toasts/Contents/ToastContent';
import {showBetForm} from '../table';
import {streamStore} from 'app/store/global';
import logEvent from 'app/amplitude/log_event';
import {checkingPages, scrollToTop} from 'app/utils';
import NotificationStore from 'app/store/notifications';
import {giftOpening} from '../promo_action/gift';
import musicPlayer from '../sounds';
import {CloseButton} from 'app/components/toasts/CloseButton';
import {MAIN_CONTAINER_ID} from 'app/utils/Consts';
import {
    goToLinkMessages,
    goToLinkMySimpleBets,
    goToLinkSimpleBets,
    goToMyHeroItem,
    goToMySteamTrade,
    goToNews,
    goToPrizeLootbox,
    goToPromo,
    goToMyProfileSettings,
    goToLinkSport
} from 'app/utils/app_links';
import openHelpDeskSupport from 'app/utils/openHelpDeskSupport';

export const NOTIFICATION_INTERACTION = 'NOTIFICATION_INTERACTION';

export default class BaseToast extends PureComponent<ToastInterface> {
    rootToastElement: React.RefObject<ToastContent<unknown>> = React.createRef();

    componentDidMount(): void {
        this.notify();
    }

    componentWillUnmount = (): void => {
        this.rootToastElement.current && this.rootToastElement.current.props.closeToast();
        this.onItemClose(this.props.data.key);
    };

    playSound = (): void => {
        if (this.props.sound) {
            musicPlayer.play(this.props.sound);
        }
    };

    closeButton = (): React.ReactElement => <CloseButton data={this.props.data}/>;

    onItemClose = (key: string): void => {
        NotificationStore.removeActiveKeys(key);
    };

    options = (options?: ToastOptions): ToastOptions => ({
        closeButton: this.closeButton(),
        onClick: this.onClick,
        onOpen: this.playSound,
        ...options
    });

    openGiftFunction = (): void => {
        giftOpening('PromoActionPresent');
    };

    notify = (): React.ReactText => toast.info(<ToastContent
        ref={this.rootToastElement}
        content={this.props.content}>
        <use xlinkHref="#svg-notif-system"/>
    </ToastContent>, this.options({containerId: MAIN_CONTAINER_ID}));

    logEvent = (): void => {
        logEvent(NOTIFICATION_INTERACTION, {
            interaction: 'tapped',
            types: this.props.data.type
        });
    };

    // eslint-disable-next-line complexity
    onClick = (): void => {
        if (this.props.data && this.props.data.type) {
            this.logEvent();
            switch (this.props.data.type) {
            case 'multiple_bets_limit_have_been_enabled':
                showBetForm(this.props.data);
                break;
            case 'multiple_bet_limit_increased':
                showBetForm(this.props.data);
                break;
            case 'line_limit_increased':
                showBetForm(this.props.data);
                break;
            case 'bet_win':
                goToLinkMySimpleBets();
                break;
            case 'artifact_bet_win':
                goToLinkMySimpleBets();
                break;
            case 'new_line_added':
                showBetForm(this.props.data);
                break;
            case 'item_received':
                goToMyHeroItem(this.props.data.data.recipe_id);
                break;
            case 'steam_skin_purchased':
                goToMySteamTrade(this.props.data.data.steam_trade_id);
                break;
            case 'promo_action_gift':
                this.openGiftFunction();
                break;
            case 'new_message_received':
                goToLinkMessages();
                break;
            case 'notify_bonus_issued':
                goToLinkSport();
                break;
            case 'news_article_added':
                goToNews(this.props.data.data.slug);
                break;
            case 'promo_article_added':
                goToPromo(this.props.data.data.slug);
                break;
            case 'new_stream_added':
                logEvent('STREAM_OPENED');
                if (checkingPages.isHomePage()) {
                    goToLinkSimpleBets();
                }
                streamStore.chooseStream(this.props.data.data);
                break;
            case 'leave_lootbox_page':
                goToPrizeLootbox();
                break;
            case 'giveaway_confirm_user':
                goToMyProfileSettings();
                break;
            case 'suggestion_not_accepted':
                goToLinkMySimpleBets();
                break;
            case 'suggestion_placed':
                goToLinkMySimpleBets();
                break;
            case 'disabled_country':
                openHelpDeskSupport();
                break;
            default:
            }
            scrollToTop();
        }
    };

    render(): JSX.Element {
        // Need to remove this code
        return <div/>;
    }
}
