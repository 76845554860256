import React, {Component, MouseEventHandler} from 'react';
import I18n from 'app/i18n';
import SmartSelect from 'app/components/reusable/SmartSelect';
import logEvent from 'app/amplitude/log_event';
import {showCasinoSignUp} from 'app/utils/IsRegisterUser';
import {inject, observer} from 'mobx-react';
import {CasinoGamesStoreProps} from 'app/interfaces/stores/CasinoGamesStoreInterfaces';

interface ThirdLevelSortInterface {
    clearSearch: () => void
    scrollToFilter?: (forceScroll?: boolean) => void
}

@inject('casinoGamesStore')
@observer
export default class ThirdLevelSort extends Component<CasinoGamesStoreProps & ThirdLevelSortInterface> {
    selectProviders = (ids: string[]): void => {
        if (showCasinoSignUp()) {
            return;
        }
        this.props.casinoGamesStore.selectProviders(ids);
        this.props.clearSearch();
        this.props.scrollToFilter(true);
    };

    logEventSelectProviders = (id: string): void => {
        logEvent('CASINO_FILTER_SET', {
            filter_type: 'game',
            value: this.props
                .casinoGamesStore.casinoProviders
                .find(item => item.id === parseInt(id, 10)).name
        });
    };

    scrollToFilterOnClick: MouseEventHandler<HTMLDivElement> = (): void => {
        this.props.scrollToFilter();
    };

    render(): React.ReactNode {
        const {filteredParams,
            casinoProviders} = this.props.casinoGamesStore;
        return <div className="casino-filter__section section-selects" onClick={this.scrollToFilterOnClick}>
            <div className="casino-smart-selects">
                <SmartSelect
                    multiple
                    collection={casinoProviders.map(provider => ({
                        text: provider.title || provider.name,
                        text2: '',
                        value: provider.id
                    }))}
                    onChange={this.selectProviders}
                    eventWithSelect={this.logEventSelectProviders}
                    value={filteredParams.provider}
                    placeholder_text_multiple={I18n.t('all_providers')}
                    search
                />
            </div>
        </div>;
    }
}
