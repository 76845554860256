import React, {ReactNode, ChangeEvent} from 'react';
import I18n from 'app/i18n';
import {fixed2} from 'app/utils/fixed';
import {StateParamsInterface} from 'app/store/PokerStore';

interface Props {
    onCancelClick: () => void
    onSitIn: (position: number, amount: number) => void
    onBuyIn: (amount: number) => void
    params: StateParamsInterface
    isSitIn: boolean
}

interface State {
    amount: string | number
}

export default class PokerPopupJoinTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            amount: 10
        };
    }

    onJoin = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        const {onSitIn, onBuyIn, isSitIn, params} = this.props;
        const amount = Number(this.state.amount);

        if (isSitIn) {
            onSitIn(params.position, amount);
            return;
        }

        onBuyIn(amount);
    };

    onChange = (event: ChangeEvent<HTMLInputElement>): void => this.changeAmount(event.target.value);

    changeAmount = (amount: string | number, isFixed = false): void => {
        const regex = /[^0-9.]/u;

        if (regex.test(amount.toString())) {
            return;
        }

        this.setState({amount: isFixed ? fixed2(amount) : amount});
    };

    render(): ReactNode {
        return <div className="poker-popup">
            <div className="poker-popup-content">
                <p>{I18n.t('poker_buy_in')}</p>
                <div className="poker-popup-input">
                    <input
                        onChange={this.onChange}
                        value={this.state.amount}
                        type="text"
                        placeholder="10.00$"
                        className="field__input field__input_text"
                    />
                </div>
                <div className="poker-popup-buttons">
                    <button
                        className="button-adaptive button-adaptive_casino"
                        onClick={this.onJoin}
                    >
                        {I18n.t('poker_join_table')}
                    </button>
                    <button
                        className="button-adaptive button-adaptive_casino"
                        onClick={this.props.onCancelClick}
                    >
                        {I18n.t('user.cancel_label')}
                    </button>
                </div>
            </div>
        </div>;
    }
}
