import {IncubatorGameAttributes} from 'app/models/IncubatorGameModel';

const DEFAULT_COEF = '1.860';


export const incubatorGameAttributesDefault = (lineId: number,
    game_id?: number,
    esports?: boolean):IncubatorGameAttributes => ({
    accepted_bets: 0,
    coef1_win: '',
    coef2_win: '',
    coef_1: lineId ? '' : DEFAULT_COEF,
    coef_2: lineId ? '' : DEFAULT_COEF,
    coef_changing: 1.5,
    color: 0,
    consider_personal_limits: true,
    date: 0,
    delay_for_bets_time: 10,
    esports,
    game_id: game_id ? game_id : 0,
    gamer_1: {
        country_code: '',
        nickname: '',
        race: ''
    },
    gamer_1_score: 0,
    gamer_2: {
        country_code: '',
        nickname: '',
        race: ''
    },
    gamer_2_score: 0,
    hidden: false,
    hide_creator: true,
    id: lineId ? lineId : null,
    lines_type_id: null,
    max: 0,
    max_high_limit: 0,
    pledge: '',
    status: 0,
    stream_link: '',
    user_bets_sum_1: '0',
    user_bets_sum_2: '0',
    winner: 0
});
