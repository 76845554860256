import React from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from 'app/components/toasts/Contents/ToastContent';
import {MAIN_CONTAINER_ID} from 'app/utils/Consts';

const toastError = (message: string): React.ReactText => toast.error(<ToastContent
    content={message}>
    <use xlinkHref="#svg-notif-error"/>
</ToastContent>, {containerId: MAIN_CONTAINER_ID});

const toastErrorNotify = (message: string, options?: object): React.ReactText => toast.error(<ToastContent
    content={message}>
    <use xlinkHref="#svg-notif-error"/>
</ToastContent>, {autoClose: false,
    closeButton: true,
    closeOnClick: true,
    containerId: MAIN_CONTAINER_ID,
    ...options});

const toastSuccess = (message: string): React.ReactText => toast.success(<ToastContent
    content={message}>
    <use xlinkHref="#svg-notif-done"/>
</ToastContent>, {containerId: MAIN_CONTAINER_ID});

const toastInfo = (message: string): React.ReactText => toast.info(<ToastContent
    content={message}>
    <use xlinkHref="#svg-notif-system"/>
</ToastContent>, {containerId: MAIN_CONTAINER_ID});

export {toastError, toastSuccess, toastInfo, toastErrorNotify};
