import React, {Component} from 'react';
import ShopItems from 'app/components/shop/lootboxes/shop_items';
import {ShopStore} from 'app/store/ShopStore';
import {Store} from 'app/store';
import {Outlet} from 'react-router';
import {inject, observer} from 'mobx-react';
import withRouter from 'app/utils/navigate/customWithRouter';
import EmptyShopResult from 'app/components/shop/lootboxes/EmptyShopResult';
import {isUSD} from 'app/utils/ShopItemPriceFormat';
import ShopItemInterface from 'app/interfaces/shop/ShopItemInterface';

interface CasinoLootboxesInterface {
    currency: string;
    isLootboxDetailPage: boolean;
    setLootboxDetailPage: (value: boolean)=>void;
    setCurrencyUSD: ()=>void;
    shopStore?: ShopStore;
    location?: {
        pathname: string
    }
}

interface CasinoLootboxesState {
    isLootboxDetailPage: boolean;
}

const updateShop = (): void => Store.updateShop();

class CasinoLootboxes extends Component<CasinoLootboxesInterface, CasinoLootboxesState> {
    state = {
        isLootboxDetailPage: this.props.isLootboxDetailPage || false
    };

    componentDidMount(): void {
        this.props.shopStore.setShopUrl('/shop/lootboxes');
        this.props.shopStore.updateShop(true);
    }

    componentDidUpdate(prevProps: CasinoLootboxesInterface): void {
        const {location: {pathname}, setLootboxDetailPage} = this.props;

        if (prevProps.location.pathname !== pathname) {
            const isLootboxPath = pathname.includes('lootboxes');
            setLootboxDetailPage(isLootboxPath);
            this.setState({isLootboxDetailPage: isLootboxPath});
        }
    }

    componentWillUnmount(): void {
        this.props.setLootboxDetailPage(false);
        this.setState({isLootboxDetailPage: false});
        this.props.setCurrencyUSD();
    }

    getFilteredLootboxes(): Array<ShopItemInterface> {
        return this.props.shopStore.data.lootboxes?.Casino?.filter(
            item => Number(item[isUSD(this.props.currency) ? 'cost_money' : 'cost_points']) > 0
        );
    }

    renderLootboxes = (lootboxes: Array<ShopItemInterface>): React.ReactNode => {
        if (lootboxes.length === 0) {
            return <EmptyShopResult currency={this.props.currency} />;
        }

        return <ShopItems
            currency={this.props.currency}
            user={this.props.shopStore.data.user}
            lootboxes={lootboxes}
            noChunks
            isCasinoPage
            afterBuy={updateShop}
        />;
    };

    render(): React.ReactNode {
        const lootboxes = this.getFilteredLootboxes() ?? [];


        return <div className="casino-lootboxes">
            {this.state.isLootboxDetailPage
                ? <Outlet />
                : this.renderLootboxes(lootboxes)
            }
        </div>;
    }
}

export default withRouter(inject('shopStore')(observer(CasinoLootboxes)));
