import LinesService from 'app/lines/LinesService';
import {ActiveSurveyStore} from 'app/store/ActiveSurveyStore';
import {CashoutsStore} from 'app/store/CashoutsStore';
import {IncubatorStore} from 'app/store/IncubatorStore';
import {ScreenStore} from 'app/store/ScreenStore';
import {TableStore} from 'app/store/TableStore';
import {ArtifactStore} from 'app/store/ArtifactStore';
import {RecipeStore} from 'app/store/artifact/recipe';
import {UserStore} from 'app/store/UserStore';
import {PromoActionStore} from 'app/store/PromoActionStore';
import {SteamTradeStore} from 'app/store/SteamTradeStore';
import {StoriesStore} from 'app/store/StoriesStore';
import {StreamStore} from 'app/store/stream_store';
import {ArticleStore} from 'app/store/article';
import {UserBetsStore} from 'app/store/UserBetsStore';
import {P2pDepositsStore} from 'app/store/P2pDepositsStore';
import {NotificationSettingsStore} from 'app/store/NotificationSettingsStore';
import {ShopLootboxStore} from 'app/store/ShopLootboxStore';
import {BuyOrderStore} from 'app/store/artifact/buy_order';
import {OrdersStore} from 'app/store/artifact/order';
import {SellOrderStore} from 'app/store/artifact/sell_order';
import {SiteNotificationStore} from 'app/store/SiteNotificationStore';
import {PageContentStore} from 'app/store/PageContentStore';
import {SmileStore} from 'app/store/smile_store';
import {CasinoGamesStore} from 'app/store/CasinoGamesStore';
import {GiveawayStore} from 'app/store/GiveawayStore';
import {ArticlesStore} from 'app/store/ArticlesStore';
import {ChatStore} from 'app/store/ChatStore';
import {ShopStore} from 'app/store/ShopStore';
import {BetSlipStore} from 'app/store/BetSlipStore';
import {ArtifactBetStore} from 'app/store/ArtifactBetStore';
import DepositsStore from 'app/store/DepositsStore';
import {currentTimeStore} from 'stores/global';
import {GamesStore} from 'app/store/GamesStore';
import {IncubatorShiftStore} from 'app/store/IncubatorShiftStore';
import {IncubatorUserLinesStore} from 'app/store/IncubatorUserLinesStore';
import {FlagsStore} from 'app/store/FlagsStore';
import {UserBonusesStore} from 'app/store/UserBonusesStore';
import {ModalsStore} from './ModalsStore';
import {BetsFilterStore} from './filters/BetsFilterStore';
import {ResultsFilterStore} from './filters/ResultsFilterStore';
import {IncubatorFilterStore} from './filters/IncubatorFilterStore';
import {OtherItemStore} from 'app/store/OtherItemStore';
import {FaqStore} from 'app/store/FaqStore';
import {PokerStore} from 'app/store/PokerStore';

export const cashoutsStore = new CashoutsStore();

export const artifactStore = new ArtifactStore();
if (window.USER_UI_DATA.user_id) {
    artifactStore.updateTradeEnabled({
        trade_enabled: window.USER_UI_DATA.trade_enabled,
        trade_token_link: window.SITE_DATA.TRADE_TOKEN_LINK
    });
    artifactStore.updatePublicInventory(window.USER_UI_DATA.public_inventory);
}
export const casinoGamesStore = new CasinoGamesStore();

export const recipeStore = new RecipeStore();

export const promoActionStore = new PromoActionStore();

export const steamTradeStore = new SteamTradeStore();

export const streamStore = new StreamStore();

export const articleStore = new ArticleStore();

export const userBonusesStore = new UserBonusesStore();

export const userStore = new UserStore({
    messagesCount: window.settings.user.messages_count,
    user: {
        ...window.settings.user,
        ...window.USER_UI_DATA
    }
}, {
    artifactStore,
    steamTradeStore,
    userBonusesStore
});

export const storiesStore = new StoriesStore(window.STORIES, userStore);

export const flagsStore = new FlagsStore();

export const userBetsStore = new UserBetsStore();

export const p2pDepositsStore = new P2pDepositsStore();

export const notificationSettingsStore = new NotificationSettingsStore();

export const shopLootboxStore = new ShopLootboxStore();

export const otherItemStore = new OtherItemStore();

export const buyOrderStore = new BuyOrderStore();

export const orderStore = new OrdersStore();

export const sellOrderStore = new SellOrderStore();

export const siteNotificationStore = new SiteNotificationStore();

export const pageContentStore = new PageContentStore();

export const smileStore = new SmileStore();

export const incubatorUserLinesStore = new IncubatorUserLinesStore();

export const linesService = new LinesService({
    currentTimeStore,
    url: '/bets',
    userStore
});

export const gamesStore = new GamesStore(window.SITE_DATA.FILTERS);

export const betsFilterStore = new BetsFilterStore(gamesStore.collection);

export const incubatorFilterStore = new IncubatorFilterStore(gamesStore.collection);

export const resultsFilterStore = new ResultsFilterStore(gamesStore.collection);
export const betSlipStore = new BetSlipStore(userStore);

export const tableStore = new TableStore({
    betSlipStore,
    filterStore: betsFilterStore,
    gamesStore,
    linesService,
    streamStore,
    userStore
});

const incubatorLineService = new LinesService({
    currentTimeStore,
    url: '/incubator/lines',
    userStore
});

export const incubatorStore = new IncubatorStore({
    betSlipStore,
    filterStore: incubatorFilterStore,
    gamesStore,
    linesService: incubatorLineService,
    streamStore,
    userStore
});

export const screenStore = new ScreenStore();

export const giveawayStore = new GiveawayStore();

export const articlesStore = new ArticlesStore();

export const chatStore = new ChatStore();

export const activeSurveyStore = new ActiveSurveyStore();

export const shopStore = new ShopStore();

export const artifactBetStore = new ArtifactBetStore();

export const depositsStore = new DepositsStore();

export const incubatorShiftStore = new IncubatorShiftStore(userStore);

export const modalsStore = new ModalsStore();

export const faqStore = new FaqStore();

export const pokerStore = new PokerStore();
