import React, {Component} from 'react';
import {Store} from 'app/store';
import ShopItems from './lootboxes/shop_items';
import InnerTabs from 'app/components/InnerTabs';
import I18n from 'app/i18n';
import {goToRoute} from 'app/utils/link_helper';
import {isUSD} from 'app/utils/ShopItemPriceFormat';
import {ShopItemsGamesInterface} from 'app/store/ShopStore';
import ShopItemInterface from 'app/interfaces/shop/ShopItemInterface';
import {ShopTableInterface} from 'app/interfaces/ShopTableInterface';
import withRouter, {withRouterInterface} from 'app/utils/navigate/customWithRouter';
import {inject, observer} from 'mobx-react';
import SelectPicker from 'app/components/reusable/SelectPicker';
import CollectionInterface from 'app/interfaces/CollectionInterface';

interface GameTab {
    query: {game: string, currency: string};
    title: string;
}

const updateShop = (): void => Store.updateShop();

interface ShopTableState {
    currency: string,
    game: string
}

class ShopTableLootboxes extends Component<ShopTableInterface, ShopTableState> {
    state = {
        currency: '',
        game: ''
    };

    get newCurrencies() {
        return [{text: 'USD', value: 'usd'}, {text: I18n.t('user_points'), value: 'points'}];
    }

    get currencies() {
        return this.newCurrencies.map((currency): CollectionInterface => ({
            text: currency.text,
            value: currency.value
        }));
    }

    get currency() {
        return new URLSearchParams(location.search).get('currency');
    }

    get params_game() {
        return new URLSearchParams(location.search).get('game');
    }

    constructor(props: ShopTableInterface) {
        super(props);

        this.state = {
            currency: this.currency || 'usd',
            game: this.defaultGame(this.params_game)
        };
    }

    defaultGame(game: string): string {
        return this.game_tabs[0] && this.game_tabs[0].query.game || game || '';
    }

    handleChangeGame = (game: string): void => this.setState({game});

    handleChangeCurrency = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        const currency = e.target.value;
        this.setState({currency});
        const {location: {pathname}} = this.props as ShopTableInterface & withRouterInterface;
        goToRoute(`${pathname}?currency=${currency || ''}&game=${this.state.game || ''}`);
    };

    selectClassName = (value: string): string => value === this.state.currency ? 'active-result result-selected highlighted' : 'active-result';

    changeNameFilter = (key: string): string => {
        if (key === '') {
            return I18n.t('new_lootboxes_header_shop');
        } else if (key === 'Casino') {
            return I18n.t('casino_lootboxes_header_shop');
        }

        return key;
    };

    get items(): ShopItemsGamesInterface {
        return this.props.shopStore.data.lootboxes;
    }

    get filteredByCurrency(): ShopItemInterface[] {
        return this.getItems(this.state.game);
    }

    get sortedTabs(): string[] {
        return this.game_tabs.map(({query: {game}}) => game);
    }

    get game_tabs(): GameTab[] {
        const keys = Object.keys(this.items).sort((a, b) => a.localeCompare(b));

        return keys.map(key => ({
            query: {
                currency: this.state.currency,
                game: key
            },
            title: `${this.changeNameFilter(key)} (${this.getItems(key).length})`
        }));
    }

    getItems = (game: string): ShopItemInterface[] => this.items[game] &&
            this.items[game].filter(item => item[isUSD(this.state.currency) ? 'cost_money' : 'cost_points'] > 0);

    render(): React.ReactNode {
        return <div className="market-lootboxes">
            <div className="tabs js-tabs tabs--lots">
                <div className="tabs__content">
                    <div className="tabs__content-item">
                        <div className="tabs js-tabs tabs--secondary">
                            <div className="tabs__content">
                                <div className="tabs__content--filters">
                                    <InnerTabs
                                        queryParam="game"
                                        selectedTab={this.state.game}
                                        tabs={this.game_tabs}
                                        handleChange={this.handleChangeGame}
                                    />
                                    <div className="select-currency classical-select field__select">
                                        <SelectPicker
                                            id="currency_select"
                                            onChange={this.handleChangeCurrency}
                                            value={this.state.currency}
                                            collection={this.currencies}
                                        />
                                    </div>
                                </div>
                                <div className="tabs__content-item">
                                    <div className="market__lootboxes">
                                        <ShopItems
                                            currency={this.state.currency}
                                            user={this.props.shopStore.data.user}
                                            lootboxes={this.filteredByCurrency}
                                            game={this.state.game}
                                            noChunks
                                            afterBuy={updateShop}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(inject('shopStore')(observer(ShopTableLootboxes)));
