
interface NetworkInfo {
  connectionType: string;
  downlink: number | string;
  effectiveType: string;
}

declare global {
    interface Navigator extends NavigatorNetworkInformation, NavigatorUserAgent {}

    interface WorkerNavigator extends NavigatorNetworkInformation, NavigatorUserAgent {}

    interface NavigatorNetworkInformation {
      readonly connection?: NetworkInformation;
    }

    interface NavigatorUserAgent {
      readonly userAgent?: string;
      readonly userAgentData?: NavigatorUAData;
    }

    interface NavigatorUAData {
      brands: { brand: string; version: string }[];
      mobile: boolean;
      platform: string;
      getHighEntropyValues(hints: string[]): Promise<Record<string, string>>;
    }

    type Megabit = number;
    type Millisecond = number;

    type EffectiveConnectionType = '2g' | '3g' | '4g' | 'slow-2g';

    type ConnectionType =
      | 'bluetooth'
      | 'cellular'
      | 'ethernet'
      | 'mixed'
      | 'none'
      | 'other'
      | 'unknown'
      | 'wifi'
      | 'wimax';

    interface NetworkInformation extends EventTarget {
      readonly type?: ConnectionType;
      readonly effectiveType?: EffectiveConnectionType;
      readonly downlinkMax?: Megabit;
      readonly downlink?: Megabit;
      readonly rtt?: Millisecond;
      readonly saveData?: boolean;
      onchange?: EventListener;
    }
  }

export {};


export class BrowserData {
  private connection: NetworkInformation | null;


  constructor() {
      this.connection = (navigator as Navigator).connection || null;
  }


  private getNetworkInfo(): NetworkInfo {
      const connectionInfo = this.connection;
      return {
          connectionType: connectionInfo?.type ?? 'Unknown',
          downlink: connectionInfo?.downlink ?? 'Unknown',
          effectiveType: connectionInfo?.effectiveType ?? 'Unknown'
      };
  }

  getAllInfo(): { network: NetworkInfo } {
      return {
          network: this.getNetworkInfo()
      };
  }
}

