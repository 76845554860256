import React, {Fragment} from 'react';
import PromoActionBeer from 'app/components/promo_action/beer';
import Image from 'app/helpers/Image';
import I18n from 'app/i18n';
import FAQButton from 'app/components/promo_action/modules/FAQButton';
import {COCKTAIL_IMAGE} from 'app/consts';
import {fixed2, userSignedIn} from 'app/utils';


export default class PromoActionCocktail extends PromoActionBeer {
    beerGlassParts = () => <Fragment>
        <div className="beer-glass__background beer-glass__cocktail">
            <Image image={{url: COCKTAIL_IMAGE[this.imagePropName].BACKGROUND}}/>
        </div>
        <div style={{bottom: `${this.nextPrizeProgress()}%`}} className="beer-glass__liquid beer-glass__cocktail">
            <Image image={{url: COCKTAIL_IMAGE[this.imagePropName].LIQUID}}/>
        </div>
    </Fragment>;

    promoActionTitle = () => <div className="beer-aside__title aside-item-heading__title">
        <div className="beer-aside__title-content">
            <h3>
                {I18n.t('promo_action.cocktail.header')}
            </h3>
            <FAQButton FAQPath="/faq/egb-sunshine-party"/>
        </div>
        {userSignedIn() && this.isStarted() && <div className="beer-aside__together">
            <span>
                {fixed2(this.nextPrizeProgress())}%
            </span>
            <span>
             x{this.props.count}
            </span>
        </div>}
    </div>;

    get imagePropName() {
        return 'PROMO_ACTION_COCKTAIL';
    }

    scatterText = () => I18n.t('promo_action_cocktail.scatter.promo_text');
}
