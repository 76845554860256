import {action, computed, makeObservable, observable} from 'mobx';
import Get from 'app/utils/Get';
import {getLinkPath} from 'app/utils/link_helper';
import {ASIDE_COUNT_LOOTBOX} from 'app/utils/Consts';

const RANDOM_VALUE = 0.5;

export class RecipeStore {
    constructor() {
        makeObservable(this);
    }

    @observable recipes = [];

    @observable paginate = {};

    @observable aside_items = [];

    @observable aside_lootboxes = [];

    @action
    init() {
        this.updateLootboxes();
    }

    @action
    updateRecipes({recipes, paginate}) {
        if (recipes) {
            this.recipes = recipes;
        }

        if (paginate) {
            this.paginate = paginate;
        }
    }

    @action
    updateAsideItems(aside_items) {
        this.aside_items = aside_items;
    }

    @action
    updateRandomLootboxes() {
        const shuffled = [...this.aside_items].sort(() => Math.random() - RANDOM_VALUE);
        this.aside_lootboxes = shuffled.slice(0, ASIDE_COUNT_LOOTBOX);
    }

    @computed
    get onSale() {
        return this.aside_lootboxes;
    }

    updateLootboxes = () => new Get({url: getLinkPath('/shop/lootboxes')})
        .execute()
        .then(response => response.json())
        .then(response => {
            const {lootboxes} = response;
            const filtredLootboxes = [];

            if (lootboxes) {
                Object.keys(lootboxes).forEach(key => {
                    filtredLootboxes.push(...lootboxes[key].filter(lootbox => lootbox.cost_money > 0));
                });
                this.updateAsideItems(filtredLootboxes);
                this.updateRandomLootboxes();
            }
        });
}
