import React, {PureComponent, ReactNode} from 'react';

const PARTNER_LIST = [
    {
        icon_height: '16px',
        icon_width: '50px',
        svg: 'visa'
    },
    {
        icon_height: '24px',
        icon_width: '31px',
        svg: 'mastercard'
    },
    {
        icon_height: '22px',
        icon_width: '130px',
        svg: 'paysafecard'
    },
    {
        icon_height: '18px',
        icon_width: '53px',
        svg: 'skrill'
    },
    {
        icon_height: '22px',
        icon_width: '94px',
        svg: 'ecopayz'
    },
    {
        icon_height: '15px',
        icon_width: '81px',
        svg: 'neteller'
    },
    {
        icon_height: '22px',
        icon_width: '84px',
        svg: 'webmoney'
    },
    {
        icon_height: '22px',
        icon_width: '23px',
        svg: 'btc'
    },
    {
        icon_height: '22px',
        icon_width: '18px',
        svg: 'yandexmoney'
    },
    {
        icon_height: '22px',
        icon_width: '35px',
        svg: 'unionpay'
    },
    {
        icon_height: '21px',
        icon_width: '47px',
        svg: 'boleto'
    },
    {
        icon_height: '21px',
        icon_width: '46px',
        svg: 'p4f'
    },
    {
        icon_height: '22px',
        icon_width: '60px',
        svg: 'paypal'
    },
    {
        icon_height: '32px',
        icon_width: '56px',
        svg: 'revolut'
    },
    {
        icon_height: '17px',
        icon_width: '58px',
        svg: 'papara'
    },
    {
        icon_height: '18px',
        icon_width: '68px',
        svg: 'gcash'
    },
    {
        icon_height: '18px',
        icon_width: '90px',
        svg: 'wechatpay'
    }
];

export default class FooterPartnerList extends PureComponent {
    render(): ReactNode {
        return <div className="partner-list">
            {PARTNER_LIST.map(partner => <span
                key={partner.svg}
                className="partner-list__link"
            >
                <svg height={partner.icon_height} width={partner.icon_width}>
                    <use xlinkHref={`#svg-payment-${partner.svg}`}/>
                </svg>
            </span>)}
        </div>;
    }
}
