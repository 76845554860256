import React, {ChangeEvent, ReactNode} from 'react';
import DepositSystem from 'app/components/deposit/form/DepositSystem';
import DepositWallets from 'app/components/deposit/form/DepositWallets';
import DepositSystemForm from 'app/components/deposit/form/DepositSystemForm';
import {inject, observer} from 'mobx-react';
import DepositRedirectSystemClass from './DepositRedirectSystemClass';
import SelectPicker from 'app/components/reusable/SelectPicker';
import {selectPickerCollection} from 'app/components/reusable/SelectCollection';
import I18n from 'app/i18n';

const TYPES = [['Visa', 'visa'], ['MasterCard', 'mastercard']];

type CreateParams = {
    amount: string
    promo_code: string
    wallet_id: number
    selected_type: string
    bonus: number
};

@inject('depositsStore')
@observer
export default class DepositRedirectRizonSystem extends DepositRedirectSystemClass {
    state = {
        selectedType: 'visa'
    };

    createParams(params = {}): CreateParams {
        const {amount, promoCode, wallet_id, bonus_type} = this.props.depositsStore;
        const {selectedType} = this.state;
        return {
            amount,
            bonus: bonus_type,
            promo_code: promoCode,
            selected_type: selectedType,
            wallet_id,
            ...params
        };
    }

    changeSelectedType = (e: ChangeEvent<HTMLSelectElement>): void => {
        this.setState({selectedType: e.target.value});
    };

    render(): ReactNode {
        const {wallets_enabled} = this.props.depositsStore.currentPaymentSystem;
        return <DepositSystem>
            {wallets_enabled && <DepositWallets/>}
            <DepositSystemForm onSubmit={this.onSubmit}>
                <div className="form__row">
                    <div className="form__row-item">
                        <label className="form__row-label">{I18n.t('choose_system_type')}</label>
                        <div className="field ">
                            <SelectPicker
                                onChange={this.changeSelectedType}
                                value={this.state.selectedType}
                                collection={selectPickerCollection(TYPES)}
                                placeholder={I18n.t('choose_currency')}
                                className="select-bank"
                            />
                        </div>
                    </div>
                </div>
                {null}
            </DepositSystemForm>
        </DepositSystem>;
    }
}
