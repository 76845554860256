import beerBackground from 'images/beer/beer-background.png';
import beerLiquid from 'images/beer/st_patrick/beer-liquid.png';
import beerVessel from 'images/beer/st_patrick/beer-vessel.png';
import oktoberfestLiquid from 'images/beer/therese_von_bayern/beer-liquid.png';
import oktoberfestVessel from 'images/beer/therese_von_bayern/beer-vessel.png';

export default {
    PROMO_ACTION_BEER: {
        BACKGROUND: beerBackground,
        LIQUID: beerLiquid,
        VESSEL: beerVessel
    },
    PROMO_ACTION_OKTOBERFEST: {
        BACKGROUND: beerBackground,
        LIQUID: oktoberfestLiquid,
        VESSEL: oktoberfestVessel
    }
};
