import {action, makeObservable, observable} from 'mobx';
import TableInterface from 'app/interfaces/poker/TableInterface';
import Get from 'app/utils/Get';
import {
    subscribePokerTable,
    unsubscribe
} from 'modules/WsSubscription';
import PlayerInterface from 'app/interfaces/poker/PlayerInterface';
import musicPlayer from 'app/components/sounds';
import {ONE_SECOND_MS, HALF_OF_SECOND_MS, THIRD_INDEX, FOURTH_INDEX, FIFTH_INDEX} from 'app/store/constants/storeConstants';
import {updateOrAddObject} from 'app/utils/updateArraysObject';

export const STATE_WAIT = 'wait';
export const STATE_SIT_IN = 'sit_in';
export const STATE_BUY_IN = 'buy_in';

const SHOWDOWN_CASES = {
    FLOP: [THIRD_INDEX, FOURTH_INDEX],
    PRE_FLOP: [0, THIRD_INDEX],
    TURN: [FOURTH_INDEX, FIFTH_INDEX]
};

const SHOWDOWN_STATES_TO_REVEAL = [
    'PRE_FLOP',
    'FLOP',
    'TURN'
];

export interface StateParamsInterface {
    position?: number
}

interface ActionParams {
    action: string
    position: number
}

export class PokerStore {
    @observable tables: TableInterface[] = [];

    @observable currentTable: TableInterface = null;

    @observable player: PlayerInterface = null;

    @observable communityCards: string[] = [];

    @observable showdownState: boolean = false;

    @observable isMinimize = false;

    @observable isEnabledSound = true;

    @observable isDisabled = false;

    @observable subscription = null;

    @observable state = STATE_WAIT;

    @observable stateParams: StateParamsInterface = {};

    @observable lastActionParams: ActionParams = null;

    lastActionInterval = null;

    constructor() {
        makeObservable(this);
    }

    @action
    updatePokerState(state: boolean): void {
        this.isDisabled = state;
    }

    @action
    openTable(table: TableInterface): void {
        this.currentTable = table;
        this.player = table.player;
        this.subcribeChannel();
    }

    @action
    closeTable(): void {
        this.currentTable = null;
        this.unsubcribeChannel();
    }

    @action
    minimizeTable(): void {
        this.isMinimize = !this.isMinimize;
    }

    @action
    toggleSound = (): void => {
        this.isEnabledSound = !this.isEnabledSound;
    };

    @action
    setTables = (tables: TableInterface[]): void => {
        this.tables = tables;
    };

    @action
    updateElementCollection(item: TableInterface): void {
        updateOrAddObject(this, 'tables', item);
    }

    @action
    updateCurrentTable = (table: TableInterface): void => {
        this.currentTable = table;

        if (SHOWDOWN_STATES_TO_REVEAL.includes(table.showdown_state)) {
            this.revealCommunityCards(table.showdown_state);
        } else {
            this.communityCards = table.cards;
        }
    };

    @action
    updatePlayer(player: PlayerInterface): void {
        this.player = player;
    }

    @action
    unsubcribeChannel = (): void => {
        this.subscription = unsubscribe(this.subscription);
    };

    @action
    subcribeChannel = (): void => {
        this.subscription = subscribePokerTable(this.currentTable.table_id, ({data: {event, payload}}) => {
            this.updateCurrentTable(payload.table);
            this.setLastActionParams({action: event, position: payload.event_params.position});
            this.setLastActionInterval();
            musicPlayer.play(`poker_${event}_sound`, this.isEnabledSound);
        });
    };

    setLastActionInterval = (): void => {
        if (this.lastActionInterval) {
            clearTimeout(this.lastActionInterval);
        }

        if (this.lastActionParams?.position !== null) {
            this.lastActionInterval = setTimeout(() => {
                this.setLastActionParams(null);
            }, ONE_SECOND_MS);
        }
    };

    @action
    sitIn = (position: number): void => {
        this.stateParams = {position};
        this.state = STATE_SIT_IN;
    };

    @action
    buyIn = (): void => {
        this.state = STATE_BUY_IN;
    };

    @action
    clearState = (): void => {
        this.stateParams = {};
        this.state = STATE_WAIT;
    };

    @action
    setLastActionParams = (params: ActionParams): void => {
        this.lastActionParams = params;
    };

    isWaiting = (): boolean => this.state === STATE_WAIT;

    isSitIn = (): boolean => this.state === STATE_SIT_IN;

    isBuyIn = (): boolean => this.state === STATE_BUY_IN;

    loadTables = (): void => {
        new Get({
            url: '/poker/tables'
        }).execute()
            .then(response => response.json())
            .then(response => {
                this.setTables(response.tables);
                this.updatePokerState(response.state);
            });
    };

    loadTable = (table_id: string): void => {
        new Get({
            url: `/poker/tables/${table_id}`
        }).execute()
            .then(response => response.json())
            .then(response => {
                this.openTable(response);
            });
    };

    @action
    setCommunityCards = (from: number, to: number): void => {
        this.communityCards = [...this.communityCards, ...this.currentTable.cards.slice(from, to)];
    };

    revealCommunityCards(showdownState: string): void {
        if (this.showdownState) {
            return;
        }

        this.showdownState = true;

        const statesToReveal = SHOWDOWN_STATES_TO_REVEAL.slice(SHOWDOWN_STATES_TO_REVEAL.findIndex(x => x === showdownState), THIRD_INDEX);

        statesToReveal.forEach((state, index) => {
            setTimeout(() => {
                const indexses = SHOWDOWN_CASES[state];
                this.setCommunityCards(indexses[0], indexses[1]);

                if (index === statesToReveal.length - 1) {
                    this.showdownState = false;

                    if (this.player.winner) {
                        musicPlayer.play('poker_win_sound', this.isEnabledSound);
                    }
                }
            }, index * HALF_OF_SECOND_MS);
        });
    }
}
