import React, {PureComponent, ReactNode} from 'react';
import WalletInterface from 'app/interfaces/WalletInterface';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';

interface DepositWalletProps {
    wallet: WalletInterface
    selectedSystem?: PaymentSystemInterface
    deactivateWallet?: (id: number, account: string) => void
    onClick: () => void
    defaultChecked: boolean
}

const COINSPAID_NAME = 'crypto-deposit';

export default class DepositWallet extends PureComponent<DepositWalletProps> {
    renderAccount = (): ReactNode => {
        const {wallet, selectedSystem} = this.props;

        if (selectedSystem && selectedSystem.slug === COINSPAID_NAME) {
            return `${wallet.wallet_detail.currency} (${wallet.masked_account})`;
        }
        return wallet.masked_account || `Wallet #${wallet.id}`;
    };

    deactivateWallet = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        const {deactivateWallet, wallet: {id, masked_account}} = this.props;

        deactivateWallet && deactivateWallet(id, masked_account);
    };

    render(): ReactNode {
        const {wallet: {id, bank_logo}, defaultChecked, onClick, deactivateWallet} = this.props;
        return <div className="field filters__list-item">
            <div className="field__choose" onClick={onClick}>
                <input
                    type="radio"
                    name="nameCheck"
                    className="field__invisible"
                    defaultChecked={defaultChecked}
                    value={id || ''}
                />
                <div className="field__choose-item">
                    <div className="field__choose-text">
                        {bank_logo && <span className="field__choose-img"><img src={bank_logo.url}/></span>}
                        <span className="field__choose-name">{this.renderAccount()}</span>
                    </div>
                </div>
            </div>
            {deactivateWallet && <button className="field__remove" onClick={this.deactivateWallet}>
                <span className="icon">
                    <svg className="svg-default">
                        <use xlinkHref="#svg-close"/>
                    </svg>
                </span>
            </button>}
        </div>;
    }
}
