import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {betMoney} from 'app/utils';
import HistoricalDepositInterface from 'app/interfaces/HistoricalDepositInterface';
import Post from 'app/utils/Post';

interface HistoricalDepositProps {
    deposit: HistoricalDepositInterface
    ofelospay: boolean
}

export class DepositHistoryItem extends PureComponent<HistoricalDepositProps> {
    changeDeposit = (url: string): void => {
        new Post({url}).execute();
    };

    render(): ReactNode {
        const {deposit} = this.props;
        const {id, date, operator, system, gift_amount} = deposit;

        return <tr key={id}>
            <td>
                <span className="value-date">{date}</span>
            </td>
            <td>
                <p className="section">
                    <span className="value-id">{id}</span>
                </p>
                <p className="section">
                    <span className="value-operator">{operator}</span>
                </p>
            </td>
            <td>
                <span className="value-payment-system">{system}</span>
            </td>
            <td>
                <span className="value-gift-amount">{gift_amount || betMoney(deposit)}</span>
            </td>
            <td colSpan={2}>
                <span className="value-status">
                    {I18n.t('user.status_completed')}
                </span>
            </td>
        </tr>;
    }
}
