import {action, makeObservable, observable} from 'mobx';
import ImageInterface from 'app/interfaces/ImageInterface';
import {addOrUpdateObject, deleteObjectById} from 'app/utils/updateArraysObject';
import {unsubscribe} from 'modules/WsSubscription';
import {isGuest} from 'app/utils';
import StoryInterface from 'app/interfaces/StoryInterface';
import ImageTranslationsInterface from 'app/interfaces/ImageTranslationsInterface';
import {ELEMENT_NOT_FOUND} from 'app/utils/Consts';
import UserStoryInterface from 'app/interfaces/UserStoryInterface';
import UserStoryImageInterface from 'app/interfaces/UserStoryImageInterface';
import Post from 'app/utils/Post';
import Get from 'app/utils/Get';
import StatsInterface from 'app/interfaces/stores/stories/StatsInterface';
import {StoryType} from 'app/interfaces/StoryType';
import {UserStore} from 'app/store/UserStore';
import StoryImageInterface from 'app/interfaces/StoryImageInterface';

const SHOP_POINTS = 1000;
const ONBOARDING_STORIES_DATE = '2022-01-16';
const STORY_TYPE_ORDER: string[] = Object.values(StoryType);
const DEFAULT_LOCALE = 'en';

export interface StoriesFormInterface {
    stories: StoryInterface[]
    user_stories: UserStoryInterface[]
    user_story_images: UserStoryImageInterface[]
}

interface StatsDataInterface {
    success: boolean
    monthly_stats: StatsInterface,
    yearly_stats: StatsInterface
}

export interface StoriesStoreProps {
    storiesStore?: StoriesStore
}

export class StoriesStore {
    constructor(data: StoriesFormInterface, userStore: UserStore) {
        this.userStore = userStore;
        userStore.storiesStore = this;
        this.updateOnboardingStories();
        this.init(data);
        makeObservable(this);
    }

    userStore: UserStore;

    @observable data: StoriesFormInterface = {
        stories: [],
        user_stories: [],
        user_story_images: []
    };

    @observable openStories = [];

    @observable subscription = null;

    @observable currentStoryId = null;

    monthlyStats: StatsInterface = null;

    yearlyStats: StatsInterface = null;

    hiddenStories: StoryInterface[] = [];

    @action
        init = (data: StoriesFormInterface): void => {
            this.data.user_story_images = data.user_story_images;
            this.data.user_stories = data.user_stories;
            data.stories.forEach(story => {
                this.updateStoryImages(story);
                this.updateViewStory(story);
            });
            this.data = this.initStories(data);

            if (this.hasStatsStories(this.data.stories)) {
                this.getStats();
            }
        };

    @action
        initStories = (data: StoriesFormInterface): StoriesFormInterface => {
            data.stories = this.removeStoriesEmpty(data.stories);
            data.stories = this.removeStoriesNotBasicForGuestOrOldUser(data.stories);
            data.stories = this.onboardingStories(data.stories);
            data.stories = this.sortStories(data.stories);
            if (isGuest()) {
                data.stories = this.removeStoriesOnEmptyStats(data.stories);
            }
            return data;
        };

    @action
        unsubscribeChannel = (): void => {
            this.subscription = unsubscribe(this.subscription);
        };

    @action
        setStories = (stories: StoryInterface[]): void => {
            this.data.stories = stories;
        };

    @action
        updateStory = (payload: StoryInterface): void => {
            this.updateViewStory(payload);
            addOrUpdateObject(this.data, 'stories', payload);
            this.data.stories = this.removeStoriesOnEmptyStats(this.data.stories);
            this.data = this.initStories(this.data);

            if (!isGuest()) {
                this.userStoryInfo(payload.id);
            }
        };

    @action
        deleteStory = (id: number): void => {
            deleteObjectById(this.data, 'stories', id);
            this.hiddenShopPurchaseStories(this.data.stories);
        };

    @action
        setCurrentStoryId = (id: number): void => {
            this.currentStoryId = id;
        };

    @action
        updateUserStory = (payload: UserStoryInterface): void => {
            addOrUpdateObject(this.data, 'user_stories', payload);
        };

    @action
        updateUserStoryImage = (payload: UserStoryImageInterface): void => {
            addOrUpdateObject(this.data, 'user_story_images', payload);
            this.updateViewStory(this.data.stories.find(item => item.id === payload.story_id));
            if (this.openStories.length > 0) {
                this.updateViewStory(this.openStories.find(item => item.id === payload.story_id));
            }
            this.data.stories = this.sortStories(this.data.stories);
        };

    @action
        updateViewStory = (story: StoryInterface): void => {
            if (!story) {
                return;
            }
            story.viewed = story.story_images.map(item => item.id)
                .filter(id => !this.userStoryImagesById(story.id).includes(id)).length === 0;
            const userStory = this.userStoryById(story.id);
            story.viewed_all = userStory ? userStory.viewed : false;
            story.hide_all = userStory ? userStory.hidden : false;
        };

    @action
        setLoadedStoryImage = (storyImage: StoryImageInterface): void => {
            storyImage.loaded = true;
        };

    @action
        removeUserStoryImagesByStoryId = (id: number): void => {
            this.data.user_story_images = this.data.user_story_images.filter(item => item.story_id !== id);
        };

    @action
        updateOnboardingStories = (): void => {
            this.data.stories = this.onboardingStories(this.data.stories);
        };

    getStats = (): void => {
        new Get({
            url: '/stories/stats'
        }).execute().then(response => response.json())
            .then((response: StatsDataInterface) => {
                if (response.success) {
                    this.monthlyStats = response.monthly_stats;
                    this.yearlyStats = response.yearly_stats;
                }
            }).finally(() => {
                this.setStories(this.removeStoriesOnEmptyStats(this.data.stories));
            });
    };

    listenUpdate(payload: StoryInterface): void {
        if (payload.active === false) {
            this.deleteStory(payload.id);
            return;
        }
        this.updateStoryImages(payload);
        this.updateStory(payload);
    }

    listenUserStory(payload: UserStoryInterface): void {
        this.updateUserStory(payload);
        this.updateViewStory(this.data.stories.find(item => item.id === payload.story_id));
    }

    updateStoryImages = (story: StoryInterface): void => {
        story.image = this.getImageLocale(story.image_translations);
        story.story_images.forEach(image => {
            image.image = this.getImageLocale(image.image_translations);
        });
    };

    hasStories = (): boolean => this.data.stories.length !== 0;

    hasStatsStories = (stories: StoryInterface[]): boolean => stories
        .filter(item => item.monthly_stats || item.year_stats).length > 0;

    getCountNewStories = (): number => this.data.stories.filter(story => !story.viewed).length;

    getImageLocale = (image_translations: ImageTranslationsInterface[]): ImageInterface => {
        if (!image_translations) {
            return null;
        }
        let image_translation = this.findImageByLocale(image_translations, window.CURRENT_LOCALE);
        if (image_translation) {
            return image_translation.image;
        } else if (window.CURRENT_LOCALE !== DEFAULT_LOCALE) {
            image_translation = this.findImageByLocale(image_translations, DEFAULT_LOCALE);

            if (image_translation) {
                return image_translation.image;
            }
        }
        return null;
    };

    findImageByLocale = (translations: ImageTranslationsInterface[], locale: string):
        ImageTranslationsInterface => translations.find(item => item.locale === locale);

    like = (id: number): void => {
        new Post({
            params: {id},
            url: '/stories/like'
        }).execute().then();
    };

    dislike = (id: number): void => {
        new Post({
            params: {id},
            url: '/stories/dislike'
        }).execute().then();
    };

    viewUserStoryImage = (id: number, story_id: number): void => {
        if (isGuest()) {
            return;
        }

        new Post({
            params: {
                id: story_id,
                story_image_id: id
            },
            url: '/stories/view_image'
        }).execute().then();
    };

    viewUserStory = (id: number): void => {
        if (isGuest()) {
            return;
        }

        new Post({
            params: {id},
            url: '/stories/view'
        }).execute().then();
    };

    clickRedirectUrl = (id: number): void => {
        new Post({
            params: {id},
            url: '/stories/click'
        }).execute().then();
    };

    clickChangeStoryHide = (id: number, callback: () => void): void => {
        new Post({
            params: {id},
            url: '/stories/hide'
        }).execute()
            .then(response => {
                const RESPONSE_STATUS_OK = 200;

                if (response.status === RESPONSE_STATUS_OK) {
                    callback();
                    this.deleteStory(id);
                }
                return response.json();
            });
    };

    userStoryInfo = (id: number): void => {
        new Get({
            params: {id},
            url: '/stories/user_story_info'
        }).execute().then(response => response.json())
            .then((response: StoriesFormInterface) => {
                response.user_stories.forEach(user_story => this.updateUserStory(user_story));
                this.removeUserStoryImagesByStoryId(id);
                response.user_story_images.forEach(user_story_image => this.updateUserStoryImage(user_story_image));
            });
    };

    userStoryById = (id: number): UserStoryInterface => this.data.user_stories.find(item => item.story_id === id);

    userStoryLike = (id: number): boolean => {
        const story = this.userStoryById(id);
        return story && story.like;
    };

    userStoryDislike = (id: number): boolean => {
        const story = this.userStoryById(id);
        return story && story.dislike;
    };

    userStoryImageById = (id: number): UserStoryImageInterface => this.data.user_story_images
        .filter(item => item.story_id === id)
        .sort((a, b) => b.id - a.id)[0];

    userStoryImagesById = (id: number): number[] => this.data.user_story_images
        .filter(item => item.story_id === id).map(item => item.story_image_id);

    removeStoriesEmpty = (stories: StoryInterface[]): StoryInterface[] => stories
        .filter(item => item.story_images.length > 0);

    removeStoriesNotBasicOrClose = (stories: StoryInterface[]): StoryInterface[] => stories
        .filter(item => item.story_type === StoryType.BASIC ||
            item.story_type === StoryType.CLOSE_BUTTON && !item.hide_all);

    removeStoriesOnEmptyStats = (stories: StoryInterface[]): StoryInterface[] => stories
        .filter(item => item.monthly_stats || item.year_stats
            ? item.monthly_stats && this.monthlyStats !== null || item.year_stats && this.yearlyStats !== null
            : item);

    sortStories = (stories: StoryInterface[]): StoryInterface[] => stories.slice()
        .sort((a, b) => {
            const story_type = STORY_TYPE_ORDER.indexOf(b.story_type) - STORY_TYPE_ORDER.indexOf(a.story_type);

            if (story_type !== 0) {
                return story_type;
            }

            const viewed = Number(a.viewed) - Number(b.viewed);

            if (viewed !== 0) {
                return viewed;
            }

            const pinned = Number(b.pinned) - Number(a.pinned);

            if (pinned !== 0) {
                return pinned;
            }

            return b.start_date - a.start_date;
        });

    removeStoriesNotBasicForGuestOrOldUser = (stories: StoryInterface[]): StoryInterface[] => {
        if (!isGuest() && this.showOnboardingStoriesByRegistrationDate()) {
            this.hiddenShopPurchaseStories(stories);
            return stories;
        }
        return this.removeStoriesNotBasicOrClose(stories);
    };

    @action
        setOpenStories = (): void => {
            this.openStories = this.data.stories.slice();
        };

    @action
        clearOpenStories = (): void => {
            this.openStories = [];
        };

    findIndexOpenStoryById = (id: number): number => this.openStories.findIndex(item => item.id === id);

    findIndexOpenStoryImageById = (story_id: number): number => {
        const userStoryImage = this.userStoryImageById(story_id);
        const id = userStoryImage ? userStoryImage.story_image_id : ELEMENT_NOT_FOUND;

        if (id < 0) {
            return 0;
        }

        const story = this.openStories.find(item => item.id === story_id);
        const last = story.story_images.findIndex(item => item.id === id);
        return story.story_images.length > last + 1 ? last + 1 : last;
    };

    basic = (story: StoryInterface): boolean => story.story_type === StoryType.BASIC;

    close_button = (story: StoryInterface): boolean => story.story_type === StoryType.CLOSE_BUTTON;

    removeStoriesByType = (stories: StoryInterface[], storyType: string, viewed = false): StoryInterface[] => stories
        .filter(item => viewed ? !(item.story_type === storyType && item.viewed_all) : item.story_type !== storyType);

    onboardingStories = (stories: StoryInterface[]): StoryInterface[] => {
        if (isGuest() || !this.showOnboardingStoriesByRegistrationDate()) {
            return stories;
        }
        let onboardingStories = this.checkEmailConfirmStories(stories);
        onboardingStories = this.checkFirstBetStories(onboardingStories);
        onboardingStories = this.checkCasinoFirstBetStories(onboardingStories);
        onboardingStories = this.checkFirstDepositStories(onboardingStories);
        onboardingStories = this.checkGiveawayTookPartStories(onboardingStories);
        return this.checkShopPurchaseStories(onboardingStories);
    };

    checkEmailConfirmStories = (stories: StoryInterface[]): StoryInterface[] => this.userStore.user.confirmed
        ? this.removeStoriesByType(stories, StoryType.EMAIL_CONFIRM)
        : stories;

    checkFirstDepositStories = (stories: StoryInterface[]): StoryInterface[] => this.userStore.user.first_deposit
        ? this.removeStoriesByType(stories, StoryType.FIRST_DEPOSIT, true)
        : stories;

    checkGiveawayTookPartStories = (
        stories: StoryInterface[]
    ): StoryInterface[] => this.userStore.user.giveaway_took_part
        ? this.removeStoriesByType(stories, StoryType.GIVEAWAY)
        : stories;

    checkShopPurchaseStories = (stories: StoryInterface[]): StoryInterface[] => {
        this.hiddenStories.forEach(item => {
            if (!stories.find(story => story.id === item.id)) {
                stories.push(item);
            }
        });
        const result = this.removeStoriesByType(
            stories, StoryType.SHOP_PURCHASE, this.userStore.user.points >= SHOP_POINTS
        );
        return this.sortStories(result);
    };

    checkFirstBetStories = (stories: StoryInterface[]): StoryInterface[] => this.userStore.user.first_bet
        ? this.removeStoriesByType(stories, StoryType.FIRST_BET, true)
        : stories;

    checkCasinoFirstBetStories = (stories: StoryInterface[]): StoryInterface[] => this.userStore.user.casino_first_bet
        ? this.removeStoriesByType(stories, StoryType.CASINO_FIRST_BET)
        : stories;

    showOnboardingStoriesByRegistrationDate = (): boolean => new Date(this.userStore.user.created_at) >
        new Date(ONBOARDING_STORIES_DATE);

    hiddenShopPurchaseStories = (stories: StoryInterface[]): void => {
        this.hiddenStories = stories.filter(item => item.story_type === StoryType.SHOP_PURCHASE && !item.viewed_all);
    };

    viewedBasic = (story: StoryInterface): boolean => story.viewed && story.story_type === StoryType.BASIC;
}
