import React, {Component, ReactNode} from 'react';

interface Props {
    onClick: () => void
}

export default class PokerMoveButtonMinimize extends Component<Props> {
    render(): ReactNode {
        return <button className="poker-move-button minimize-poker" onClick={this.props.onClick}>
            <svg className="svg-default">
                <use xlinkHref="#svg-bet-slip-controller-minimize-1"/>
            </svg>
        </button>;
    }
}
