import React, {PureComponent, ReactNode} from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {flagImage} from 'app/utils';
import OptionInterface from 'app/interfaces/OptionInterface';
import IncubatorGamerInterface from 'app/interfaces/IncubatorGamerInterface';

interface Props {
    keyForSelect: string
    request?: (inputValue: string) => Promise<Response>
    value: OptionInterface
    onChange: (selected?: OptionInterface | OptionInterface[] | null) => void
    placeholder: string,
    loadOptions?: (inputValue: string) => void | Promise<OptionInterface[]>,
    customOption?: (innerProps, data) => ReactNode
    isClearable?: boolean
    isValue?: boolean
    onKeyDown?: (e: React.KeyboardEvent) => void
    onClick?: () => void
}

const colourStyles = {
    control: (styles, state) => ({
        ...styles,
        '&:hover': {
            border: state.isFocused ? '1px solid #fe9400' : '1px solid #36383e'
        },
        backgroundColor: '#1d1d23',
        border: state.isFocused ? '1px solid #fe9400' : '1px solid #36383e',
        boxShadow: 'none',
        color: '#ffffff',
        cursor: 'pointer',
        fontSize: '12px',
        lineHeight: '14px'
    }),
    input: styles => ({
        ...styles,
        color: '#ffffff'
    }),
    menuList: styles => ({
        ...styles,
        backgroundColor: '#1d1d23',
        color: '#ffffff',
        img: {
            display: 'block',
            width: '30px'
        }
    }),
    singleValue: styles => ({
        ...styles,
        color: '#ffffff'
    })
};

const CustomOption = ({innerProps, data}) => <div className="player-filters__menu-item" {...innerProps}>
    <span className="player-filters__menu-item__player-logo">
        {flagImage(data.icon)}
    </span>
    <span className="player-filters__menu-item__player-name">
        {data.label}
    </span>
</div>;

export class LiveCreatableSelect extends PureComponent<Props> {
    root: React.RefObject<HTMLDivElement> = React.createRef();

    loadOptions = (inputValue: string): void | Promise<OptionInterface[]> => {
        const {request} = this.props;
        return request(inputValue)
            .then<IncubatorGamerInterface[]>(response => response.json())
            .then(gamers => gamers.map(gamer => ({
                icon: gamer.country_code,
                label: gamer.nickname,
                value: gamer.id
            })));
    };

    formatCreateLabel = (userInput: string): string => `${userInput}`;

    onFocus = (): void => this.root.current?.click();

    handleMenuOpen = (): void => {
        this.props.onClick();
    };

    render(): React.ReactNode {
        const {keyForSelect, value, onChange, loadOptions, customOption, isClearable, isValue, placeholder} = this.props;
        const option = customOption || CustomOption;
        const valueOrNull = isValue && value && value.label !== '' ? value : null;
        return <div ref={this.root}>
            <AsyncCreatableSelect
                key={keyForSelect}
                defaultValue={valueOrNull}
                value={valueOrNull}
                classNamePrefix="players_filter"
                components={{DropdownIndicator: () => null, IndicatorSeparator: () => null, Option: option}}
                cacheOptions={false}
                loadOptions={loadOptions || this.loadOptions}
                defaultOptions
                onChange={onChange}
                isClearable={value && value.label && isClearable}
                placeholder={placeholder}
                styles={colourStyles}
                createOptionPosition={'first'}
                onFocus={this.onFocus}
                formatCreateLabel={this.formatCreateLabel}
                onMenuOpen={this.handleMenuOpen}
            />
        </div>;
    }
}
