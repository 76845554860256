import InventoryItemInterface from 'app/interfaces/InventoryItemInterface';
import {action, computed, makeObservable, observable, toJS} from 'mobx';
import {artifactStore} from 'app/store/global';
import QuantifiedArtifact from 'app/interfaces/QuantifiedArtifactInterface';
import Websocket from 'modules/Websocket';
import Get from 'app/utils/Get';

const ARTIFACT_BET_LIMIT = 9;

export class ArtifactBetStore {
    subscriptions = [];

    @observable artifacts: InventoryItemInterface[] = [];

    @observable availableArtifacts: InventoryItemInterface[] = [];

    constructor() {
        makeObservable(this);
        this.resetArtifacts();
    }

    @action
    getUserInventory(): void {
        new Get({url: '/my_profile/inventory_items'})
            .execute()
            .then(data => data.json())
            .then(data => {
                artifactStore.updateStore({...data});
                const betArtifacts = data.inventory_items.filter(
                    i => (i?.item_type === 'artifact' || i?.item_type === 'scroll_artifact') && i?.bet_available
                );
                this.initAvailableArtifacts(betArtifacts);
            });
    }

    @action
    initArtifacts(): void {
        this.artifacts = [];
    }

    @action
    resetArtifacts(): void {
        this.initArtifacts();
        this.initAvailableArtifacts(artifactStore.betArtifacts);
    }

    @action
    updateInventory(): void {
        this.initArtifacts();
        this.initAvailableArtifacts(artifactStore.betArtifacts);
    }

    @action
    initAvailableArtifacts(availableArtifacts: InventoryItemInterface[]): void {
        this.availableArtifacts = toJS(availableArtifacts);
    }

    @computed
    get artifactsToBet(): InventoryItemInterface[] {
        return this.artifacts;
    }

    @computed
    get availableToBetArtifacts(): InventoryItemInterface[] {
        return this.availableArtifacts;
    }

    @action
        addArtifact = (artifact: InventoryItemInterface): boolean => {
            const artifacts_count = this.artifacts.reduce((a, b) => a + b.total_inventory_items, 1);

            if (artifacts_count <= ARTIFACT_BET_LIMIT) {
                [this.availableArtifacts, this.artifacts] = this.moveArtifacts(
                    this.availableArtifacts,
                    this.artifacts,
                    artifact
                );
                return true;
            }
            return false;
        };

    quantifiedArtifactsToBet = (): QuantifiedArtifact[] => this.artifacts.map(a => ({
        id: a.recipe.id,
        quantity: a.total_inventory_items
    }));

    @action
        removeArtifact = (artifact: InventoryItemInterface): void => {
            [this.artifacts, this.availableArtifacts] = this.moveArtifacts(
                this.artifacts,
                this.availableArtifacts,
                artifact
            );
        };

    private moveArtifacts = (
        fromArtifacts: InventoryItemInterface[],
        toArtifacts: InventoryItemInterface[],
        artifact: InventoryItemInterface
    ): [InventoryItemInterface[], InventoryItemInterface[]] => {
        const toArtifactsRes = toArtifacts.find(a => a?.id === artifact?.id)
            ? toArtifacts.map(
                a => a?.id === artifact?.id
                    ? {
                        ...artifact,
                        total_inventory_items: a?.total_inventory_items + 1
                    }
                    : a
            )
            : toArtifacts.concat({
                ...artifact,
                total_inventory_items: 1
            });

        const fromArtifactsRes = artifact.total_inventory_items === 1
            ? fromArtifacts.filter(a => a?.id !== artifact.id)
            : fromArtifacts.map(
                a => a?.id === artifact?.id
                    ? {
                        ...artifact,
                        total_inventory_items: artifact.total_inventory_items - 1
                    }
                    : a
            );
        return [fromArtifactsRes, toArtifactsRes];
    };

    listen(userId: number): void {
        this.subscriptions[`${userId}`] = Websocket.subscribe(`$bet-of-${userId}`, ({data: {event}}) => {
            switch (event) {
            case 'cancelled':
                this.getUserInventory();
                break;
            case 'accepted':
                this.getUserInventory();
                break;
            default:
                throw new Error('Invalid event_type');
            }
        });
    }

    unsubscribe(userId: number): void {
        let subscription = this.subscriptions[`${userId}`];
        if (subscription) {
            Websocket.unsubscribe(subscription);
            subscription = null;
        }
    }
}
