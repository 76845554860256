import Get from 'app/utils/Get';

interface AddressType {
    currency: string
    address: string
}

export interface AddressesCurrenciesType {
    addresses: {[currency: string]: AddressType}
}

export const loadAddresses = (callback: (data: AddressesCurrenciesType) => void): void => {
    new Get({url: '/deposits/crypto_deposit/addresses'})
        .execute()
        .then(data => data.json())
        .then(data => {
            callback(data as AddressesCurrenciesType);
        });
};
