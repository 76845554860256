import React from 'react';
import I18n from 'app/i18n';

import {getLinkPath, iOSDevice} from 'app/utils';
import {Auth} from './auth_popup';
import Captcha from './captcha';
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';

import logEvent from 'app/amplitude/log_event';
import {ShowPasswordEye} from 'app/components/auth/ShowPasswordEye';

import SmartLoader from 'app/components/reusable/SmartLoader';
import {toastError} from 'app/components/toasts/liteToast';
import Post from 'app/utils/Post';
import {BrowserData} from './BrowserData';

export default class Login extends React.PureComponent {
    state = {
        captcha: false,
        error: null,
        iOSDevice: iOSDevice(),
        passwordType: 'password',
        submitting: false,
        token: null,
        useRecaptchaV2: false
    };

    get captchaError() {
        return this.state.error && this.state.captcha;
    }

    get credentialsError() {
        return !this.captchaError && this.state.error;
    }

    get hashRedirect() {
        return window.location.hash.split(',')[1];
    }

    get firebaseToken() {
        return window.localStorage.getItem('sentFirebaseMessagingToken');
    }

    get amplitudeToken() {
        return window.localStorage.getItem('amplitude_device_id');
    }

    get userAgent() {
        return navigator.userAgent;
    }

    logIn = e => {
        e && e.preventDefault();

        if (this.state.submitting) {
            return;
        }

        this.setState({captcha: false, error: null, submitting: true, useRecaptchaV2: false});

        const form = new FormData(e.target);

        const egb_user = {
            name: form.get('name'),
            password: form.get('password'),
            remember_me: form.get('remember_me')
        };

        const browserData = new BrowserData();
        const additional_info = browserData.getAllInfo();
        const formData = {
            additional_info,
            'device[browser]': this.userAgent,
            'device[id]': this.firebaseToken || this.amplitudeToken,
            egb_user,
            'g-recaptcha-response': this.state.token || form.get('g-recaptcha-response'),
            use_recaptcha_v2: this.state.useRecaptchaV2
        };

        new Post({
            params: formData,
            url: '/egb_users/sign_in'

        })
            .execute()
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    logEvent('USER_AUTHORIZED');
                    window.CURRENT_LOCALE = data.locale;
                    location.href = getLinkPath(this.props.redirectUrl || this.hashRedirect || '/play/bets');
                }
                if (data.error || data.errors) {
                    const error = data.error ? data.error : data.errors;
                    logEvent('USER_AUTHORIZATION_FAILURE', error);
                    toastError(error);
                    this.setState({captcha: false, error, submitting: false, token: null, useRecaptchaV2: data.captcha || false});
                }
            }).finally(() => {
                this.setState({submitting: false});
            });
    };

    onKeyUp = event => Auth.clearFiledError(this, event);

    onCaptchaPass = () => this.setState({error: null, submitting: false});

    saveFormRef = x => {
        this.formRef = x;
    };

    typePassword() {
        return this.state.passwordType === 'password';
    }

    showPasswordHandler = () => {
        this.setState({passwordType: this.typePassword() ? 'text' : 'password'});
    };

    showPasswordTitle() {
        return I18n.t(`auth.${this.typePassword() ? 'show' : 'hide'}_password_label`);
    }

    showPasswordEye() {
        return this.typePassword() ? '#eye' : '#eye_crossed';
    }

    handleVerify = token => {
        if (this.state.token !== token) {
            this.setState({token});
        }
    };

    render() {
        const {error, submitting, credentialsError, passwordType, useRecaptchaV2} = this.state;
        return (
            <form
                className={error ? 'form-popup popup-form--error' : 'form-popup login'}
                id="sign-in"
                onKeyUp={this.onKeyUp}
                onSubmit={this.logIn}
                ref={this.saveFormRef}
            >
                <div className="popup-form__error-hint">{error}</div>

                <div className="popup-form__row">
                    <label className="field-label" htmlFor="field-log-login">{I18n.t('nickname')}</label>
                    <div className={`field${credentialsError ? ' field-error' : ''}`}>
                        <input
                            autoComplete="username"
                            className="field__input"
                            id="field-log-login"
                            name="name"
                        />
                    </div>
                </div>

                <div className="popup-form__row">
                    <label className="field-label" htmlFor="field-log-password">{I18n.t('password')}</label>
                    <div className={`field field__input-group${credentialsError ? ' field-error' : ''}`}>
                        <input
                            autoComplete="current-password"
                            className="password optional field__input"
                            id="field-log-password"
                            name="password"
                            type={passwordType}
                        />
                        <ShowPasswordEye title={this.showPasswordTitle()}
                            onClickHandler={this.showPasswordHandler}
                            eyeSign={this.showPasswordEye()}/>
                    </div>
                    <a
                        className="popup-form__forgot-password"
                        href="#"
                        onClick={this.props.forgotPassword}
                    >
                        {I18n.t('user.forgotten')}
                    </a>
                </div>

                <div className="popup-form__row captcha__row">
                    {useRecaptchaV2
                        ? <div className={`popup-form__row captcha__row${this.captchaError ? ' field-error' : ''}`}>
                            <div className={this.captchaError ? ' field__input' : ''}>
                                <Captcha onPass={this.onCaptchaPass} reset={error}/>
                            </div>
                        </div>
                        : <GoogleReCaptcha onVerify={this.handleVerify} />
                    }
                </div>

                <input id="egb_user_remember_me" name="remember_me" type="hidden" value="1"/>
                {submitting
                    ? <SmartLoader loaderClassName="button-adaptive login-loader"/>
                    : <div className="popup-form__submit-row">
                        <input
                            className={`button-adaptive ${submitting ? 'button-adaptive_disable' : ''}`}
                            disabled={submitting}
                            type="submit"
                            value={I18n.t('login_button')}
                        />
                    </div>
                }
            </form>
        );
    }
}
