import React, {ReactNode} from 'react';
import PokerBoard from 'app/components/poker/poker_board/PokerBoard';
import PokerPot from 'app/components/poker/poker_pot/PokerPot';
import PokerPlayers from 'app/components/poker/poker_players/PokerPlayers';
import PokerControllerPanel from 'app/components/poker/poker_controller_panel/PokerControllerPanel';
import PokerMainMessage from 'app/components/poker/poker_main_message/PokerMainMessage';
import PokerPopupJoinTable from 'app/components/poker/poker_popup/PokerPopupJoinTable';
import {inject, observer} from 'mobx-react';
import {PokerStore} from 'app/store/PokerStore';
import {PokerController} from 'app/controllers/PokerController';

interface Props {
    pokerStore?: PokerStore
    controller?: PokerController
}

@inject('pokerStore', 'controller')
@observer
export default class PokerRoom extends React.Component<Props> {
    sitIn = (position: number, amount: number): void => {
        this.props.controller.sitIn(this.props.pokerStore.currentTable.table_id,
            position,
            amount,
            this.props.pokerStore.clearState);
    };

    buyIn = (amount: number): void => {
        this.props.controller.buyIn(this.props.pokerStore.currentTable.table_id,
            amount,
            this.props.pokerStore.clearState);
    };

    render(): ReactNode {
        const {currentTable: {pot, players, dealer_place, max_players, count_players, action_index},
            isDisabled,
            lastActionParams,
            isSitIn, isBuyIn,
            communityCards} = this.props.pokerStore;

        return <div className="poker-room">
            <div className="poker-table">
                <PokerPot pot={pot}/>
                <PokerBoard cards={communityCards}/>
                <PokerPlayers
                    players={players}
                    dealer_place={dealer_place}
                    max_players={max_players}
                    count_players={count_players}
                    action_index={action_index}
                    previousActionParams={lastActionParams}
                />
            </div>
            <PokerControllerPanel/>
            {isDisabled && this.props.pokerStore.currentTable.finished && <PokerMainMessage/>}
            {(isSitIn() || isBuyIn()) &&
                <PokerPopupJoinTable
                    onSitIn={this.sitIn}
                    onBuyIn={this.buyIn}
                    isSitIn={isSitIn()}
                    onCancelClick={this.props.pokerStore.clearState}
                    params={this.props.pokerStore.stateParams}
                />}
        </div>;
    }
}
