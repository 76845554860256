import React, {PureComponent} from 'react';
import {casinoGamesStore} from 'app/store/global';
import MultiLangsInterface from 'app/interfaces/MultiLangsInterface';
import I18n from 'app/i18n';
import {goToCasino} from 'app/utils/app_links';

interface FilterGroupProps {
    className?: string
    id: number
    name: string
    onClick?: () => void
    langs?: MultiLangsInterface
}

export default class FilterGroup extends PureComponent<FilterGroupProps> {
    selectFilter = (): void => {
        const {pathname, search} = window.location;
        casinoGamesStore.changeFilterGroup(this.props.id);
        this.props.onClick();

        if (pathname.includes('lootboxes') || search.includes('currency')) {
            goToCasino();
        }
    };

    render(): React.ReactNode {
        const {className, name, langs} = this.props;
        return <button className={className} onClick={this.selectFilter}>
            <span className="name">{langs && langs[I18n.locale] || name}</span>
        </button>;
    }
}
