import React, {Component, ReactNode, MouseEvent} from 'react';
import {inject, observer} from 'mobx-react';
import {SmileStore} from 'app/store/smile_store';
import {ScreenStore} from 'app/store/ScreenStore';
import {ChatStore} from 'app/store/ChatStore';
import {artifactStore} from 'app/store/global';
import {checkElementCookies, getLinkPath, isGuest, isDesktop, userSignedIn, setElementCookies,
    placeCaretAtEnd} from 'app/utils';
import {fetchMessages, cyrillic, MAX_TEXT_LENGTH, hideChatActions} from 'app/components/chat/chat_utils';
import {Link} from 'react-router-dom';
import {addGaEvent} from 'app/ga_methods';
// @ts-ignore
import doubleArrDownSvg from 'images/svg_icons/double-arr-down.svg';
import SliderContainer from 'app/components/slider_container';
import ChatMessages from 'app/components/chat/ChatMessages';
import I18n from 'app/i18n';
import $ from 'jquery';
import {appendToChatFromBuffer} from 'app/utils/share_to_chat';
import isEqual from 'lodash/isEqual';
import SmileMenu from './SmileMenu';
import {DivWithReference} from 'app/utils/DivWithReference';
import BarLoginMobile from 'app/components/auth/BarLoginMobile';
import ChatSelectTab from './ChatSelectTab';
import logoLink from 'site_version/com/logoLink';

interface ChatProps {
    chatStore?: ChatStore,
    smileStore?: SmileStore,
    screenStore?: ScreenStore,
    mobile?: boolean,
    fullScreen?: boolean
}

interface ChatState {
    height: string,
    opened: boolean,
    scrollDown: boolean,
    smileListOpened: boolean,
    isRecentSmilesLoading: boolean
}

interface ImageInterface {
    name: string
}

interface SmileResponse {
    images: ImageInterface[]
}

@inject('screenStore', 'smileStore', 'chatStore')
@observer
export default class Chat extends Component<ChatProps, ChatState> {
    constructor(props: ChatProps) {
        super(props);
        this.state = {
            height: '500px',
            isRecentSmilesLoading: false,
            opened: checkElementCookies('chat-menu'),
            scrollDown: false,
            smileListOpened: false
        };
        this.props.smileStore.loadSmiles();
    }

    container = React.createRef();

    smileList: React.RefObject<HTMLDivElement> = React.createRef();

    messageInput: React.RefObject<HTMLDivElement> = React.createRef();

    componentDidMount(): void {
        $('body').on('click', this.hideChatActions);
        appendToChatFromBuffer();
    }

    componentWillUnmount(): void {
        $('body').off('click', this.hideChatActions);
    }

    hideChatActions = (e: MouseEvent): void => {
        if ($(e.target).closest('.chat__actions').length === 0) {
            hideChatActions();
        }
        if ($(e.target).closest('.chat__input-smile').length === 0) {
            this.smilesListSlideUp();
        }
    };

    updateHeight = (): void => {
        this.setState({height: `${window.innerHeight - $('.header').height() - $('.tap_bar').height()}px`});
    };

    onlineElement(): ReactNode {
        return <span className="chat__counter">({this.props.chatStore.online} {I18n.t('chat_online')})</span>;
    }

    scrollToBottom = (): void => {
        this.setState({scrollDown: true}, () => {
            this.setState({scrollDown: false});
        });
    };

    openChat = (): void => {
        const opened = !this.state.opened;
        this.setState({opened});
        addGaEvent('Chat', opened ? 'closed' : 'opened');
        setElementCookies('chat-menu', opened);
    };

    placeCaretAtEnd = (): void => {
        placeCaretAtEnd($(this.messageInput.current).get(0));
    };

    insertUserName = (name: string): void => {
        $(this.messageInput.current).text(`[b]${name}[/b],\xa0`);
        this.placeCaretAtEnd();
    };

    selectRuTabByMessage = (message: string): boolean => this.props.chatStore.currentLang !== 'ru' && cyrillic(message);

    sendMessage = (): void => {
        $(this.smileList.current).slideUp();
        const element = $(this.messageInput.current);

        if (!element.text() || this.props.chatStore.disabledButton) {
            return;
        }

        this.props.chatStore.sendMessage(this.selectRuTabByMessage(element.text()),
            this.scrollToBottom,
            this.messageInput.current);
    };

    sendMessageHandle = (e: React.SyntheticEvent): void => {
        e.preventDefault();
        this.sendMessage();
    };

    handlerKeyPress = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        const BUTTON_ENTER_CODE = 13;

        if (e.charCode === BUTTON_ENTER_CODE) {
            this.sendMessage();
            e.preventDefault();
        }
        if ($(this.messageInput.current).text().length > MAX_TEXT_LENGTH || this.props.chatStore.disabledButton) {
            e.preventDefault();
        }
    };

    inputMouseDownHandler = (): void => {
        if ($(this.messageInput.current).is(':focus')) {
            return;
        }
        this.placeCaretAtEnd();
    };

    updateRecentSmiles = (response: SmileResponse): void => {
        const {smileStore} = this.props;

        if (!isEqual(smileStore.recent_smiles, response)) {
            const TIMEOUT = 400;
            this.setState(
                {isRecentSmilesLoading: true},
                () => setTimeout(() => {
                    this.setState({isRecentSmilesLoading: false}, () => smileStore.updateRecentSmiles(response));
                }, TIMEOUT)
            );
        }
    };

    smilesListSlideUp = (): void => {
        $(this.smileList.current).slideUp('done', () => {
            this.setState({smileListOpened: false});
        });
    };

    openSmilesList = (e: React.MouseEvent): void => {
        e.preventDefault();
        if (this.state.smileListOpened) {
            this.smilesListSlideUp();
        } else {
            this.setState({smileListOpened: true}, () => {
                $(this.smileList.current).slideDown(
                    'done',
                    () => this.props.smileStore.loadRecentSmiles(this.updateRecentSmiles)
                );
            });
        }
    };

    insertSmile = (smile: string): void => {
        const textInput = $(this.messageInput.current);
        const smile_name = smile.substring(0, smile.lastIndexOf('.'));

        if (smile_name.length + textInput.text().length <= MAX_TEXT_LENGTH) {
            textInput.text(`${textInput.text()} :${smile_name}:`);
        }
        this.placeCaretAtEnd();
    };

    render(): ReactNode {
        const {langs, currentLang} = this.props.chatStore;
        const {fullScreen, mobile, smileStore} = this.props;
        const {opened, smileListOpened, isRecentSmilesLoading, scrollDown} = this.state;
        return <div className={`chat${fullScreen ? ' chat--fullscreen' : ''} ${mobile ? 'chat--mobile' : ''}`}>
            <div className="chat__inner">
                <div id="block-user"/>
                <div id="block-list"/>
                <div className="chat__heading">
                    {fullScreen
                        ? <>
                            <div className="chat__title" key="chat__title">
                                <h2 className="chat__title-text">{I18n.t('chat_title')}</h2>
                                {this.onlineElement()}
                            </div>
                            <div className="chat__logo" key="chat__logo">
                                {logoLink && logoLink()}
                            </div>
                        </>
                        : <div className="chat__title">
                            <div className="chat__title-row">
                                <div className="chat__title-text">{I18n.t('chat_title')}</div>
                                <Link
                                    to={getLinkPath('/chat')}
                                    target="_blank"
                                    className="chat__title-icon"
                                >
                                    <svg height="22px" width="22px">
                                        <use xlinkHref="#svg-maximize"/>
                                    </svg>
                                </Link>
                            </div>
                            {this.onlineElement()}
                        </div>}
                    <div className="chat__controls">
                        <div className={`chat__lang-switcher${opened || fullScreen || mobile ? '' : ' hidden'}`}>
                            {langs.length > 1
                                ? langs.map(lang => <ChatSelectTab key={`tab-${lang}`} lang={lang}
                                    chatStore={this.props.chatStore}/>)
                                : null}
                        </div>
                        {fullScreen || mobile
                            ? null
                            : <button
                                data-js-opener="chat-menu"
                                onClick={this.openChat}
                                className={`act chat__opener${opened ? ' opener-active' : ''}`}
                            >
                                <img src={doubleArrDownSvg} alt="opener" height="12px" width="12px"/>
                            </button>}
                    </div>
                </div>
                <SliderContainer opened={fullScreen || mobile ? true : opened}>
                    <div className="chat__content">
                        <div className="chat__message-list custom-scroll-shadow has-shadow">
                            <ChatMessages
                                messages={fetchMessages(this.props.chatStore)} fullScreen={fullScreen}
                                currentLang={currentLang} insertUserName={this.insertUserName}
                                scrollDown={scrollDown}
                            />
                        </div>
                        {userSignedIn() &&
                        <form className="chat__message-add" onSubmit={this.sendMessageHandle}>
                            <div
                                onKeyPress={this.handlerKeyPress}
                                onMouseDown={this.inputMouseDownHandler}
                                className="chat__input-text js-chat-input"
                                data-placeholder={`${I18n.t('please_enter_you_message')}...`}
                                ref={this.messageInput}
                                contentEditable="true"
                            />
                            <div className="chat__input-smile">
                                <a className="act chat__smile-opener" onClick={this.openSmilesList}>
                                    <svg height="22px" width="22px">
                                        <use xlinkHref="#svg-smile"/>
                                    </svg>
                                </a>
                            </div>
                            <button className="act chat__submit">
                                <svg height="12px" width="10px">
                                    <use xlinkHref="#svg-send"/>
                                </svg>
                            </button>
                        </form>}
                    </div>
                </SliderContainer>
                {!isDesktop() && isGuest() && <BarLoginMobile/>}
                {smileListOpened && <DivWithReference ref={this.smileList} className={'chat__smile-list js-smile-list'}>
                    <SmileMenu
                        smiles={artifactStore.smileFilenames.concat(smileStore.smiles)}
                        insertSmile={this.insertSmile}
                        recent_smiles={smileStore.recent_smiles}
                        isLoading={isRecentSmilesLoading}/>
                </DivWithReference>}
            </div>
        </div>;
    }
}
