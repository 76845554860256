const FIXED_1 = 1;
const FIXED_2 = 2;
const FIXED_3 = 3;
const FIXED_8 = 8;
const DIVIDER_3 = 1000;

export const round3 = (num: string | number): string => (Math.round(Number(num) * DIVIDER_3) / DIVIDER_3).toString();

export const fixed3 = (num: string | number): string => Number(num).toFixed(FIXED_3);

export const fixed8 = (num: string | number): string => Number(num).toFixed(FIXED_8);

export const fixed2 = (num: string | number): string => Number(num).toFixed(FIXED_2);

export const fixed1 = (num: string | number): string => Number(num).toFixed(FIXED_1);

export const fixed2int = (num: string | number): string => {
    const fixed0 = Number(num).toFixed(0);
    return Number(fixed0) === Number(num) ? fixed0 : fixed2(num);
};
