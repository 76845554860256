import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    disabled?: boolean
    onClick: () => void
    onAllIn: () => void
    isAllIn: boolean
    bbAmount: string
}

export default class PokerPanelButtonBetRaise extends React.Component<Props> {
    render(): ReactNode {
        const {onClick, onAllIn, isAllIn, disabled, bbAmount} = this.props;
        return <button
            className="button-adaptive"
            disabled={disabled}
            onClick={isAllIn ? onAllIn : onClick}
        >
            {isAllIn
                ? <>
                    <span className="button-name">{I18n.t('poker.all_in_bank')}</span>
                    <span className="button-subtitle">{bbAmount} {I18n.t('poker.bb')}</span>
                </>
                : <>
                    <span className="button-name">{I18n.t('poker.bet')}</span>
                    <span className="button-subtitle">{bbAmount} {I18n.t('poker.bb')}</span>
                </>}
        </button>;
    }
}
