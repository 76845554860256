import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    onCheck: () => void
    onCall: () => void
    isCheck: boolean
    disabled?: boolean
    bbAmount?: string
}

export default class PokerPanelButtonCheckCall extends React.Component<Props> {
    render(): ReactNode {
        const {onCheck, onCall, disabled, isCheck, bbAmount} = this.props;

        return <button
            className="button-adaptive check_or_call"
            onClick={isCheck ? onCheck : onCall}
            disabled={disabled}
        >
            {isCheck
                ? <span className="button-name">{I18n.t('poker.check')}</span>
                : <>
                    <span className="button-name">{I18n.t('poker.call')}</span>
                    <span className="button-subtitle">{bbAmount} {I18n.t('poker.bb')}</span>
                </>}
        </button>;
    }
}
