import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    currentCount: number
}

export default class PokerPlayersAmount extends Component<Props> {
    render(): ReactNode {
        const {currentCount} = this.props;

        return <span className="poker-players-amount">{currentCount}/6 {I18n.t('poker.players')}</span>;
    }
}
