import {action, makeObservable, observable} from 'mobx';
import Get from 'app/utils/Get';
import ShopItemInterface from 'app/interfaces/shop/ShopItemInterface';
import UserPurchaseInterface from 'app/interfaces/shop/UserPurchaseInterface';
import {getLinkPath} from 'app/utils/link_helper';

interface ShopUserInterface {
    money: string,
    points: number
}

export interface ShopsStoreProps {
    shopStore?: ShopStore
}

export interface ShopItemsGamesInterface {
    '""': ShopItemInterface[],
    EGB: ShopItemInterface[],
    Other: ShopItemInterface[],
    dota2: ShopItemInterface[],
    csgo: ShopItemInterface[],
    other: ShopItemInterface[],
    Casino: ShopItemInterface[]
}

interface DataInterface {
    user: ShopUserInterface,
    product_items: ShopItemInterface[],
    money_items: ShopItemInterface[],
    other_items: ShopItemInterface[],
    lootboxes: ShopItemsGamesInterface,
    skins: ShopItemsGamesInterface,
    userPurchases: UserPurchaseInterface[],
    hasMorePurchases: boolean
}

export class ShopStore {
    @observable data: DataInterface = {
        hasMorePurchases: false,
        lootboxes: null,
        money_items: [],
        other_items: null,
        product_items: [],
        skins: null,
        user: {money: '0', points: 0},
        userPurchases: []
    };

    @observable loading = true;

    @observable shopUrl = null;

    purchasesPage = 0;

    constructor() {
        makeObservable(this);
    }

    @action
    setShopUrl = (shopUrl: string): void => {
        this.shopUrl = shopUrl;
    };

    @action
    setLoadingStatus = (loading: boolean): void => {
        this.loading = loading;
    };

    @action
    setData = (data: DataInterface): void => {
        this.data = Object.assign(this.data, data);
    };

    @action
    setHasMorePurchases = (hasMore: boolean): void => {
        this.data.hasMorePurchases = hasMore;
    };

    @action
    setUserPurchases = (purchases: UserPurchaseInterface[]): void => {
        this.data.userPurchases = purchases;
    };

    @action
    setPurchasesPage = (page: number): void => {
        this.purchasesPage = page;
    };

    updatePurchasesData = (purchases: UserPurchaseInterface[], hasMore: boolean, page: number): void => {
        this.setUserPurchases(purchases);
        this.setHasMorePurchases(hasMore);
        this.setPurchasesPage(page);
    };

    loadMore = (startLoading = false): Promise<void> => {
        this.setLoadingStatus(startLoading);
        const next_page = this.purchasesPage + 1;
        return new Get({
            params: {limit: next_page, page: next_page},
            url: getLinkPath('/shop/purchases')
        })
            .execute()
            .then(response => response.json())
            .then(response => {
                this.updatePurchasesData(this.data.userPurchases
                    ? this.data.userPurchases.concat(response.purchases)
                    : response.purchases,
                response.has_more,
                next_page);
            }).finally(() => {
                this.setLoadingStatus(false);
            });
    };

    updateShop = (startLoading: boolean): Promise<void> => {
        this.setLoadingStatus(startLoading);
        return new Get({params: {dataType: 'json'},
            url: getLinkPath(this.shopUrl ? this.shopUrl : '/shop')})
            .execute()
            .then(response => response.json())
            .then(response => {
                this.setData(response);
            }).finally(() => {
                this.setLoadingStatus(false);
            });
    };
}
