import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    enabled: boolean
    onClick: () => void
}

export default class PokerMenuButtonSound extends Component<Props> {
    render(): ReactNode {
        const text = this.props.enabled ? I18n.t('poker.bet_sound_mute') : I18n.t('poker.bet_sound_on');

        return <button className="poker-menu-container-button audio" onClick={this.props.onClick}>
            <svg className="svg-default">
                <use xlinkHref={this.props.enabled ? '#svg-volume0' : '#svg-volume70more'}/>
            </svg>
            <span className="name">{text}</span>
        </button>;
    }
}
