import React, {PureComponent} from 'react';
import SmartPromotionList from 'app/components/pages/home_page/SmartPromotion/SmartPromotionList';
import {inject, observer} from 'mobx-react';
import {ArticlesStore} from 'app/store/ArticlesStore';
import {isDesktop, newsArticles, promotionArticles} from 'app/utils';
import ArticleInterface from 'app/interfaces/articles/ArticleInterface';

interface LetsShowProps {
    articlesStore?: ArticlesStore;
    isCasinoPage?: boolean;
}

const HALVES_COUNT = 2;

@inject('articlesStore')
@observer
export default class LetsShow extends PureComponent<LetsShowProps> {
    constructor(props: LetsShowProps) {
        super(props);
        if (props.isCasinoPage) {
            this.props.articlesStore.initPromoNew();
        } else {
            this.props.articlesStore.loadMainArticles();
        }
    }

    render(): React.ReactNode {
        const {articlesStore, isCasinoPage} = this.props;
        const showSecondPromotionList = !isCasinoPage || isDesktop();
        const promoArticles = promotionArticles(isCasinoPage ? articlesStore.casinoPromoArticles : articlesStore.homePageArticles);
        const articles = newsArticles(articlesStore.homePageArticles);
        const showLetsShowBlock = isCasinoPage ? promoArticles.length > 0 : promoArticles.length > 0 || articles.length > 0;
        let firstPromotionListData: ArticleInterface[] | null = null;
        let secondPromotionListData: ArticleInterface[] | null = null;

        if (isCasinoPage && isDesktop()) {
            const middleIndex = Math.ceil(promoArticles.length / HALVES_COUNT);
            firstPromotionListData = promoArticles.slice(0, middleIndex);
            secondPromotionListData = promoArticles.slice(middleIndex);
        }

        return showLetsShowBlock && <div className="lets-show">
            <div className="lets-show__part">
                {isCasinoPage
                    ? <SmartPromotionList data={firstPromotionListData ?? promoArticles}
                        type="promotions" nameForControls="promotions"/>
                    : <SmartPromotionList data={articles}
                        type="news" nameForControls="news"/>
                }
            </div>
            {showSecondPromotionList && <div className="lets-show__part">
                <SmartPromotionList data={secondPromotionListData ?? promoArticles}
                    type="promotions" nameForControls="promotions"/>
            </div>}
        </div>;
    }
}
