import React, {Fragment, PureComponent} from 'react';
import {updateInfo} from 'app/common';
import I18n from 'app/i18n';
import {fixed2, getPromoLinkPath, TimeToStartTimerOld, userSignedIn} from 'app/utils';
import {Link} from 'react-router-dom';
import TakePartButton from 'app/components/compendium/take_part_button';
import TimeToStartTimer from 'app/utils/TimeToStartTimer';

const ONE_SECOND = 1000;
const DEFAULT_MONEY_FOR_NEXT_POINT = 5;
const HUNDRED_PERCENT = 100;
const TWELVE_HOURS = 43200;

export default class CountDownPromo extends PureComponent {
    constructor(props) {
        super(props);
        const {time_to_start, time_to_end} = props;
        this.state = {
            ending: this.hasEnding(time_to_end),
            started: this.hasStarted(time_to_start)
        };
    }

    hasStarted = time_to_start => time_to_start === 0;

    hasEnding = time_to_end => time_to_end < TWELVE_HOURS;

    isMountedComponent = false;

    componentDidMount() {
        this.isMountedComponent = true;
        this.timeHandler();
    }

    componentDidUpdate(prevProps) {
        const {time_to_start, time_to_end} = this.props;

        if (time_to_start !== prevProps.time_to_start) {
            this.setState({
                started: this.hasStarted(time_to_start),
                time_to_start
            });
            if (prevProps.time_to_start === 0 && time_to_start !== 0) {
                this.timeHandler();
            }
        }
        if (time_to_end !== prevProps.time_to_end) {
            this.setState({ending: this.hasEnding(time_to_end), time_to_end});
            if (prevProps.time_to_end === 0 && time_to_end !== 0) {
                this.timeHandler();
            }
        }
    }

    componentWillUnmount() {
        this.isMountedComponent = false;
    }

    isStarted = () => this.state.started;

    isEndingPromo = () => this.state.ending;

    isEndedPromo = () => this.state.time_to_end <= 0;

    get isFinished() {
        return this.props.finished;
    }

    get currentPercent() {
        const {percents_to_next_point} = this.props;
        return userSignedIn() && this.isStarted() && !this.isFinished
            ? HUNDRED_PERCENT - (percents_to_next_point || 0)
            : 0;
    }

    timeHandler = () => setTimeout(() => {
        if (this.isMountedComponent) {
            if (!this.isStarted()) {
                if (this.state.time_to_start > 0) {
                    this.setState({
                        started: this.hasStarted(this.state.time_to_start),
                        time_to_start: this.state.time_to_start - 1
                    });
                } else {
                    updateInfo();
                }
            } else if (!this.isEndingPromo()) {
                this.setState({
                    ending: this.hasEnding(this.state.time_to_end - 1),
                    time_to_end: this.state.time_to_end - 1
                });
            } else if (!this.isEndedPromo()) {
                if (this.state.time_to_end > 0) {
                    this.setState({
                        time_to_end: this.state.time_to_end - 1
                    });
                } else {
                    updateInfo();
                }
            }
            this.timeHandler();
        }
    }, ONE_SECOND);

    renderPoints(max_points, money_to_next_point, reset_points_at) {
        return this.isStarted()
            ? !this.isFinished && <div className="enigma-aside__info-column">
                {userSignedIn()
                    ? <Fragment>
                        <div>{I18n.t('user.promo_action.enigma.remaining_until_the_next_point')}:</div>
                        <div className="enigma-aside__highlighted" key="points">
                            ${fixed2(max_points ? 0 : money_to_next_point || DEFAULT_MONEY_FOR_NEXT_POINT)}
                        </div>
                        <div>
                            <TimeToStartTimer time_to_start={reset_points_at}>
                                {I18n.t('user.promo_action.enigma.points_cost_reset_in')}:
                            </TimeToStartTimer>
                        </div>
                    </Fragment>
                    : <div className="header__auth-links"><TakePartButton/></div>}
            </div>
            : <TimeToStartTimerOld {...this.state}/>;
    }

    renderLinks(prediction_points, promoActionLocaleKey) {
        return this.isStarted() && <div className="enigma-aside__links">
            {!this.isFinished && <Link
                to={getPromoLinkPath('/predictions')}
            >
                {I18n.t(`user.promo_action.${promoActionLocaleKey}.compendium_table`)}
            </Link>}
            {userSignedIn() && <Link
                to={getPromoLinkPath('/my_predictions')}
            >
                {this.isFinished
                    ? I18n.t(`user.promo_action.${promoActionLocaleKey}.my_predictions_title`)
                    : I18n.t(`user.promo_action.${promoActionLocaleKey}.my_predictions`, {
                        count: prediction_points || 0
                    })
                }
            </Link>}
            <Link to={getPromoLinkPath('/rating')}>
                {I18n.t(`user.promo_action.${promoActionLocaleKey}.results_table`)}
            </Link>
        </div>;
    }
}
