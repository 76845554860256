import React from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from 'app/components/toasts/Contents/ToastContent';
import BaseToast from 'app/components/toasts/BaseToast';
import {MAIN_CONTAINER_ID} from 'app/utils/Consts';
import I18n from 'app/i18n';
import {showDocumentsUploadPopup} from 'app/components/DocumentsUpload/upload_documents';
import {inject, observer} from 'mobx-react';

@inject('userStore')
@observer
export default class NeedVerification extends BaseToast {
    rootToastElement: React.RefObject<ToastContent<unknown>> = React.createRef();

    onClick = (): void => {
        showDocumentsUploadPopup();
        this.props?.userStore.updateVerificationNotification();
    };

    onClose = (): void => {
        this.props?.userStore.updateVerificationNotification();
    };

    notify = (): React.ReactText => toast.error(
        <ToastContent ref={this.rootToastElement} content={I18n.t('user.need_verification')}>
            <use xlinkHref="#svg-notif-error" />
        </ToastContent>,
        this.options({autoClose: false, closeButton: true, closeOnClick: true, containerId: MAIN_CONTAINER_ID, onClose: this.onClose})
    );
}

