import {BaseController} from 'app/controllers/BaseController';
import {action, makeObservable, observable} from 'mobx';
import {FormEvent} from 'react';
import {IncubatorGameModel} from 'app/models/IncubatorGameModel';
import {calculateSecondCoef} from 'modules/calculateSecondCoef';
import {fixCoef} from 'modules/fixCoef';
import {UserStore} from 'app/store/UserStore';
import {DRAW, GAMER_1, GAMER_2} from 'app/lines/line_helpers';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import ErrorsServerInterface from 'app/interfaces/ErrorsServerInterface';
import Get, {ParamsInterface} from 'app/utils/Get';
import {IncubatorUsersBetsStore} from 'app/store/IncubatorUsersBetsStore';
import {goToIncubatorLineEdit, goToIncubatorMyLines} from 'app/utils/app_links';
import {incubatorShiftStore} from 'app/store/global';
import {fixed2, fixed3} from 'app/utils/fixed';
import I18n from 'app/i18n';
import {MIN_PLEDGE} from 'app/utils/Consts';
import {incubatorGameAttributesDefault} from 'app/models/incubatorGameAttributesDefault';
import {validateAmount} from 'app/utils/ValidateAmount';
import {updateIncubatorLineText} from 'app/utils/updateIncubatorLineText';
import {betMoney, goToRoute} from 'app/utils';
import {promiseToast, showErrorToast} from 'app/components/toasts/promiseToast';
import {validationCoef} from 'app/utils/ValidateCoef';
import {incubatorTopUpErrorModal} from 'app/components/pages/incubator/IncubatorTopUpModal';
import {IncubatorShiftController} from 'app/controllers/IncubatorShiftController';
import IncubatorLinesTypeInterface from 'app/interfaces/IncubatorLinesTypeInterface';

const COEF_TO_SET_UP_TO_NULL = 10;
const MIN_COEF = 1.01;
const MAX_COEF = 10;
const MIN_WIN_SCORE = 1;
const PAGE_TOP = 1000;

const AUTO_LAN_LOW_COEF = 1.26;
const AUTO_LAN_HIGH_COEF = 3.5;
const AUTO_AVG_LOW_COEF = 1.466;
const AUTO_AVG_HIGH_COEF = 2.5;
const LAN_MATCH = 0.9;
const AVERAGE_MATCH = 0.88;

const ERRORS_WITHOUT_TOAST = [
    'gamer_1_id',
    'gamer_2_id',
    'stream_link',
    'pledge',
    'lines_type_id'
];

export const marginPercentValue = (coef: number | string): number => {
    const valCoef = Number(coef);
    let percent = 0.86;
    if (valCoef <= AUTO_LAN_LOW_COEF || valCoef >= AUTO_LAN_HIGH_COEF) {
        percent = LAN_MATCH;
    } else if (valCoef <= AUTO_AVG_LOW_COEF || valCoef >= AUTO_AVG_HIGH_COEF) {
        percent = AVERAGE_MATCH;
    }
    return percent;
};

export const calculate2Coef = (coef: string | number): string => {
    const percent = marginPercentValue(coef);
    const coefNumber = Number(coef);

    if (isNaN(coefNumber)) {
        return '';
    }
    if (coefNumber >= COEF_TO_SET_UP_TO_NULL) {
        return '';
    }

    return fixed3(fixCoef(calculateSecondCoef(coef, percent, coefNumber), MIN_COEF, MAX_COEF));
};

export class IncubatorCreateLineController extends BaseController {
    userStore: UserStore;

    @observable incubatorGame: IncubatorGameModel = null;

    @observable bets: IncubatorUsersBetsStore = null;

    @observable shiftPledge: string = null;

    @observable disabled = false;

    @observable linesTypes: IncubatorLinesTypeInterface[] = [];

    incubatorShiftController: IncubatorShiftController;

    constructor(userStore: UserStore, lineId: number, incubatorShiftController: IncubatorShiftController) {
        super();
        makeObservable(this);
        this.userStore = userStore;
        this.updateIncubator(new IncubatorGameModel(
            incubatorGameAttributesDefault(lineId, userStore.user.incubator_last_game_id), userStore
        ));
        this.setDisabled(false);
        this.setLinesTypes([]);
        this.getLinesTypes();
        this.incubatorShiftController = incubatorShiftController;

        if (!this.incubatorGame.isNewRecord()) {
            this.setNewLine(lineId);
        }

        this.onSelectGame = this.onSelectGame.bind(this);
    }

    @action
    setNewLine(lineId: number): void {
        this.incubatorGame.lineStore.stopListening();
        this.incubatorGame.attributes.id = lineId;
        this.incubatorGame.lineStore.setNewLine(lineId, this.userStore.user.id);
        this.incubatorGame.load();
        this.setBetsStore(new IncubatorUsersBetsStore(this.userStore.user.id, lineId));
        this.bets.loadCollection();
    }

    @action
    setBetsStore(store: IncubatorUsersBetsStore): void {
        this.bets = store;
    }

    @action
    setDisabled(value: boolean): void {
        this.disabled = value;
    }

    @action
    updateIncubator(incubatorGame: IncubatorGameModel): void {
        this.incubatorGame = incubatorGame;
    }

    @action
    onSelectGame(game_id: number): void {
        this.updateAttributeValue('game_id', game_id);
    }

    @action
    setEsportsAttribute(esports: boolean): void {
        this.incubatorGame.attributes.esports = esports;
    }

    @action
    onChangeStreamLink(stream_link: string): void {
        this.updateAttributeValue('stream_link', stream_link.toLocaleLowerCase());
    }

    @action
    onChangePledge(pledge: string): void {
        this.updateAttributeValue('pledge', validateAmount(pledge));
    }

    @action
    onChangeGamer1Id(gamer1Id: number): void {
        this.updateAttributeValue('gamer_1_id', gamer1Id);
    }

    @action
    onChangeCoef1(coef_1: string): void {
        const amount = validationCoef(coef_1, this.incubatorGame.attributes.coef_1);
        this.updateAttributeValue('coef_1', amount);
        this.updateAttributeValue('coef_2', calculate2Coef(amount));
    }

    @action
    onChangeGamer2Id(gamer2Id: number): void {
        this.updateAttributeValue('gamer_2_id', gamer2Id);
    }

    @action
    onChangeCoef2(coef_2: string): void {
        const amount = validationCoef(coef_2, this.incubatorGame.attributes.coef_2);
        this.updateAttributeValue('coef_2', amount);
        this.updateAttributeValue('coef_1', calculate2Coef(amount));
    }

    @action
    onChangeCoefChanging(coef_changing: string | number): void {
        this.updateAttributeValue('coef_changing', coef_changing);
    }

    @action
    onChangeDelayBet(delay_for_bets_time: string | number): void {
        this.updateAttributeValue('delay_for_bets_time', delay_for_bets_time);
    }

    @action
    onChangeHideNickname(hide_creator: boolean): void {
        this.updateAttributeValue('hide_creator', Number(hide_creator));
    }

    @action
    onChangeConsiderPersonalLimits(consider_personal_limits: boolean): void {
        this.updateAttributeValue('consider_personal_limits', Number(consider_personal_limits));
    }

    @action
    onChangeScore1(score1: string): void {
        this.updateAttributeValue('gamer_1_score', validateAmount(score1));
    }

    @action
    onChangeScore2(score2: string): void {
        this.updateAttributeValue('gamer_2_score', validateAmount(score2));
    }

    @action
    onChangeWinner(winner: string): void {
        this.updateAttributeValue('winner', Number(winner));
    }

    @action
    onSelectLinesType(lines_type_id: number): void {
        this.updateAttributeValue('lines_type_id', lines_type_id);
    }

    @action
    onClickWinner(element: HTMLInputElement, gamer: number): void {
        if (this.incubatorGame.attributes.winner === gamer) {
            element.checked = false;
            this.onChangeWinner(null);
        }
    }

    onClickWinner1(element: HTMLInputElement): void {
        this.onClickWinner(element, GAMER_1);

        this.onChangeScore1(MIN_WIN_SCORE.toString());
        this.onChangeScore2('0');
    }

    onClickWinner2(element: HTMLInputElement): void {
        this.onClickWinner(element, GAMER_2);

        this.onChangeScore1('0');
        this.onChangeScore2(MIN_WIN_SCORE.toString());
    }

    onClickDraw(element: HTMLInputElement): void {
        this.onClickWinner(element, DRAW);
    }

    @action
    onSubmitForm(e: FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        this.setDisabled(true);
        this.incubatorGame.setUpdateText(updateIncubatorLineText(this.incubatorGame));
        const isNewRecord = this.incubatorGame.isNewRecord();
        this.incubatorGame.save()
            .then(({attributes}) => {
                if (isNewRecord) {
                    this.userStore.updateIncubatorLastGame(attributes.game_id);
                    this.userStore.addIncubatorLineId(attributes.id);
                    goToIncubatorLineEdit(attributes.id);
                    window.scrollTo(PAGE_TOP, 0);
                    toastSuccess(I18n.t('line_created'));
                } else {
                    toastSuccess(this.incubatorGame.updateText);
                }
                if (attributes.winner > 0) {
                    goToIncubatorMyLines();
                }
            })
            // eslint-disable-next-line max-statements
            .catch((errors: ErrorsServerInterface): void => {
                const [errorKey] = Object.keys(errors);
                const [error] = errors[errorKey];

                if (errorKey === 'not_enough_pledge') {
                    incubatorTopUpErrorModal(this.incubatorShiftController, error);
                    return;
                }

                if (ERRORS_WITHOUT_TOAST.includes(errorKey)) {
                    return;
                }

                if (error) {
                    toastError(error);
                    return;
                }
                showErrorToast(errors);
            })
            .finally(() => {
                this.setDisabled(false);
            });
    }

    @action
    onChangeShiftPledge(pledge: string): void {
        this.shiftPledge = this.userPledge(pledge);
    }

    addTime = (params: ParamsInterface): Promise<Response> => promiseToast(this.incubatorGame.apiService
        .postAction('add_time', params), I18n.t('bet_acceptance', {value: params.time}));

    close = (params: {id: number}): Promise<Response> => promiseToast(this.incubatorGame.apiService
        .postAction('close', params), I18n.t('closed_line'));

    acceptAndClose = (params: {id: number}): Promise<Response> => promiseToast(this.incubatorGame.apiService
        .postAction('accept_and_close', params), I18n.t('accept_closed_line'));

    hideLine = (params: {id: number}): void => {
        const {hidden} = this.incubatorGame.lineStore.collection;

        promiseToast(this.incubatorGame.apiService.postAction('hide', params),
            hidden ? I18n.t('returned_line') : I18n.t('hide_line'));
    };

    cancelLine = (): void => {
        promiseToast(this.incubatorGame.apiService.postAction('cancel', {id: this.incubatorGame.id}),
            I18n.t('match_was_cancelled_label')).finally(() => goToRoute('/play/incubator/my_lines'));
    };

    addPledge = (pledge: number): void => {
        promiseToast(this.incubatorGame
            .apiService.postAction('add_pledge', {id: this.incubatorGame.id, line: {add_pledge: pledge}}),
        I18n.t('topped_up_line_pledge', {amount: betMoney({amount: pledge})}));
    };

    @action
        setLinesTypes = (linesTypes: IncubatorLinesTypeInterface[]): void => {
            this.linesTypes = linesTypes;
        };

    getLinesTypes = (): void => {
        new Get({url: '/incubator/lines_types'}).execute()
            .then(response => response.json())
            .then(response => {
                this.setLinesTypes(response);
            });
    };

    profitClass(coefWin: string): string {
        return Number(coefWin) > 0 ? 'plus' : 'minus';
    }

    checkMinPledge = (): boolean => {
        if (Number(this.incubatorGame.attributes.pledge) < MIN_PLEDGE) {
            this.incubatorGame.setErrors({pledge: [I18n.t('pledge_must_be_greater')]});
            toastError(I18n.t('pledge_must_be_greater', {amount: MIN_PLEDGE}));
            return true;
        }
        return false;
    };

    private userPledge(pledge: string): string {
        const currentShiftPledge = incubatorShiftStore.currentShift()?.pledge;
        let result = pledge;

        if (Number(result) > Number(currentShiftPledge)) {
            result = fixed2(currentShiftPledge);
        }

        return result;
    }

    updateAttributeValue(attribute: string, value: string|number): void {
        this.incubatorGame.attributes[attribute] = value;
    }

    findLinesType = (id: number): IncubatorLinesTypeInterface => this.linesTypes.find(item => item.id === id);

    linesTypeBySport = (): IncubatorLinesTypeInterface[] => this.linesTypes.filter(
        item => item.deleted === false
    );

    currentGameLineType = (): IncubatorLinesTypeInterface => this.linesTypes.find(
        item => item.name.toLowerCase() === 'current game' && item.deleted === false
    );
}
