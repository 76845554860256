import React, {ReactNode} from 'react';
import {ONE_SECOND_MS} from 'app/store/constants/storeConstants';

interface Props {
    target: number
}

interface State {
    percentage: number
}

const BASE_TIME_TO_MOVE = 20000;
const MULTIPLIER_TO_MILLISECONDS = 1000;

export default class PokerPlayerTimer extends React.Component<Props, State> {
    private interval: NodeJS.Timeout | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            percentage: 100
        };
    }

    componentDidMount(): void {
        this.startTimer();
    }

    componentWillUnmount(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    startTimer = (): void => {
        this.interval = setInterval(() => {
            const now = Date.now();
            const target = this.props.target * MULTIPLIER_TO_MILLISECONDS;
            const start = target - BASE_TIME_TO_MOVE;

            if (now >= target) {
                this.setState({percentage: 0});
                if (this.interval) {
                    clearInterval(this.interval);
                }
            } else {
                const elapsed = now - start;
                const total = target - start;
                const remainingPercentage = Math.max(100 - elapsed / total * 100, 0);
                this.setState({percentage: parseFloat(remainingPercentage.toFixed(1))});
            }
        }, ONE_SECOND_MS);
    };

    render(): ReactNode {
        return <div className="poker-player-timer">
            <span className="progress" style={{width: `${this.state.percentage}%`}}/>
        </div>;
    }
}
