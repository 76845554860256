import React from 'react';
import I18n from 'app/i18n';
import {NavLink} from 'react-router-dom';

const AffiliatesLandingTop: React.FC = () => <>
    <NavLink
        className="button-become-partner"
        to="https://my.egbaffiliates.com/registration"
        rel="noreferrer noopener"
    >{I18n.t('affiliate.become_partner')}</NavLink>
    <div className="landing-promo-sections">
        <div className="landing-promo-section">
            <svg className="svg-default section-icon">
                <use xlinkHref="#svg-affiliates-icon-1"/>
            </svg>
            <div>
                <span className="section-title">{I18n.t('affiliate.banner_text_1')}</span>
                <span className="section-title">{I18n.t('affiliate.banner_text_1_1')}</span>
            </div>
        </div>
        <div className="landing-promo-section">
            <svg className="svg-default section-icon">
                <use xlinkHref="#svg-affiliates-icon-2"/>
            </svg>
            <span className="section-title">{I18n.t('affiliate.banner_text_2')}</span>
        </div>
        <div className="landing-promo-section">
            <svg className="svg-default section-icon">
                <use xlinkHref="#svg-affiliates-icon-3"/>
            </svg>
            <span className="section-title">{I18n.t('affiliate.banner_text_3')}</span>
        </div>
        <div className="landing-promo-section">
            <svg className="svg-default section-icon">
                <use xlinkHref="#svg-affiliates-icon-4"/>
            </svg>
            <span className="section-title">{I18n.t('affiliate.banner_text_4')}</span>
        </div>
        <div className="landing-promo-section">
            <svg className="svg-default section-icon">
                <use xlinkHref="#svg-affiliates-icon-5"/>
            </svg>
            <span className="section-title">{I18n.t('affiliate.banner_text_5')}</span>
        </div>
        <div className="landing-promo-section banner">
            <span className="section-subtitle">{I18n.t('affiliate.banner_text_6')}</span>
            <span className="section-big-title">{I18n.t('affiliate.banner_text_6_1')}</span>
            <span className="section-title">{I18n.t('affiliate.banner_text_6_2')}</span>
        </div>
    </div>
</>;

export default AffiliatesLandingTop;
