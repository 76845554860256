/* eslint-disable max-params */

import {BaseController} from 'app/controllers/BaseController';
import Post from 'app/utils/Post';
import {action} from 'mobx';
import {PokerStore} from 'app/store/PokerStore';
import {toastError} from 'app/components/toasts/liteToast';

const BASE_AMOUNT = 10;

export class PokerController extends BaseController {
    store: PokerStore;

    constructor(store: PokerStore) {
        super();
        this.store = store;
    }

    @action
    sitOut(): void {
        new Post({
            url: `/poker/tables/${this.store.currentTable.table_id}/sit_out`
        }).execute()
            .then(response => response.json())
            .then(response => {
                if (!response.success) {
                    toastError(response.message);
                }
            });
    }

    @action
    sitIn(table_id: string, position: number, amount = BASE_AMOUNT, onSucces: () => void = null): void {
        this.make_action(table_id, 'sit_in', onSucces, {amount, position});
    }

    @action
    check(table_id: string): void {
        this.make_action(table_id, 'check');
    }

    @action
    call(table_id: string): void {
        this.make_action(table_id, 'call');
    }

    @action
    fold(table_id: string): void {
        this.make_action(table_id, 'fold');
    }

    @action
    allIn(table_id: string): void {
        this.make_action(table_id, 'all_in');
    }

    @action
    bet(table_id: string, amount: string, onSucces: () => void): void {
        this.make_action(table_id, 'bet', onSucces, {amount});
    }

    @action
    dropOut(table_id: string): void {
        this.make_action(table_id, 'drop_out');
    }

    @action
    buyIn(table_id: string, amount: number, onSucces: () => void = null): void {
        this.make_action(table_id, 'buy_in', onSucces, {amount});
    }

    @action
    make_action(table_id: string, action: string, onSuccess?: () => void, params = {}): void {
        new Post({
            params,
            url: `/poker/tables/${table_id}/${action}`
        }).execute()
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    onSuccess && onSuccess();
                } else {
                    toastError(response.message);
                }
            });
    }
}
