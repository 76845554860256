import React, {useCallback, useState} from 'react';
import I18n from 'app/i18n';

interface Props {
    faqItem: {q: string, a: string}
}

const AffiliatesLandingFaqItem: React.FC<Props> = ({faqItem}) => {
    const [openItem, setOpenItem] = useState<boolean>(false);

    const onClick = () => {
        setOpenItem(!openItem);
    };

    const handleOnClick = useCallback(() => onClick(), [onClick]);

    return <>
        <div className={`landing-promo-faq-item${openItem ? ' active' : ''}`} onClick={handleOnClick}>
            <span className="title-faq">{I18n.t(faqItem.a)}</span>
            <div className="description-faq">
                <p>{I18n.t(faqItem.q)}</p>
            </div>
        </div>
    </>;
};

export default AffiliatesLandingFaqItem;
