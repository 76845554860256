import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import PlayerInterface from 'app/interfaces/poker/PlayerInterface';

interface Props {
    sitOut: () => void
    sitIn: () => void
    player: PlayerInterface
}

export default class PokerMenuButtonPause extends Component<Props> {
    render(): ReactNode {
        const {player, sitOut, sitIn} = this.props;
        const text = player?.sat_out ? I18n.t('poker.bet_sit_in') : I18n.t('poker.bet_sit_out');

        return <button disabled={!player} className="poker-menu-container-button pause" onClick={player?.sat_out ? sitIn : sitOut}>
            <svg className="svg-default">
                <use xlinkHref={player?.sat_out ? '#svg-play-icon' : '#svg-pause-icon'}/>
            </svg>
            <span className="name">{text}</span>
        </button>;
    }
}
