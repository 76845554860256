import React, {ReactNode} from 'react';
import PokerButtonTakePlace from 'app/components/poker/poker_players/poker_take_place/PokerButtonTakePlace';
import PokerDealer from 'app/components/poker/poker_players/poker_dealer/PokerDealer';
import PlayerInterface from 'app/interfaces/poker/PlayerInterface';
import {selectCard} from 'app/utils/poker/selectCard';
import {betMoney} from 'app/utils';
import {observer} from 'mobx-react';
import PokerCard from 'app/components/poker/poker_cards/PokerCard';
import PokerPlayerTimer from 'app/components/poker/poker_players/poker_player_timer/PokerPlayerTimer';
import {isoToUnix} from 'app/utils/time/IsoToUnix';
import Timer from 'app/components/reusable/Timer';

interface Props {
    position: number
    isBigBlind: boolean
    active?: boolean
    player?: PlayerInterface
    onSitIn?: (position: number) => void
    satOut?: boolean
    actionEnds?: number
    showAction?: boolean
    action?: string
    showdownState?: boolean
}

const HALF_OF_PLAYERS = 3;

@observer
export default class PokerPlace extends React.Component<Props> {
    renderCards = (): ReactNode => {
        const {player} = this.props;

        if (player.fold || player.sat_out) {
            return <></>;
        }

        return player.cards && player.cards.length > 0
            ? <>
                <div className="player-card-first animate">
                    <div className="player-card-inner">
                        <span className="player-card-face-first"></span>
                        {selectCard(player.cards[0])}
                    </div>
                </div>
                <div className="player-card-second animate">
                    <div className="player-card-inner">
                        <span className="player-card-face-second"></span>
                        {selectCard(player.cards[1])}
                    </div>
                </div>
            </>
            : <>
                <div className="player-card-first">
                    <div className="player-card-inner">
                        <span className="player-card-face-first"></span>
                        <PokerCard suit={'hearts'} sign={'K'}/>
                    </div>
                </div>
                <div className="player-card-second">
                    <div className="player-card-inner">
                        <span className="player-card-face-second"></span>
                        <PokerCard suit={'clubs'} sign={'K'}/>
                    </div>
                </div>
            </>;
    };

    render(): ReactNode {
        const {position, player, isBigBlind, onSitIn, active, satOut, actionEnds, action, showAction, showdownState} = this.props;
        const bet_position = position <= HALF_OF_PLAYERS ? 'bet-bottom' : 'bet-top';
        const activeClassName = active ? ' active' : '';
        const depositAmount = betMoney({amount: player?.chips || 0});

        return <div className={`poker-player place-${position} ${bet_position}${activeClassName}`}>
            {onSitIn && <PokerButtonTakePlace onSitIn={onSitIn} position={position - 1}/>}
            {player && <>
                <div className="player-cards">
                    {this.renderCards()}
                </div>
                <div className={`poker-player-about my-player${satOut ? ' sitout' : ''}`}>
                    <span className="name" title={player.name}>{player.name}</span>
                    <span className="deposit">
                        {player.sat_out && player.drop_at ? <Timer endTime={isoToUnix(player.drop_at)} onlyMinutes/> : depositAmount}
                    </span>
                </div>
                {player.bet && player.bet > 0 && <div className="poker-player-bet">
                    <span className="amount">{betMoney({amount: player.bet})}</span>
                </div>}
                {active && <PokerPlayerTimer target={actionEnds}/>}
                {isBigBlind && <PokerDealer/>}
                {satOut && <span className="poker-player-sitout">
                    <svg className="svg-default">
                        <use xlinkHref="#svg-sleep-icon"/>
                    </svg>
                </span>}
                {!showdownState && player.winner && <span className="poker-player-win">
                    <svg className="svg-default">
                        <use xlinkHref="#svg-win-icon"/>
                    </svg>
                </span>}
                {showAction && <div className="poker-player-action active">
                    <span>{action}</span>
                </div>}
            </>}
        </div>;
    }
}
