import {getLinkPath, getTableLinkPath, goToRoute, simpleGoToRoute} from 'app/utils/link_helper';
import {modalConfirm} from 'app/utils/modals/popups';
import I18n from 'app/i18n';
import {scrollToTop} from 'app/utils';
import {getNavigate} from './navigation';

export const linkMarketplace = () => getLinkPath('/shop/marketplace');

export const goToLinkMarketplace = (artifact = null) => {
    if (artifact) {
        simpleGoToRoute(`${linkMarketplace()}/${artifact.slug}/orders`);
    } else {
        simpleGoToRoute(linkMarketplace());
    }
};

export const linkLootboxes = () => getLinkPath('/shop/lootboxes');

export const goToLinkLootboxes = () => simpleGoToRoute(linkLootboxes());

export const goToShopLootbox = (slug, points, isCasinoPage = false) => {
    const navigate = getNavigate();
    const pathname = getLinkPath(`/${isCasinoPage ? 'casino' : 'shop'}/lootboxes/${slug}`);
    const search = points ? '?currency=points' : '';
    navigate(`${pathname}${search}`);
};

export const goToShopOtherItem = (name, points) => {
    const navigate = getNavigate();
    const pathname = getLinkPath(`/shop/other/${name}`);
    const search = points ? '?currency=points' : '';
    navigate(`${pathname}${search}`);
};


export const linkMessages = () => getLinkPath('/messages');

export const linkPredictionsTable = (promoAction, withCurrentLocale = true) => {
    const path = `/promo/${promoAction.url}/predictions`;

    if (withCurrentLocale) {
        return getLinkPath(path);
    }

    return path;
};

export const goToMarketplaceWithName = name => {
    const navigate = getNavigate();
    const pathname = getLinkPath(`/shop/marketplace/${name}/orders`);
    navigate(pathname);
};

const myHeroPath = '/my_profile/hero';

export const linkMyHero = (withCurrentLocale = true, recipeId = null) => {
    let path = myHeroPath;

    if (recipeId) {
        path = `${path}/item/${recipeId}`;
    }

    if (withCurrentLocale) {
        return getLinkPath(path);
    }

    return getLinkPath(path);
};

export const linkMySkin = steamTradeId => `${myHeroPath}/skin/${steamTradeId}`;

export const linkSimpleBets = () => getTableLinkPath('/play/bets');

export const linkSport = () => getTableLinkPath('/sport');

export const linkSimpleSportBets = () => getLinkPath('/play/simple_sport_bets');

export const linkMySimpleBets = () => getLinkPath('/my_bets');

export const linkLine = id => getLinkPath(`/play/bets#${id}`);

export const linkIOSDownload = () => getLinkPath('/apps/ios/download/');

export const linkAndroid = () => getLinkPath('/apps/android');

export const linkDepositSystem = slug => getLinkPath(`/deposits/${slug}`);

export const linkShopIncubatorScroll = () => getLinkPath('/shop/marketplace/cbt-scroll/orders');

export const goToMyHero = () => simpleGoToRoute(linkMyHero());

export const goToMyHeroItem = param => simpleGoToRoute(linkMyHero(true, param));

export const goToMySteamTrade = steamTradeId => simpleGoToRoute(linkMySkin(steamTradeId));

export const goToLinkSimpleBets = (esport = true) => simpleGoToRoute(esport ? linkSimpleBets() : linkSimpleSportBets());

export const goToLinkMessages = () => simpleGoToRoute(linkMessages());

export const goToLinkSport = () => simpleGoToRoute(linkSport());

export const goToLinkMySimpleBets = () => simpleGoToRoute(linkMySimpleBets());

export const goToLinkSimpleSportBets = () => simpleGoToRoute(linkSimpleSportBets());

export const goToNews = slug => goToRoute(`/news/${slug}`);

export const goToPromo = slug => goToRoute(`/promotions/${slug}`);

export const goToDeposits = callback => {
    goToRoute('/deposits');
    callback && callback();
};

export const goToCashouts = () => goToRoute('/cashouts');

export const goToMyProfileSettings = () => goToRoute('/my_profile/settings');

export const goToPrizeLootbox = () => goToRoute('/my_profile/prizes', {search: '?type=lootboxes'});

export const goToCasino = () => goToRoute('/casino');

export const confirmGoToDeposits = () => modalConfirm(
    `${I18n.t('insufficient_funds')} <br/> ${I18n.t('would_you_like_to_make_a_deposit')}`,
    () => goToDeposits(scrollToTop)
);

export const linkEsportIncubatorNew = () => getLinkPath('/play/incubator/new');

export const linkIncubator = () => getLinkPath('/play/incubator/all');

export const linkIncubatorNew = () => linkEsportIncubatorNew();

export const goToLinkIncubator = (esport = true) => simpleGoToRoute(linkIncubator(esport));

export const goToIncubatorLineEdit = betId => simpleGoToRoute(incubatorEsportLineEdit(betId));

export const incubatorEsportLineEdit = betId => getLinkPath(`/play/incubator/${betId}`);

export const linkIncubatorMyLines = () => linkIncubatorEsportMyLines();

export const linkIncubatorEsportMyLines = () => getLinkPath('/play/incubator/my_lines');

export const goToIncubatorMyLines = () => simpleGoToRoute(linkIncubatorMyLines());

export const linkIncubatorHelpStaticPage = () => getLinkPath(
    `/public/${I18n.locale === 'ru' ? 'ru' : 'en'}_incubator_section_cbt`
);
