import React, {Component} from 'react';
import {i18n_t} from 'app/i18n';
import {inject, observer} from 'mobx-react';
import {scrollToTop} from 'app/utils';
import {getLinkPath} from 'app/utils/link_helper';
import {Link} from 'react-router-dom';
import ShopItem from 'app/components/shop/lootboxes/shop_item.jsx';
import {ShopsStoreProps} from 'app/store/ShopStore';
import ShopItemInterface from 'app/interfaces/shop/ShopItemInterface';
import AsideItem from 'site_version/com/AsideItem';
import {OPEN_LOOTBOX_UPDATE_TIMEOUT, DELAY_BEFORE_OPENING_LOOTBOX} from 'app/store/ArtifactStore';
import SmartLoader from 'app/components/reusable/SmartLoader';

interface RecipeStoreInterface {
    onSale: ShopItemInterface[];
    updateRandomLootboxes: () => void
}

interface ShopLootboxInterface {
    updateAnimationFromOtherPageStatus: (value: boolean) => void;
}

interface LootboxAsideProps extends ShopsStoreProps {
    recipeStore?: RecipeStoreInterface;
    shopLootboxStore?: ShopLootboxInterface;
}

const UPDATE_TIME_LOOTBOXES = 10000;

@inject('recipeStore', 'shopStore', 'shopLootboxStore')
@observer
export default class LootboxAside extends Component<LootboxAsideProps> {
    interval: ReturnType<typeof setInterval> | undefined;

    componentDidMount(): void {
        this.interval = setInterval(() => {
            this.props.recipeStore.updateRandomLootboxes();
        }, UPDATE_TIME_LOOTBOXES);
    }

    componentWillUnmount(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    lootboxItem = (lootbox: ShopItemInterface): React.ReactNode => <div className="lootbox-aside__item" key={lootbox.id}>
        <ShopItem
            {...lootbox}
            currency="usd"
            user={this.props.shopStore.data.user}
            startAnimationOnNonShopPage={this.purchaseNotFromShopPage}
        />
    </div>;

    header = (): React.ReactNode => <div className="chat__heading">
        <div className="chat__title__title">
            <div className="chat__title-text">
                <span className="aside-preview-post__title">
                    <Link to={getLinkPath('/shop/lootboxes')} onClick={scrollToTop}>
                        {i18n_t('lootboxes')}
                    </Link>
                </span>
            </div>
        </div>
    </div>;

    purchaseNotFromShopPage = (): void => {
        const {shopLootboxStore} = this.props;

        if (window.location.pathname.includes('shop')) {
            scrollToTop();
        } else {
            shopLootboxStore.updateAnimationFromOtherPageStatus(true);
            setTimeout(() => {
                shopLootboxStore.updateAnimationFromOtherPageStatus(false);
            }, OPEN_LOOTBOX_UPDATE_TIMEOUT + DELAY_BEFORE_OPENING_LOOTBOX);
        }
    };

    render(): React.ReactNode {
        const {onSale} = this.props.recipeStore;

        return <AsideItem header={this.header()} name="lootbox-aside" itemClassName="lootbox-aside">
            <div className="lootbox-aside__content">
                {onSale.length > 0
                    ? onSale.map((lootbox: ShopItemInterface) => this.lootboxItem(lootbox))
                    : <SmartLoader />
                }
            </div>
        </AsideItem>;
    }
}
