import React, {useEffect, useState, useRef} from 'react';
import {Articles} from 'site_version/common/aside/Articles';
import Chat from 'app/components/Chat';
import Survey from 'app/components/Survey';
import MainPageDescription from 'app/components/MainPageDescription';
import PromoActions from 'site_version/com/aside/PromoActions';
import LootboxAside from './aside/LootboxAside';
import HiddenTemple from 'site_version/com/aside/HiddenTemple';
import {isDesktop, checkingPages} from 'app/utils';
import SmartStoriesMobile from 'app/components/pages/home_page/SmartStories/SmartStoriesMobile';
import AsideStories from 'site_version/com/AsideStories';
import Giveaway from 'site_version/com/Giveaway';
import CasinoWins from 'site_version/com/CasinoWins';
import SocialsList from 'site_version/common/aside/SocialsList';
import Apps from 'site_version/common/aside/Apps';
import {isCasinoPage} from 'app/utils/IsCasinoPage';
import {ScrollUp} from 'app/components/ScrollUp';
import {EgbButton} from 'app/components/Button';
import {getElement} from 'app/utils/getElement';
import AsidePoker from 'app/components/poker/poker_aside/AsidePoker';
import {userStore} from 'app/store/global';
import banners from './aside/Banners';
import LetsShow from 'app/components/pages/home_page/LetsShow/LetsShow';
import PopularGames from 'app/components/pages/home_page/ViewCasinoGames/PopularGames';
import $ from 'jquery';

type AsideProps = {
    hideChat?: boolean;
};

const Aside: React.FC<AsideProps> = ({hideChat}) => {
    const [showWidget, setShowWidget] = useState(false);

    const [state, setState] = useState({
        heightAsideContent: '0px'
    });

    const reactAsideContentRef = useRef(null);
    let resizeObserver = null;

    const handleScroll = (event: Event): void => {
        const blockWidth = -60;
        const target = event.target as Document;
        const {top} = target.body.getBoundingClientRect();

        if (top === 0) {
            setShowWidget(false);
        }

        if (getElement('.casino-page') === null) {
            return;
        }

        if (top <= blockWidth) {
            setShowWidget(true);
        } else {
            setShowWidget(false);
        }
    };

    const heightHeaderBox = 70;

    const updateAsideContentPosition = () => {
        const heightAsideAdaptive = $('#aside-inner-content').outerHeight() - $(window).height() + heightHeaderBox;

        setState(prevState => ({
            ...prevState,
            heightAsideContent: `-${heightAsideAdaptive}px`
        }));
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        if (isDesktop()) {
            resizeObserver = new ResizeObserver(updateAsideContentPosition);
            resizeObserver.observe(reactAsideContentRef.current, {childList: true, subtree: true});
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
            resizeObserver?.disconnect();
        };
    }, [handleScroll]);

    const topStyleAsideContent = isDesktop() ? {top: state.heightAsideContent} : {};

    return (
        <aside className="aside">
            <div
                ref={reactAsideContentRef}
                id="aside-inner-content"
                className="aside__inner"
                style={topStyleAsideContent}
            >
                {!isDesktop() && <SmartStoriesMobile />}

                {isDesktop() && <AsideStories />}

                <PromoActions />

                {!hideChat && <Chat />}

                {userStore.user.is_admin && <AsidePoker />}

                <SocialsList />

                {isCasinoPage() && <CasinoWins />}

                <Giveaway itemClassName="giveaway" />

                <LootboxAside />

                {isDesktop() && !checkingPages.isHomePage() && <Articles />}

                {!isDesktop() && checkingPages.isHomePage() && <LetsShow />}

                {!isDesktop() && checkingPages.isHomePage() && <PopularGames nameForSliderControls="popular-games-aside"/>}

                <HiddenTemple />

                <Survey />

                {!window.SITE_DATA.MOBILE_WRAPPER && <Apps />}

                {(!checkingPages.isHomePage() && isDesktop() || !isDesktop()) && <MainPageDescription/>}

                {banners()}
            </div>

            <ScrollUp
                show={showWidget}
                behavior={'instant'}
                element={getElement('.casino-page')}
                icon={<EgbButton name={'back_to_top'} />}
            />
        </aside>
    );
};

export default Aside;
