import React, {Component} from 'react';
import SellNowSteamSkin from 'app/components/common/SellNowSteamSkin';
import {hideLastPopup, showPopup} from 'app/utils/modals/ModalsService';
import {scrollToTop} from 'app/utils';
import Prize from './popup/prize';
import I18n from 'app/i18n';
import SendArtifactPopup from './send_artifact_popup';
import {lootboxToChat} from 'app/utils/share_to_chat';
import PrizeBackground from './popup/prize_background';
import BuyButton from 'app/components/shop/lootboxes/buy_button';
import {inject, observer} from 'mobx-react';
import SellArtifactPopup from './sell_artifact_popup';
import ActionButton from './action_button';
import {currencyItem} from 'app/utils/ShopItemPriceFormat';
import {prizeName} from 'app/components/my_profile/artifacts/popup/PrizeName';
import {userStore} from 'app/store/global';
import {openPopupClickable} from 'app/components/my_profile/hero/clickable';
import SmartLoader from 'app/components/reusable/SmartLoader';

const DELAY_OPENING = 500;

@inject('shopLootboxStore', 'artifactStore')
@observer
export default class OpenedLootboxPopupContent extends Component {
    state = {
        currency: '',
        loadingButton: true
    };

    componentDidMount() {
        this.updateCurrencyByLocation();
        this.updateLootbox();
        this.setState({loadingButton: true});

        if (this.isInventoryPage()) {
            this.props.artifactStore.getUserInventory();
        }
    }

    updateLootbox = () => {
        const {shopLootboxStore, commonProperties: {slug}} = this.props;
        const {currency} = this.state;
        shopLootboxStore.updateLootboxBySlug(slug, currency);
    };

    openMore = () => {
        const {shopLootboxStore} = this.props;
        this.updateLootbox();
        shopLootboxStore.updateOpenNowStatus(true);

        if (this.isInventoryPage()) {
            const newArtifact = this.props.artifactStore.inventory_items.find(
                artif => artif.recipe?.id.toString() === this.props.commonProperties.recipe?.id.toString()
            );
            openPopupClickable(newArtifact);
        } else {
            hideLastPopup('inventory-popup');
            scrollToTop();
        }
    };

    buyMore = () => {
        const {shopLootboxStore} = this.props;
        this.updateLootbox();
        shopLootboxStore.updateOpenNowStatus(true);

        if (this.isInventoryPage()) {
            this.props.artifactStore.getUserInventory();
            setTimeout(() => {
                const {artifactStore, commonProperties} = this.props;

                const newArtifact = artifactStore.inventory_items.find(
                    artif => artif.recipe?.id.toString() === commonProperties.recipe?.id.toString()
                );
                openPopupClickable(newArtifact);
            }, DELAY_OPENING);
        } else {
            hideLastPopup('inventory-popup');
            scrollToTop();
        }
    };

    shareToChat = () => {
        const {commonProperties: {id}} = this.props;
        lootboxToChat(id);
        hideLastPopup();
    };

    get prize() {
        const {commonProperties: {extra_data}} = this.props;
        const [prize] = extra_data;
        return prize;
    }

    get artifact() {
        const {commonProperties: {win_artifact}} = this.props;
        return win_artifact;
    }

    send = () => {
        showPopup('inventory-popup', props => <SendArtifactPopup artifact={this.artifact} popupId={props.popupId}/>);
    };

    updateCurrencyByLocation = () => {
        const urlParams = new URLSearchParams(window.location.search);
        this.setState({currency: currencyItem(urlParams.get('currency'))});
    };

    sellArtifact = () => {
        showPopup(
            'sell-inventory',
            props => <SellArtifactPopup artifact={this.artifact} popupId={props.popupId}/>,
            ['inventory-popup']
        );
    };

    get showButtonSell() {
        const {restrict_artifact} = userStore.user;
        return this.artifact &&
            this.artifact.salable &&
            !restrict_artifact;
    }

    renderBuyOrOpenButton = (shopLootboxStore, total_inventory_items, currency) => {
        const userLootboxCount = shopLootboxStore?.lootbox?.user_lootbox_count;
        const totalInventoryItems = total_inventory_items - Number(this.isInventoryPage());
        const lootbox = shopLootboxStore?.lootbox;

        if (this.state.loadingButton) {
            setTimeout(() => this.setState({loadingButton: false}), DELAY_OPENING);
            return <SmartLoader/>;
        }

        if (userLootboxCount > 0 || totalInventoryItems > 0) {
            return <button
                className="button-adaptive button-adaptive_uppercase"
                onClick={this.openMore}
            >
                {I18n.t('open_more')}
            </button>;
        } else if (lootbox && lootbox.id) {
            return <BuyButton
                {...shopLootboxStore.lootbox}
                currency={currency}
                customText={I18n.t('buy_more')}
                show_loading={false}
                afterBuy={this.buyMore}
            />;
        }

        return <SmartLoader/>;
    };

    isInventoryPage = () => window.location.pathname.includes('hero');

    render() {
        const {commonProperties: {steam_skin, steam_trade, total_inventory_items}, shopLootboxStore} = this.props;
        const {currency} = this.state;

        return <div className="prize-won">
            <div className="prize-won-title">
                <h2>{I18n.t('congratulations_you_win')}</h2>
            </div>
            <div className="prize-won-name">
                <span className="prize-won-name__icon">
                    <svg>
                        <use xlinkHref="#svg-gold-cup"/>
                    </svg>
                </span>
                <span className="prize-won-name__name">
                    {prizeName(this.prize)}
                </span>
            </div>
            <div className="prize-won-image-wrapper">
                <div className="prize-won-image">
                    <div className="prize-won-adaptive-image">
                        <Prize loot={this.prize}/>
                    </div>
                    <PrizeBackground {...this.prize}/>
                </div>
            </div>
            <div className="prize-won-buttons">
                <div className="row">
                    { this.renderBuyOrOpenButton(shopLootboxStore, total_inventory_items, currency) }
                </div>
                <div className="row row_group-button">
                    {steam_skin && <SellNowSteamSkin
                        popupId="inventory-popup"
                        steam_skin={steam_skin}
                        steam_trade={steam_trade}
                        btnRemoveClassBtn
                        btnClass="button-adaptive button-adaptive_full-thin"
                    />}
                    {this.showButtonSell && <ActionButton
                        {...this.artifact}
                        onClickHandler={this.sellArtifact}
                        btnText={I18n.t('sell')}
                        btnRemoveClassBtn
                        btnClass="button-adaptive button-adaptive_full-thin"
                    />}
                    <button className="button-adaptive button-adaptive_full-thin-icon" onClick={this.shareToChat}>
                        <span className="button-icon">
                            <svg>
                                <use xlinkHref="#svg-arrow-link"/>
                            </svg>
                        </span>
                        <span className="button-name">{I18n.t('in_chat')}</span>
                    </button>
                </div>
            </div>
        </div>;
    }
}
