/* eslint-disable no-empty-function */
import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import {CasinoGamesStoreInterface, CasinoGamesStoreProps} from 'app/interfaces/stores/CasinoGamesStoreInterfaces';
import CasinoGameItem from './CasinoGameItem';
import EmptyGames from './EmptyGames';
import I18n from 'app/i18n';
import {UserStore} from 'app/store/UserStore';
import SmartLoader from 'app/components/reusable/SmartLoader';
import {showCasinoSignUp} from 'app/utils/IsRegisterUser';
import FirstLevelSort from './FirstLevelSort';
import FilterGroups from './FilterGroups';
import ButtonFavourite from './ButtonFavourite';
import CasinoLootboxes from './CasinoLootboxes';
import {isDesktop, isGuest, userSignedIn, getLinkPath} from 'app/utils';
import DisabledCasino from './DisabledCasino';
import NotFullRegisteredCasino from 'app/components/pages/casino/NotFullRegisteredCasino';
import MainPageFilters from './MainPageFilters';
import ThirdLevelSort from './ThirdLevelSort';
import GroupContainer from './GroupContainer';
import BarLoginMobile from 'app/components/auth/BarLoginMobile';
import {ELEMENT_NOT_FOUND, KEY_ENTER, CASINO_TAB_LOOTBOX} from 'app/utils/Consts';
import {LazyLoading} from 'app/utils/lazyLoading/LazyLoading';
import {ScrollUp} from 'app/components/ScrollUp';
import {EgbButton} from 'app/components/Button';
import IframeView from './iframe-view/IframeView';
import OptionInterface from 'app/interfaces/OptionInterface';
import {size190x190} from 'app/utils/CasinoGameImages';
import {LiveCreatableSelect} from 'app/components/reusable/LiveCreatableSelect';
import {startCasinoGame} from 'app/services/CasinoServices';
import SelectPicker from 'app/components/reusable/SelectPicker';
import {goToRoute} from 'app/utils/link_helper';
import CollectionInterface from 'app/interfaces/CollectionInterface';
import LetsShow from 'app/components/pages/home_page/LetsShow/LetsShow';

const RADIX = 36;
const KEY_LENGTH = 7;
const HEIGHT_FILTER = 300;
const DESKTOP_OFFSET = 70;
const MOBILE_OFFSET = 60;

interface CasinoStateInterface {
    activeClickGame: number
    currency: string
    findValue: string
    gameActive: boolean
    showWidget?: boolean
    key: string
    gameId: string
    isLootboxDetailPage: boolean
}

interface UserStoreProps {
    userStore?: UserStore;
}

@inject('casinoGamesStore', 'userStore')
@observer
export default class Casino extends Component<CasinoGamesStoreProps & UserStoreProps, CasinoStateInterface> {
    constructor(props: CasinoGamesStoreProps) {
        super(props);
        this.state = {
            activeClickGame: null,
            currency: 'usd',
            findValue: '',
            gameActive: false,
            gameId: null,
            isLootboxDetailPage: false,
            key: this.randomKey(),
            showWidget: false
        };
    }

    rootElement: React.RefObject<HTMLDivElement> = React.createRef();

    letsShowRef: React.RefObject<HTMLDivElement> = React.createRef();

    componentDidMount(): void {
        const gameId = this.getGameId();
        const {updateGameId, initialize} = this.props.casinoGamesStore;

        const {pathname, search} = window.location;

        if (pathname.includes('casino') && (pathname.includes('lootboxes') || search.includes('currency'))) {
            window.location.href = getLinkPath('/casino');
        }

        if (isGuest()) {
            if (gameId) {
                updateGameId(gameId);
            }
            initialize();
        } else {
            initialize();
            if (gameId) {
                this.openGame(Number(gameId));
            }
        }
        this.loadGamesIfEmpty();

        window.addEventListener('scroll', this.handleScroll);
    }

    openGame = (gameId: number): void => {
        startCasinoGame(Number(gameId));
        this.scrollToFilter(true);
    };

    getGameId = (): string => {
        const params = new URLSearchParams(window.location.search);
        const gameId = params.get('id');

        if (params && gameId) {
            return gameId;
        }
        return null;
    };

    componentWillUnmount(): void {
        this.props.casinoGamesStore.closeModal();
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event: Event): void => {
        const blockWidth = -400;
        const target = event.target as Document;
        const {top} = target.body.getBoundingClientRect();

        if (top <= blockWidth) {
            this.setState({showWidget: true});
        } else {
            this.setState({showWidget: false});
        }
    };

    loadGamesIfEmpty = (): void => {
        const {
            casinoGames
        } = this.props.casinoGamesStore.data;

        if (casinoGames.length === 0) {
            this.props.casinoGamesStore.loadCasinoGames();
        }
    };

    favoriteFilter = (): void => {
        const {casinoGamesStore} = this.props;
        const {showOnlyFavorite} = casinoGamesStore;

        if (showCasinoSignUp()) {
            return;
        }
        if (!showOnlyFavorite) {
            this.clearSearch();
        }
        casinoGamesStore.clearFind();
        casinoGamesStore.updateShowOnlyFavorite(!showOnlyFavorite);
        casinoGamesStore.updateFilteredParams('favorite', !showOnlyFavorite);
        casinoGamesStore.clearSelectedCategories();
        this.scrollToFilter(true);
    };

    selectedValue = (selected?: OptionInterface | OptionInterface[] | null): string => Array.isArray(selected)
        ? selected.find(Boolean).label
        : selected.label;

    changSearchData = (selected?: OptionInterface | OptionInterface[] | null): void => {
        if (!selected) {
            this.clearSearch(this.updateFilteredParams);
            return;
        }

        const identifier = 'identifier';
        const value = 'value';
        const gameId = selected[identifier] && selected[value] ? selected[value].toString() : null;
        const selectedValue = this.selectedValue(selected);
        const findValue = gameId ? selectedValue.replace(/\s*$/u, '') : selectedValue;
        this.setState({findValue, gameId}, () => {
            this.updateFilteredParams();
        });
    };

    clearSearch = (callback?: () => void): void => {
        if (typeof callback === 'function') {
            this.setState({findValue: '', gameId: null, key: this.randomKey()}, callback);
        } else {
            this.setState({findValue: '', gameId: null, key: this.randomKey()});
        }
        this.scrollToFilter(true);
    };

    handleSearchData = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === KEY_ENTER) {
            this.props.casinoGamesStore.updateShowOnlyFavorite(false);
            this.updateFilteredParams();
        }
    };

    updatedSelect = (): void => {
        this.setState({key: this.randomKey()});
    };

    clearFilters = (): void => {
        this.clearSearch(() => {
            this.props.casinoGamesStore.updateShowOnlyFavorite(false);
            this.updateFilteredParams();
            this.loadGamesIfEmpty();
            this.props.casinoGamesStore.changeFilterGroup(ELEMENT_NOT_FOUND);
        });
    };

    updateFilteredParams = (): void => {
        const {casinoGamesStore} = this.props;

        if (showCasinoSignUp()) {
            return;
        }
        casinoGamesStore.updateFindFilteredParams(this.state.findValue, this.state.gameId);
    };

    setActiveGame = (id: number): void => {
        this.setState({activeClickGame: id});
    };

    deactivateGame = (): void => {
        this.setState({activeClickGame: null});
    };

    loadMoreGames = (): void => {
        this.props.casinoGamesStore.loadCasinoGames();
    };

    showScrollUp = (): React.ReactNode => !isDesktop() && !isGuest() && <ScrollUp
        show={this.state.showWidget}
        behavior={'instant'}
        icon={<EgbButton name={'back_to_top'}/>}
        element={this.rootElement.current}/>;


    loadOptions = (inputValue: string): void | Promise<OptionInterface[]> => this.props.casinoGamesStore.liveSearchApiService(inputValue)
        .then<CasinoGamesStoreInterface>(response => response.json())
        .then(data => data.casinoGames.map(game => ({
            identifier: game.identifier,
            label: `${game.name}  `,
            provider_name: game.provider_name,
            provider_title: game.provider_title,
            value: game.id
        })));

    /* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
    customOption = ({innerProps, data}): ReactNode => <div className="games_filter__menu-item" {...innerProps}>
        <span className="games_filter__menu-item__game-logo">
            {data.provider_name && data.identifier && <img
                alt={data.provider_name}
                src={size190x190(data.provider_name, data.identifier)}/>
            }
        </span>
        <div className="games_filter__menu-item__game">
            <span className="games_filter__menu-item__game-name">
                {data.label}
            </span>
            <span className="games_filter__menu-item__game-provider">
                {data.provider_title || data.provider_name}
            </span>
        </div>
    </div>;

    randomKey = ():string => (Math.random() + 1).toString(RADIX).substring(KEY_LENGTH);

    get newCurrencies() {
        return [{text: 'USD', value: 'usd'}, {text: I18n.t('user_points'), value: 'points'}];
    }

    get currencies() {
        return this.newCurrencies.map((currency): CollectionInterface => ({
            text: currency.text,
            value: currency.value
        }));
    }

    handleChangeCurrency = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        const currency = e.target.value;
        this.setState({currency});
        const {pathname} = window.location;
        goToRoute(`${pathname}?currency=${currency || ''}`);
    };

    setLootboxDetailPage = (value: boolean) => this.setState({isLootboxDetailPage: value});

    setCurrencyUSD = () => this.setState({currency: 'usd'});

    scrollToFilter = (forceScroll = false) => {
        if (this.letsShowRef.current) {
            if (!isDesktop() || window.scrollY < HEIGHT_FILTER || forceScroll === true) {
                const elementRect = this.letsShowRef.current.getBoundingClientRect();
                const offset = isDesktop() ? DESKTOP_OFFSET : MOBILE_OFFSET;
                window.scrollTo({
                    behavior: 'smooth',
                    top: window.scrollY + elementRect.bottom - offset
                });
            }
        }
    };

    render(): React.ReactNode {
        const {casinoGamesStore, userStore} = this.props;

        if (userStore.user.casino_disabled) {
            return <DisabledCasino/>;
        }
        if (userSignedIn() && !userStore.fullRegistered()) {
            return <NotFullRegisteredCasino user={userStore.user}/>;
        }

        const {activeClickGame, findValue, key, isLootboxDetailPage} = this.state;
        const {
            casinoGames
        } = casinoGamesStore.data;
        const {hasMore,
            loadingNewGames,
            showOnlyFavorite,
            sortings,
            sortingValue,
            selectedFilterGroupId,
            filters, favoritesGameCount, totalGamesCount} = casinoGamesStore;
        const lootboxTabActive = selectedFilterGroupId === CASINO_TAB_LOOTBOX;
        return <>
            <div className={`casino-page ${isLootboxDetailPage ? 'casino-lootbox-page' : ''}`} ref={this.rootElement}>
                <div ref={this.letsShowRef}>
                    <LetsShow isCasinoPage/>
                </div>
                <div className="casino-filter">
                    {lootboxTabActive
                        ? !this.state.isLootboxDetailPage && <div className="casino-filter__currency-lootboxes">
                            <div className="select-currency classical-select field__select">
                                <SelectPicker
                                    id="currency_select"
                                    onChange={this.handleChangeCurrency}
                                    value={this.state.currency}
                                    collection={this.currencies}
                                />
                            </div>
                        </div>
                        : <div className="casino-filter__section section-search">
                            <div className="classical-select games-select">
                                <span className="search-container__icon">
                                    <svg className="svg-default">
                                        <use xlinkHref="#svg-search-icon"/>
                                    </svg>
                                </span>
                                <LiveCreatableSelect
                                    keyForSelect={key}
                                    value={{icon: null, label: findValue, value: ELEMENT_NOT_FOUND}}
                                    placeholder={I18n.t('search')}
                                    loadOptions={this.loadOptions}
                                    customOption={this.customOption}
                                    onChange={this.changSearchData}
                                    isClearable
                                    isValue
                                    onClick={this.scrollToFilter}
                                />
                            </div>
                            <ThirdLevelSort clearSearch={this.updatedSelect} scrollToFilter={this.scrollToFilter}/>
                            <button onClick={this.clearFilters}
                                className="button-filters-reset"
                                title={I18n.t('clear')}
                            >
                                <svg className="svg-default">
                                    <use xlinkHref="#svg-clear"/>
                                </svg>
                            </button>
                            <FirstLevelSort
                                onClick={this.clearSearch}
                                sortings={sortings}
                                sortingValue={sortingValue}
                            />
                        </div>}
                    <div className="casino-filter__section section-favourite">
                        {isDesktop() && <div className="casino-filter__row">
                            <ButtonFavourite
                                isActive={showOnlyFavorite}
                                favoritesCount={favoritesGameCount}
                                handleOnClick={this.favoriteFilter}
                            />
                        </div>}
                        <FilterGroups
                            casinoFilters={filters}
                            selectedFilterGroupId={selectedFilterGroupId}
                            onClick={this.clearSearch}
                            showOnlyFavorite={showOnlyFavorite}
                            favoritesCount={favoritesGameCount}
                            handleButtonFavouriteClick={this.favoriteFilter}
                        />
                    </div>
                </div>
                <IframeView activeCasinoGame={casinoGamesStore.activeCasinoGame} scrollToFilter={this.scrollToFilter}/>
                {loadingNewGames
                    ? <SmartLoader loaderClassName={'casino-game-loader'}/>
                    : <div className="casino-game-groups" >
                        {<MainPageFilters
                            activeClickGame={activeClickGame}
                            deactivateGame={this.deactivateGame}
                            setActiveItem={this.setActiveGame}/>}
                        {lootboxTabActive &&
                            <CasinoLootboxes
                                currency={this.state.currency}
                                setCurrencyUSD={this.setCurrencyUSD}
                                isLootboxDetailPage={this.state.isLootboxDetailPage}
                                setLootboxDetailPage={this.setLootboxDetailPage}
                            />}
                        {!lootboxTabActive && casinoGames && (casinoGames?.length > 0
                            ? <GroupContainer
                                countGames={totalGamesCount}
                                show={casinoGamesStore.showFilterGroups()}
                                title={I18n.t('all_games')}
                                opened>
                                <div className="casino-list-games">
                                    {casinoGames && casinoGames.map(casinoGame => <CasinoGameItem
                                        key={`casinoGame-all-${casinoGame.id}`}
                                        setActiveItem={this.setActiveGame}
                                        showMobileButtons={activeClickGame === casinoGame.id}
                                        deactivateGame={this.deactivateGame}
                                        scrollToFilter={this.scrollToFilter}
                                        {...casinoGame}
                                    />)}
                                </div>
                                {hasMore && <div className="casino-games-more"><SmartLoader/></div>}
                            </GroupContainer>
                            : <EmptyGames favourite={favoritesGameCount === 0 && showOnlyFavorite}/>)}
                    </div>}
                {casinoGames && casinoGames?.length > 0 &&
                        <LazyLoading show={hasMore} callback={this.loadMoreGames}/>}
            </div>
            {!isDesktop() && isGuest() && <BarLoginMobile/>}
            {this.showScrollUp()}
        </>;
    }
}
