import React, {Component, MouseEvent, ReactNode} from 'react';
import SmartLabelTooltip from 'app/components/reusable/SmartLabelTooltip';
import Checkbox from 'app/components/auth/Checkbox';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import {ElementWithError} from 'app/components/reusable/ElementWithError';
import {inject, observer} from 'mobx-react';
import I18n from 'app/i18n';
import {MIN_COEF_CHANGING, MAX_COEF_CHANGING, MIN_DELAY_VALUE, MAX_DELAY_VALUE} from 'app/utils/Consts';

const DEFAULT_DELAY_VALUE = 10;
const DEFAULT_COEFFICIENT_VALUE = 1.5;
const SECOND_INDEX_OF_ARRAY = 2;

interface Props {
    controller?: IncubatorCreateLineController
    isEditable: boolean
    updateSettings: boolean
    setUpdateSettings: () => void
}
interface State {
    isOpenModal: boolean
    old_coef_changing: number,
    old_delay_for_bets_time: number,
    hideNickname: boolean,
    considerPersonalLimits: boolean,
    toSave: boolean
}

@inject('controller')
@observer
export default class IncubatorCreateSettings extends Component<Props, State> {
    state = {
        considerPersonalLimits: true,
        hideNickname: true,
        isOpenModal: false,
        old_coef_changing: -1,
        old_delay_for_bets_time: -1,
        toSave: false
    };

    get controller(): IncubatorCreateLineController {
        return this.props.controller;
    }

    componentDidMount(): void {
        this.checkDelayValueNull();
        this.syncInputsWithController();
        window.addEventListener('beforeunload', this.saveSettingsBeforeUnload);
    }

    componentDidUpdate(): void {
        this.checkDelayValueNull();
        if (this.props.isEditable) {
            const {coef_changing, delay_for_bets_time} = this.props.controller.incubatorGame.attributes;
            const {old_coef_changing, old_delay_for_bets_time} = this.state;

            if (old_coef_changing !== coef_changing || old_delay_for_bets_time !== delay_for_bets_time || this.props.updateSettings) {
                this.syncInputsWithController();
                this.props.setUpdateSettings();
            }
        }
    }

    componentWillUnmount(): void {
        this.saveSettingsBeforeUnload();
        window.removeEventListener('beforeunload', this.saveSettingsBeforeUnload);
    }

    syncInputsWithController(): void {
        const {coef_changing, delay_for_bets_time, hide_creator, consider_personal_limits} = this.props.controller.incubatorGame.attributes;
        this.delayInputRef.current.value = `${delay_for_bets_time}`;
        this.coefficientInputRef.current.value = `${coef_changing}`;
        this.setState({
            considerPersonalLimits: consider_personal_limits,
            hideNickname: hide_creator,
            old_coef_changing: coef_changing,
            old_delay_for_bets_time: delay_for_bets_time
        });
    }

    saveSettingsBeforeUnload = (): void => {
        if (this.state.toSave) {
            this.controller.incubatorGame.save(true);
        }
    };

    delayInputRef: React.RefObject<HTMLInputElement> = React.createRef();

    coefficientInputRef: React.RefObject<HTMLInputElement> = React.createRef();

    setOptionsByDefault = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        const {controller} = this.props;
        this.delayInputRef.current.value = `${DEFAULT_DELAY_VALUE}`;
        this.coefficientInputRef.current.value = `${DEFAULT_COEFFICIENT_VALUE}`;
        this.setState({
            considerPersonalLimits: true,
            hideNickname: true
        });
        controller.incubatorGame.clearError('coef_changing');
        controller.incubatorGame.clearError('delay_for_bets_time');
    };

    validationSettings = (): void => {
        const {incubatorGame} = this.controller;
        const delay_for_bets_time = Number(this.delayInputRef.current.value);
        const coef_changing = this.coefficientInputRef.current.value;

        if (delay_for_bets_time < MIN_DELAY_VALUE || delay_for_bets_time > MAX_DELAY_VALUE) {
            incubatorGame.setErrors({...incubatorGame.errors,
                delay_for_bets_time: [I18n.t('amount_range_error', {max_amount: MAX_DELAY_VALUE, min_amount: MIN_DELAY_VALUE})]});
        }
        if (Number(coef_changing) < MIN_COEF_CHANGING || Number(coef_changing) > MAX_COEF_CHANGING || coef_changing === '') {
            incubatorGame.setErrors({...incubatorGame.errors,
                coef_changing: [I18n.t('amount_range_error', {max_amount: MAX_COEF_CHANGING, min_amount: MIN_COEF_CHANGING})]});
        }
    };

    saveOptions = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        this.validationSettings();
        const {incubatorGame} = this.controller;

        if (!incubatorGame.getError('delay_for_bets_time') && !incubatorGame.getError('coef_changing')) {
            this.setState({isOpenModal: false});
            this.controller.onChangeDelayBet(Number(this.delayInputRef.current.value));
            this.controller.onChangeCoefChanging(Number(this.coefficientInputRef.current.value));
            this.controller.onChangeHideNickname(this.state.hideNickname);
            this.controller.onChangeConsiderPersonalLimits(this.state.considerPersonalLimits);

            if (this.props.isEditable) {
                this.setState({toSave: true});
            }
        }
    };

    checkDelayValueNull = (): void => {
        if (this.controller.incubatorGame.attributes.delay_for_bets_time === null) {
            this.controller.onChangeDelayBet(DEFAULT_DELAY_VALUE);
        }
    };

    handleDelayInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.target.value = e.target.value.replace(/[^0-9]/gu, '');
        this.controller.incubatorGame.clearError('delay_for_bets_time');
    };

    handleCoefficientInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let inputValue = e.target.value.replace(/[^0-9.]/gu, '').replace(/(?<first>\..*?)\..*/gu, '$<first>');

        if (inputValue.length === 1 && inputValue.substring(0, 1) === '.') {
            inputValue = '';
        }

        if (inputValue.length > 1 && inputValue.substring(1, SECOND_INDEX_OF_ARRAY) !== '.') {
            inputValue = inputValue[0].concat('.', inputValue[1]);
        }

        if (Number(inputValue) >= MIN_COEF_CHANGING && Number(inputValue) <= MAX_COEF_CHANGING) {
            this.controller.incubatorGame.clearError('coef_changing');
        }
        e.target.value = inputValue;
    };

    handleHideNickname = (hideNickname: boolean): void => {
        this.setState({hideNickname});
    };

    handleConsiderPersonalLimits = (considerPersonalLimits: boolean): void => {
        this.setState({considerPersonalLimits});
    };

    onClick = (e: MouseEvent<HTMLButtonElement>):void => {
        e.preventDefault();
        this.setState({isOpenModal: !this.state.isOpenModal});
    };

    errorHint = (field: string): ReactNode => this.error(field);

    error = (field: string): string => this.controller.incubatorGame.getError(field);


    render(): ReactNode {
        const {coef_changing, delay_for_bets_time} = this.controller.incubatorGame.attributes;

        return <div className={`incubator-create-settings ${this.state.isOpenModal ? 'active' : '' }`}>
            <button
                className={`incubator-create-settings__button
                    ${this.error('coef_changing') || this.error('delay_for_bets_time') ? 'error' : ''}`}
                onClick={this.onClick}
                type="button"
            >
                <svg className="svg-default">
                    <use xlinkHref="#svg-settings"/>
                </svg>
            </button>
            <div className="incubator-create-settings__modal">
                <form className="incubator-create-settings__form">
                    <div className="incubator-create-settings__form-row">
                        <SmartLabelTooltip
                            forLabelTooltip="acceptanceDelay"
                            labelTooltip="title_incubator_acceptance_delay"
                            descriptionTooltip="tooltip_incubator_acceptance_delay_1"
                            additionalDescriptionTooltip="tooltip_incubator_acceptance_delay_2"
                        />
                        <ElementWithError
                            errorHint={this.errorHint('delay_for_bets_time')}
                            className={this.error('delay_for_bets_time') ? 'field-error' : ''}
                        >
                            <input
                                id="acceptanceDelay"
                                className="field__input field-number__input"
                                placeholder={`${delay_for_bets_time}`}
                                maxLength={2}
                                name="acceptanceDelay"
                                onChange={this.handleDelayInput}
                                ref={this.delayInputRef}
                            />
                        </ElementWithError>
                    </div>
                    <div className="incubator-create-settings__form-row">
                        <SmartLabelTooltip
                            forLabelTooltip="changeCoefficientIncubator"
                            labelTooltip="title_incubator_change_coefficient"
                            descriptionTooltip="tooltip_incubator_change_coefficient_1"
                            additionalDescriptionTooltip="tooltip_incubator_change_coefficient_2"
                        />
                        <ElementWithError
                            errorHint={this.errorHint('coef_changing')}
                            className={this.error('coef_changing') ? 'field-error' : ''}
                        >
                            <input
                                id="changeCoefficientIncubator"
                                className="field__input field-number__input"
                                placeholder={`${coef_changing}`}
                                maxLength={4}
                                name="changeCoefficientIncubator"
                                onChange={this.handleCoefficientInput}
                                ref={this.coefficientInputRef}
                            />
                        </ElementWithError>
                    </div>
                    <div className="incubator-create-settings__form-row--without-label">
                        <Checkbox
                            label={I18n.t('tooltip_incubator_consider_personal_limits')}
                            onChange={this.handleConsiderPersonalLimits}
                            value={this.state.considerPersonalLimits}
                        />
                        <SmartLabelTooltip
                            labelTooltip="tooltip_incubator_hint_consider_personal_limits"
                            descriptionTooltip="tooltip_incubator_hint_consider_personal_limits"
                        />
                    </div>
                    <Checkbox
                        label={I18n.t('tooltip_incubator_hide_nickname')}
                        onChange={this.handleHideNickname}
                        value={this.state.hideNickname}
                    />
                    <button
                        className="incubator-create-settings__form-btn button-adaptive button-adaptive_full"
                        onClick={this.setOptionsByDefault}
                    >{I18n.t('by_default')}</button>
                    <button
                        className="incubator-create-settings__form-btn incubator-create-settings__form-btn--save button-adaptive button-adaptive_full"
                        onClick={this.saveOptions}
                    >{I18n.t('apply')}</button>
                </form>
            </div>
        </div>;
    }
}
