import React, {PureComponent, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {i18n_t} from 'app/i18n';
import {HeaderMenu} from 'site_version/com/HeaderMenu';
import {getLinkPath, isGuest, scrollToTop} from 'app/utils';
import {PageContent} from 'app/components/page_content';
import FooterPartnerList from 'site_version/common/FooterPartnerList';

const LICENSE_IMAGE_URL = 'https://seal.cgcb.info/8b089e55-4795-425a-6bf6-fdb75eba6900';
// eslint-disable-next-line max-len
const LICENSE_CLICK_URL = 'https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbXB3ZUhScU5TOWpiRWxWVkZSQk1uUnJhM2hoWVdjOVBTSXNJblpoYkhWbElqb2ljVUpxVWxsVVJFeElXVGxVT0VkemJVMVRlVk5sUVQwOUlpd2liV0ZqSWpvaU9URm1aVE0wWXpZMlptTmlPVFZoWlRCak5qazFZakEzTm1abFpURmtZV1JoWmprMVpUZzVZamsyTm1RNU5UZGpZakZqWW1OaE5tVmlPVEU0WVRjd01TSXNJblJoWnlJNklpSjk=';
// eslint-disable-next-line max-len
const LICENSE_CLICK_URL_TO_MIRROR = 'https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJakUwZHpsaE5HUkNWaXRrYVhrM1IwWlhkSEJtVmtFOVBTSXNJblpoYkhWbElqb2laVXRSYTA5MGNucFhOaXRwUlZoNGFrOVBjR2xEUVQwOUlpd2liV0ZqSWpvaU5ERmxaalF5WVdVNFl6STRZVGxtTURFMk1UYzFNbUV6WW1Rek5XUXpOamM1TmpkbFpUbG1aRGszTkRrek5XRTVNelJtTmpjeE9EWTBaRE15T0RFMFppSXNJblJoWnlJNklpSjk=';
const MIRROR_DOMAIN_FOR_LICENSE = 'egabe.net';

export default class Footer extends PureComponent {
    componentDidMount = (): void => {
        if (window.SHOW_LICENSE) {
            this.createScriptToElement('second-license',
                'https://a3ccd1f4-e8b2-4443-8523-479da29966ed.seals-emr.certria.com/emr-seal.js');
        }
    };

    createScriptToElement = (elementId: string, src: string): void => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        document.getElementById(elementId).appendChild(script);
    };

    render(): ReactNode {
        const click_url = window.location.hostname === MIRROR_DOMAIN_FOR_LICENSE ? LICENSE_CLICK_URL_TO_MIRROR : LICENSE_CLICK_URL;

        return <div id="footer" className="footer">
            <div className="footer__inner">
                <div className="footer__nav">
                    <nav className="footer-nav">
                        <Link
                            to={getLinkPath('/esports')}
                            className="footer-nav__link"
                            onClick={scrollToTop}
                        >
                            {i18n_t('menu_links.esport')}
                        </Link>

                        {HeaderMenu.map(item => <a
                            key={item.section}
                            className="footer-nav__link"
                            href={getLinkPath(item.path)}
                        >
                            {i18n_t(`menu_links.${item.section}`)}
                        </a>)}
                        <Link
                            className="footer-nav__link"
                            to={getLinkPath('/about')}
                            onClick={scrollToTop}
                        >
                            {i18n_t('menu_links.about')}
                        </Link>
                        <Link
                            className="footer-nav__link"
                            to={getLinkPath('/contacts')}
                            onClick={scrollToTop}
                        >
                            {i18n_t('menu_links.contacts')}
                        </Link>
                        <Link
                            className="footer-nav__link"
                            to={getLinkPath('/sitemap.html')}
                            onClick={scrollToTop}
                        >
                            {i18n_t('menu_links.sitemap')}
                        </Link>
                        <Link
                            className="footer-nav__link"
                            to={getLinkPath('/affiliates')}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {i18n_t('menu_links.affiliate')}
                        </Link>
                        <Link
                            to={getLinkPath('/careers')}
                            className="footer-nav__link"
                            onClick={scrollToTop}
                        >
                            {i18n_t('menu_links.careers')}
                        </Link>
                    </nav>
                </div>
                <div className="footer__text footer__text__top">
                    <div className="footer__plain-text">
                        <PageContent contentUrl="/" contentKey="footer_content"/>
                    </div>
                </div>
                <div className="partner-list-license">
                    <FooterPartnerList/>
                    <div className="footer__share-license">
                        <div className="footer__share-license">
                            <div className="license-list">
                                {window.SHOW_LICENSE && <>
                                    <div id="license">
                                        <div id="apg-seal-container" className="apg-license">
                                            <div>
                                                <a target="_blank" rel="noreferrer" href={click_url}>
                                                    <img style={{maxHeight: '100%', maxWidth: '100%'}} src={LICENSE_IMAGE_URL}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="second-license">
                                        <div
                                            id="emr-a3ccd1f4-e8b2-4443-8523-479da29966ed"
                                            data-emr-seal-id="a3ccd1f4-e8b2-4443-8523-479da29966ed"
                                            data-emr-image-size="27"
                                            data-emr-image-type="basic-light-large"
                                            className="em-license"
                                        />
                                    </div>
                                </>}
                                <svg height="27px" width="27px"><use xlinkHref={'#svg-eighteenplus'}/></svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`footer__text footer__text__bottom${isGuest() ? ' padding_bottom' : ''}`}>
                    <div className="footer__plain-text">
                        <PageContent contentUrl="/" contentKey="footer_content_license"/>
                    </div>
                </div>
            </div>
        </div>;
    }
}
