import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import I18n from 'app/i18n';
import CountDownPromo from './count_down_promo';
import {fixed2, getLinkPath, userSignedIn} from 'app/utils';
import TimeToStartTimer from 'app/utils/TimeToStartTimer';
import {BEER_IMAGE} from 'app/consts';
import AsideItem from 'site_version/com/AsideItem';
import nextPrizeProgress from './modules/next_prize_progress';
import FAQButton from './modules/FAQButton';
import ClickableTooltip from 'app/components/reusable/ClickableTooltip';
import {promoActionStore} from 'app/store/global';
import {Provider} from 'mobx-react';
import {goToMyHero} from 'app/utils/app_links';
import {getPromoUrl} from 'app/utils/getPromoUrl';
import CheersButton from './CheersButton';

export default class PromoActionBeer extends CountDownPromo {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            time_to_end: props.time_to_end,
            time_to_start: props.time_to_start
        };
    }

    static imagePropName = 'PROMO_ACTION_BEER';

    UNSAFE_componentWillMount() {
        this.isMountedComponent = true;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(nextProps, nextState) {
        super.componentDidUpdate(nextProps, nextState);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.isMountedComponent = false;
    }

    promoActionTitle = () => <div className="beer-aside__title aside-item-heading__title">
        <div className="beer-aside__title-content">
            <h3>
                {I18n.t('st_patrick_header')}
            </h3>
            <FAQButton FAQPath="/faq/stpatrick"/>
        </div>
        {userSignedIn() && this.isStarted() && <div className="beer-aside__together">
            <span>
                {fixed2(this.nextPrizeProgress())}%
            </span>
            <span>
                x{this.props.count}
            </span>
        </div>}
    </div>;

    getTimeContent = (timeText, time) => <>
        <span>{I18n.t(timeText)}:</span>
        <TimeToStartTimer time_to_start={time} countdown/>
    </>;

    promoActionTimeBlock = () => {
        let timeContent = null;
        if (!this.isStarted()) {
            timeContent = this.getTimeContent('start_in', this.state.time_to_start);
        } else if (userSignedIn() && this.isEndingPromo()) {
            timeContent = this.getTimeContent('end_of_the_promotion', this.state.time_to_end);
        } else {
            return null;
        }
        return <div className="beer-aside__time">
            <div className="beer-aside__time-content">
                {timeContent}
            </div>
        </div>;
    };

    footer = () => <>
        {this.promoActionTimeBlock()}
    </>;

    scatterText = () => I18n.t('promo_action_beer.scatter.promo_text');

    promoActionTimeBeforeStart = () => !this.isStarted() && <>
        <div className="beer-aside__time-content">
            <span className="beer-aside__begin">Начало через:</span>
            <TimeToStartTimer {...this.state} smartTimer/>
        </div>
    </>;

    promoActionPrizeProgressAndBalance = () => userSignedIn() && this.isStarted() && <div className="beer-aside__together">
        <span>
            {fixed2(this.nextPrizeProgress())}%
        </span>
        <span>
            x{this.props.count}
        </span>
    </div>;

    promoActionHeadingCheers = () => userSignedIn() && this.isStarted() && this.props.count !== 0 && <div className="beer-aside__footer">
        <CheersButton
            {...this.state}
            imagePropName={this.imagePropName}
            isStarted={this.isStarted}
        />
    </div>;

    get imagePropName() {
        return this.constructor.imagePropName;
    }

    beerInnerComponent = () => <div className="beer-glass__inner">{this.beerGlassParts()}</div>;

    beerGlassParts = () => <Fragment>
        <div className="beer-glass__background">
            <img src={BEER_IMAGE[this.imagePropName].BACKGROUND} alt="Beer background"/>
        </div>
        <div className="beer-glass__vessel">
            <img src={BEER_IMAGE[this.imagePropName].VESSEL} alt="Beer vessel"/>
        </div>
        <div style={{bottom: `${this.nextPrizeProgress()}%`}} className="beer-glass__liquid">
            <img src={BEER_IMAGE[this.imagePropName].LIQUID} alt="Beer liquid"/>
        </div>
    </Fragment>;

    nextPrizeProgress = () => nextPrizeProgress({
        ...this.props,
        isStarted: this.isStarted
    });

    static get promoUrl() {
        const {promoAction} = promoActionStore;

        return `${getPromoUrl()}/${promoAction.url}`;
    }

    render() {
        const ConvertButton = promoActionStore.convertButton;

        return !this.isEndedPromo() && <AsideItem
            header={this.promoActionTitle()}
            footer={this.footer}
            itemClassName={'beer-aside'}>
            <div className={`beer-aside-content ${this.isStarted() ? 'beer-aside-content_left' : ''}`}>
                <div className="beer-aside__beer-glass">
                    <div className="beer-glass">
                        <Link to={getLinkPath(this.constructor.promoUrl)}>
                            {this.beerInnerComponent()}
                        </Link>
                    </div>
                </div>
                {this.isStarted() && <div className="beer-aside__beer-scatter">
                    {userSignedIn() && <div className="promo-aside__scatter">
                        <svg height="16px" width="16px">
                            <use xlinkHref="#svg-scatter"/>
                        </svg>
                        <Provider promoActionStore={promoActionStore}>
                            <ConvertButton onClickHandler={goToMyHero} classes={['promo-aside__scatter-btn']}/>
                        </Provider>
                        <ClickableTooltip text={this.scatterText()}/>
                    </div>
                    }
                    {this.props.count !== 0 && <CheersButton
                        {...this.state}
                        imagePropName={this.imagePropName}
                        isStarted={this.isStarted}
                    />}
                </div>}

            </div>
        </AsideItem>;
    }
}
