import React from 'react';
import PromoActionBeer from './beer';
import I18n from 'app/i18n';
import FAQButton from './modules/FAQButton';
import {fixed2, userSignedIn} from 'app/utils';



export default class PromoActionOktoberfest extends PromoActionBeer {
    promoActionTitle = () => <div className="beer-aside__title aside-item-heading__title">
        <div className="beer-aside__title-content">
            <h3>
                {I18n.t('oktoberfest_header')}
            </h3>
            <FAQButton FAQPath="/faq/therese_von_bayern"/>
        </div>
        {userSignedIn() && this.isStarted() && <div className="beer-aside__together">
            <span>
                {fixed2(this.nextPrizeProgress())}%
            </span>
            <span>
             x{this.props.count}
            </span>
        </div>}
    </div>;

    get imagePropName() {
        return 'PROMO_ACTION_OKTOBERFEST';
    }

    beerInnerComponent = () => <div className="beer-glass__inner beer-glass__inner__oktoberfest">
        {this.beerGlassParts()}
    </div>;
}

