import React, {PureComponent} from 'react';
import {CasinoFilterInterface} from 'app/interfaces/stores/CasinoGamesStoreInterfaces';
import I18n from 'app/i18n';
import {ELEMENT_NOT_FOUND, CASINO_TAB_LOOTBOX} from 'app/utils/Consts';
import {SwiperSlide} from 'swiper/swiper-react';
import FilterGroup from 'app/components/pages/casino/FilterGroup';
import ParentsFilter from 'app/components/pages/casino/ParentsFilter';
import {isDesktop} from 'app/utils';
import ButtonFavourite from './ButtonFavourite';
import SwiperCore from 'swiper';
import {getWidth} from 'app/utils/getWidth';

interface FilterGroupsProps {
    casinoFilters: CasinoFilterInterface[]
    selectedFilterGroupId: number
    onClick?: () => void
    showOnlyFavorite: boolean
    favoritesCount: number
    handleButtonFavouriteClick: () => void
}

const CLASS_END = 'reached-end';
const CLASS_BEGIN = 'reached-begin';
const CLASS_MIDDLE = 'reached-middle';

export default class FilterGroups extends PureComponent<FilterGroupsProps> {
    state = {renderSlider: false};

    filtersGroupElement: React.RefObject<HTMLDivElement> = React.createRef();

    rootElement: React.RefObject<HTMLDivElement> = React.createRef();

    onCheckReached = (swiper: SwiperCore): void => {
        const el = this.rootElement.current;

        if (!el) {
            return;
        }

        if (swiper.isEnd) {
            el.classList.add(CLASS_END);
            el.classList.remove(CLASS_BEGIN, CLASS_MIDDLE);
        } else if (swiper.isBeginning) {
            el.classList.add(CLASS_BEGIN);
            el.classList.remove(CLASS_END, CLASS_MIDDLE);
        } else {
            el.classList.remove(CLASS_BEGIN, CLASS_END);
            el.classList.add(CLASS_MIDDLE);
        }
    };

    componentDidMount(): void {
        this.setRenderSwiper();
        window.addEventListener('resize', this.setRenderSwiper);
    }

    componentDidUpdate(): void {
        if (!this.state.renderSlider) {
            this.setRenderSwiper();
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.setRenderSwiper);
    }

    setRenderSwiper = (): void => {
        const filterGroups = this.filtersGroupElement.current?.clientWidth;
        const filterList = getWidth('.buttons-filter-without-swiper') || getWidth('.swiper-wrapper');
        this.setState({renderSlider: filterGroups < filterList});
    };

    render(): React.ReactNode {
        const {casinoFilters, selectedFilterGroupId, showOnlyFavorite, favoritesCount,
            handleButtonFavouriteClick} = this.props;
        const {renderSlider} = this.state;
        return <div className="casino-filter__row row-buttons" ref={this.filtersGroupElement}>
            <div className={`${renderSlider ? 'buttons-casino-filter-slide' : ''}`} >
                <div className={`buttons-filter-group ${renderSlider ? '' : 'without-swiper'}`} ref={this.rootElement}>
                    <ParentsFilter isSwiper={renderSlider} onCheckReached={this.onCheckReached}>
                        {!isDesktop() && <SwiperSlide>
                            <ButtonFavourite
                                isActive={showOnlyFavorite}
                                favoritesCount={favoritesCount}
                                handleOnClick={handleButtonFavouriteClick}
                            />
                        </SwiperSlide>}
                        <SwiperSlide>
                            <FilterGroup
                                className={`button-group-item${selectedFilterGroupId === ELEMENT_NOT_FOUND ? ' selected' : ''}`}
                                id={ELEMENT_NOT_FOUND}
                                onClick={this.props.onClick}
                                name={I18n.t('all_games')}
                            />
                        </SwiperSlide>

                        {casinoFilters && casinoFilters.map(filter => <SwiperSlide key={filter.id}>
                            <FilterGroup
                                className={`button-group-item${filter.id === selectedFilterGroupId ? ' selected' : ''}`}
                                id={filter.id}
                                langs={filter.langs}
                                onClick={this.props.onClick}
                                name={filter.name}
                            />
                        </SwiperSlide>)}
                        <SwiperSlide>
                            <FilterGroup
                                className={`button-group-item ${selectedFilterGroupId === CASINO_TAB_LOOTBOX ? ' selected' : ''}`}
                                id={CASINO_TAB_LOOTBOX}
                                onClick={this.props.onClick}
                                name={I18n.t('lootboxes')}
                            />
                        </SwiperSlide>
                    </ParentsFilter>
                </div>
            </div>
        </div>;
    }
}
