import {action, makeObservable, observable, runInAction} from 'mobx';
import Get from 'app/utils/Get';
import Delete from 'app/utils/Delete';
import BonusInterface from 'app/interfaces/BonusInterface';
import BonusDataInterface from 'app/interfaces/BonusDataInterface';
import {UserStore} from 'app/store/UserStore';
import Post from 'app/utils/Post';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import I18n from 'app/i18n';
import Websocket from 'modules/Websocket';
import FreespinInterface from 'app/interfaces/Freespinsinterface';
import NotificationStore from 'app/store/notifications';
import PopupFreespins from 'app/components/my_profile/freespins/PopupFreespins';
import {showPopup} from 'app/utils/modals/ModalsService';
import React from 'react';

const ELEMENT_NOT_FOUND = -1;

export interface UpdateBonusesType {
    bonuses?: BonusInterface[],
    bonuses_data?: BonusDataInterface,
    bonusesLoading?: boolean
}

export class UserBonusesStore {
    @observable bonuses: BonusInterface[] = [];

    @observable bonuses_data: BonusDataInterface = null;

    @observable bonusesLoading = false;

    @observable bonusDataLoading = false;

    @observable free_spins: FreespinInterface[] = [];

    userStore: UserStore;

    subscriptions = [];

    constructor() {
        makeObservable(this);
    }

    @action
    setUserStore = (store: UserStore): void => {
        this.userStore = store;
    };

    @action
    setFreeSpins = (data: FreespinInterface[]): void => {
        this.free_spins = data;
    };

    @action
    cancelFreeSpin(id: string): void {
        new Delete({url: `/casino/free_spins/${id}`})
            .execute()
            .then(response => response.json())
            .then(response => {
                if (response.status === 'ok') {
                    runInAction(() => {
                        this.removeFreeSpinAfterCancel(id);
                    });
                    toastSuccess(I18n.t('casino_free_spins.canceled_success'));
                } else {
                    toastError(response.message || I18n.t('casino_free_spins.canceled_error'));
                }
            })
            .catch(error => {
                console.error('Error canceling free spin:', error);
                toastError(I18n.t('casino_free_spins.canceled_error'));
            });
    }

    @action
    removeFreeSpinAfterCancel(id: string): void {
        const spinIndex = this.free_spins.findIndex(spin => spin.id === id);

        if (spinIndex !== ELEMENT_NOT_FOUND) {
            this.free_spins.splice(spinIndex, 1);
        }
    }

    @action
    updateUserBonusData({
        bonuses, bonuses_data
    }: UpdateBonusesType): void {
        this.bonuses = bonuses || [];
        this.bonuses_data = bonuses_data;
    }

    @action
    updateBonusesLoading(loading = false): void {
        this.bonusesLoading = loading;
    }

    @action
    updateBonusDataLoading(loading = false): void {
        this.bonusDataLoading = loading;
    }

    @action
    getUserBonusesData(loading = true): void {
        this.updateBonusDataLoading(loading);

        new Get({url: '/my_profile/bonuses_data'})
            .execute()
            .then(data => data.json())
            .then(data => {
                this.updateBonusDataLoading();
                this.updateStore({...data});
                this.userStore.updateBonuses(data.bonuses);
            });
    }

    @action
    updateStore({bonuses, bonuses_data}:
            { bonuses?: BonusInterface[], bonuses_data?: BonusDataInterface, loading: boolean}): void {
        if (bonuses) {
            this.bonuses = bonuses;
        }
        if (bonuses_data) {
            this.bonuses_data = bonuses_data;
        }
    }

    @action
    removeBonusAfterCancel(id: number): void {
        const objWithIdIndex = this.bonuses.findIndex(obj => obj.id === id);
        const notFoundIndex = -1;
        const recordFound = objWithIdIndex > notFoundIndex;

        if (recordFound) {
            this.bonuses.splice(objWithIdIndex, 1);
        }
    }

    cancelBonus = (id: number): void => {
        new Post({
            params: {bonus_id: id},
            url: '/user/cancel_bonus'
        }).execute()
            .then((response: Response) => response.json())
            .then(response => {
                if (response.success) {
                    toastSuccess(I18n.t('cancelled_bonus_message', {id}));
                    this.removeBonusAfterCancel(id);
                } else {
                    toastError(response.message);
                }
            });
    };

    listen(): void {
        this.getUserBonusesData(false);
    }

    @action
    free_spin_notice(payload: FreespinInterface): void {
        const existingSpinIndex = this.free_spins.findIndex(spin => spin.id === payload.id);

        if (existingSpinIndex === ELEMENT_NOT_FOUND) {
            this.free_spins.push(payload);
        }

        if (window.location.pathname.includes('/casino')) {
            showPopup('popup-freespins', () => <PopupFreespins popupId={'popup-freespins'} free_spin_data={payload} />);
        } else {
            NotificationStore.add({
                force: true,
                key: `free_spin_notification-${payload.id}`,
                payload,
                timeout: false,
                type: 'free_spin_notification'
            });
        }
    }

    unsubscribe(userId: number): void {
        let subscription = this.subscriptions[`${userId}`];
        if (subscription) {
            Websocket.unsubscribe(subscription);
            subscription = null;
        }
    }

    freeSpinsCount = (): number => this.free_spins.length;
}
