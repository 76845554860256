import React, {Component} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import Crafting from './artifacts/crafting';
import {goToRoute, userSignedIn} from 'app/utils';
import InventorySlot from './hero/inventory_slot';
import Equipped from './hero/equipped';
import DescriptionGroup from './hero/description_group';
import Effects from './hero/Effects';
import SteamTradeSlot, {openSteamTradePopup} from './hero/SteamTradeSlot';
import {openPopupClickable} from './hero/clickable';
import withRouter from 'app/utils/navigate/customWithRouter';
import {ArtifactStore} from 'app/store/ArtifactStore';
import {SteamTradeStore} from 'app/store/SteamTradeStore';
import SmartLoader from 'app/components/reusable/SmartLoader';

interface HeroInterface {
    artifactStore?: ArtifactStore
    steamTradeStore?: SteamTradeStore
    params?: {
        recipeId?: string
        steamTradeId?: string
    }
    userName?: string
    tradeEnabled?: boolean
    readOnly?: boolean
}

class Hero extends Component<HeroInterface> {
    state = {
        loading: false
    };

    componentDidMount() {
        this.openSteamTrade();
        if (this.props.params && this.props.params.recipeId) {
            const artifact = this.props.artifactStore.inventory.find(
                artif => artif.recipe.id.toString() === this.props.params.recipeId
            );

            if (artifact) {
                // The timer was used because ReactDOM.render() was asynchronous in app/popups.jsx:93
                setTimeout(() => openPopupClickable(artifact), 0);
            }
        }
        if (!this.props.userName) {
            this.props.artifactStore.setLoading(true);
            this.props.artifactStore.getUserInventory();
        }
    }

    openSteamTrade() {
        if (this.props.params && this.props.params.steamTradeId) {
            const steamTrade = this.props.steamTradeStore.steamTradesOnApproval
                .find(trade => trade.id === Number(this.props.params.steamTradeId));

            if (steamTrade) {
                setTimeout(() => openSteamTradePopup(steamTrade), 0);
            }
        }
    }

    openTradePage = () => {
        goToRoute(`/profile/${this.props.userName}/trade`);
    };

    render() {
        const store = this.props.artifactStore;
        const {steamTrades} = this.props.steamTradeStore;
        const tradeStore = this.props.steamTradeStore;
        const tradeEnabled = userSignedIn() && this.props.tradeEnabled;
        const multi = store.multiEquipment;

        return store.loading
            ? <SmartLoader/>
            : <div className="hero profile-tab">
                <div className="hero__inventory--wrap">
                    <div className="hero__inventory--column hero__inventory--column-descr">
                        <DescriptionGroup artifact={store.helmet} type="helmet" multi={multi}/>
                        <DescriptionGroup artifact={store.armor} type="armor" multi={multi}/>
                        <DescriptionGroup artifact={store.sword} type="sword" multi={multi}/>
                    </div>
                    <div className="hero__inventory--column">
                        <div className={`hero__warrior${multi ? ' honeycomb' : ''}`}>
                            <div className="hero__warrior--siluet"/>
                            <Equipped artifact={store.helmet} type="helmet" multi={multi}/>
                            <Equipped artifact={store.armor} type="armor" multi={multi}/>
                            <Equipped artifact={store.sword} type="sword" multi={multi}/>
                            <Equipped artifact={store.shield} type="shield" multi={multi}/>
                            <Equipped artifact={store.cloak} type="cloak" multi={multi}/>
                            <Equipped artifact={store.boots} type="boots" multi={multi}/>
                        </div>
                    </div>
                    <div className="hero__inventory--column hero__inventory--column-descr">
                        <DescriptionGroup artifact={store.shield} type="shield" multi={multi}/>
                        <DescriptionGroup artifact={store.cloak} type="cloak" multi={multi}/>
                        <DescriptionGroup artifact={store.boots} type="boots" multi={multi}/>
                    </div>
                </div>
                <Effects
                    title={I18n.t('artifacts')}
                    effects={store.artifactEffects}
                />
                <Effects
                    title={I18n.t('scrolls')}
                    effects={store.scrollEffects}
                />
                <div className="hero__dark-bg hero__inventory-board">
                    <div className="hero__slots">
                        <div className="hero__slots-content">
                            <div className="hero__slots-title">{I18n.t('inventory')}</div>
                            <div className="hero__slot-wrap">
                                {store && store.inventory.map(a => <InventorySlot artifact={a} key={a?.id}/>)}
                                {steamTrades && steamTrades.map(a => <SteamTradeSlot
                                    steamTrade={a}
                                    key={`steam-trade-${a?.id}`}
                                />)}
                                {store.emptySlots.map((s, i) => <InventorySlot key={i}/>)}
                            </div>
                        </div>
                        {store.inventoryInMarket && store.inventoryInMarket.length > 0 &&
                    <div className="hero__slots-content">
                        <div className="hero__slots-title">{I18n.t('on_sell')}</div>
                        <div className="hero__slot-wrap">
                            {store.inventoryInMarket.map(a => <InventorySlot artifact={a} key={a?.id}/>)}
                        </div>
                    </div>}
                        {tradeStore.steamTradesOnApproval && tradeStore.steamTradesOnApproval.length > 0 &&
                    <div className="hero__slots-content">
                        <div className="hero__slots-title">{I18n.t('on_approval')}</div>
                        <div className="hero__slot-wrap">
                            {tradeStore
                                .steamTradesOnApproval
                                .map(a => <SteamTradeSlot steamTrade={a} key={`steam-trade-${a?.id}`}/>)
                            }
                        </div>
                    </div>}
                        {this.props.readOnly && <div className="hero__inventory--exchange--btn-wrap">
                            <button
                                className={`btn btn--invert btn--big-on-mobile${ tradeEnabled ? '' : ' btn--disable'}`}
                                onClick={this.openTradePage}
                                disabled={!tradeEnabled}
                            >
                                {I18n.t('trade_offer')}
                            </button>
                        </div>}
                    </div>
                </div>
                {!this.props.readOnly && <Crafting/>}
            </div>;
    }
}

export default withRouter(inject('artifactStore', 'steamTradeStore')(observer(Hero)));
