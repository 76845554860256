import React, {Component} from 'react';
import {modalAlert, modalConfirm} from 'app/utils/modals/popups';
import I18n from 'app/i18n';
import {artifactDescription, artifactName} from 'app/components/my_profile/artifacts/utils';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import {inject, observer} from 'mobx-react';
import ComponentRecipe from './component_recipe';

const TabContent = inject('artifactStore')(observer(class TabContent extends Component {
    state = {error: undefined};
    craft = () => {
        if (!this.canCraft()) {
            return;
        }

        const {recipe} = this.props;
        this.handleError(undefined);
        modalConfirm(I18n.t('disassemble_confirm', {points: `<span class="price">${recipe.craft_points}</span>`}), this.assemble);
    };

    assemble = () => {
        this.props.artifactStore.assemble(this.props.recipe.id, this.onSuccess, this.handleError);
    };

    onSuccess = () => {
        modalAlert(I18n.t('assemble_success', {name: artifactName(this.props.recipe)}));
    };

    handleError = error => this.setState({error: error});

    canCraft = () => this.props.artifactStore.hasComponentsForCraft(this.props.recipe);

    render() {
        const {recipe} = this.props;

        if (!recipe) {
            return null;
        }

        const {error} = this.state;

        return <div className="tabs__content-item">
            <div className="crafting__item">
                <div className="crafting__item-content">
                    <div className="crafting__item--title">{artifactName(recipe)}</div>
                    <div className="crafting__item--actions">
                        <div className="crafting__item--price">{I18n.t('egb_points', {amount: recipe.craft_points})}</div>
                        <button className={'btn btn--invert' + (this.canCraft() ? '' : ' btn--disable')} onClick={this.craft}>
                            {I18n.t('create')}
                        </button>
                    </div>
                    {error && <div className="crafting__item--message crafting__item--message-error">
                        <svg height="21px" width="19px">
                            <use xlinkHref="#svg-warning"/>
                        </svg>
                        <div className="crafting__item--message--content">{error}</div>
                    </div>}
                    <div className="crafting__item--overview">
                        <div className="crafting__item--overview-title">{I18n.t('overview')}</div>
                        <div className="crafting__item--overview-text">
                            {artifactDescription(recipe)}
                        </div>
                    </div>
                    <div className="crafting__item--parts-wrap">
                        <div className="crafting__item--parts-title">{I18n.t('required_skins')}</div>
                        <div className="crafting__item--parts">
                            {recipe.components.map(r => <ComponentRecipe key={r.id} recipe={r}/>)}
                        </div>
                    </div>
                </div>
                <div className="crafting__item--img-wrap simple-image">
                    <div className="crafting__item--img">
                        <SimpleArtifactImage artifact={recipe}/>
                    </div>
                </div>
            </div>
        </div>;
    }
}));

export default TabContent;
