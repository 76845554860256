import $ from 'jquery';
import {Component} from 'react';
import logEvent from 'app/amplitude/log_event';
import {toastError} from 'app/components/toasts/liteToast';
import I18n from 'app/i18n';
import {DepositsStoreProps} from 'app/store/DepositsStore';
import {ErrorReturnType} from 'app/components/deposit/InputError';
import {sendGaFirstDepositEvent} from 'app/utils/deposits/sendGaFirstDepositEvent';

const ONE_SECOND = 1_000;

export type ResponseType = {
    id: number
    success: boolean
    url: string
    errors: ErrorReturnType
    start_result: {
        url: string
        parameters: Map<string, string> | {name: string, value: string}[]
    }
};

type CreateParams = {
    amount: string
    promo_code: string
    wallet_id: number
    bonus: number
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default class DepositForm extends Component<DepositsStoreProps, any> {
    submitRedirect(startUrl: string, readyUrl: string, params = {}): void {
        this.props.depositsStore.startSubmitting();
        $.ajax(startUrl, {
            data: this.createParams({...params, return_url: location.href}),
            error: this.handleError,
            success: response => this.handleSuccess(response, startUrl, readyUrl),
            type: 'POST'
        });
    }

    newTab = (): boolean => false;

    handleError = (message: string): void => {
        const errorMessage = typeof message === 'string' ? message : I18n.t('server_error_try_later');
        logEvent('INVOICE_FORM_FAILURE', {responseJSON: {errors: errorMessage}});

        toastError(errorMessage);
        this.props.depositsStore.stopSubmitting();
    };

    handleSuccess(response: ResponseType, startUrl: string, readyUrl: string): void {
        if (!response.success) {
            this.props.depositsStore.stopSubmitting(response.errors);
            return;
        }

        const checkRedirect = readyUrl
            ? this.checkRedirect.bind(this, response.id, readyUrl)
            : () => {
                this.openUrl(response.start_result.url);
            };

        if (this.instant()) {
            this.instantDeposit(response);
        } else {
            checkRedirect();
        }

        sendGaFirstDepositEvent();
    }

    instant(): boolean {
        return false;
    }

    instantDeposit(response: ResponseType): void {
        console.log(response);
        throw new Error();
    }

    createParams(params = {}): CreateParams {
        const {amount, promoCode, wallet_id, bonus_type} = this.props.depositsStore;
        return {
            amount,
            bonus: bonus_type,
            promo_code: promoCode,
            wallet_id,
            ...params
        };
    }

    openUrl = (url: string): void => {
        if (this.newTab()) {
            this.props.depositsStore.resetInputs();
            window.open(
                url,
                '_blank',
                'menubar=no,toolbar=no,resizable=yes,scrollbars=yes'
            );
        } else {
            location.href = url;
        }
    };

    handleSuccessResponse = (response: Response, intervalTimerId: number): void => {
        window.clearInterval(intervalTimerId);
        this.openUrl(response.url);
    };

    checkRedirect(id: number, readyUrl: string): void {
        const interval = window.setInterval(() => $.ajax(readyUrl, {
            data: {id},
            error: () => {
                this.handleError(null);
                window.clearInterval(interval);
            },
            success: response => {
                if (!response.success) {
                    window.clearInterval(interval);
                    this.handleError(response.message);
                    return;
                }
                if (response.url) {
                    this.handleSuccessResponse(response, interval);
                }
            }
        }), ONE_SECOND);
    }
}
