import {RestApiService} from 'app/services/RestApiService';
import BaseValidator from 'validators/BaseValidator';
import {action, makeObservable, observable, runInAction} from 'mobx';
import {UNPROCESSABLE_ENTITY} from 'app/utils/HttpStatuses';
import ErrorsServerInterface from 'app/interfaces/ErrorsServerInterface';

export class BaseModel<Attributes> {
    url = '/';

    paramsKey?: string;

    Validator: new (attributes: Attributes) => BaseValidator<Attributes>;

    SportValidator: new (attributes: Attributes) => BaseValidator<Attributes>;

    @observable errors: ErrorsServerInterface = null;

    @observable attributes: Attributes & {id?: number, esports?: boolean} = null;

    apiService: RestApiService;

    updateText?: string;

    constructor(attributes: Attributes) {
        this.setAttribute(attributes);
        makeObservable(this);
        this.apiService = new RestApiService(this.url);
    }

    isNewRecord(): boolean {
        return this.attributes.id <= 0;
    }

    @action
    setAttribute(attributes: Attributes): void {
        this.attributes = attributes;
    }

    @action
    setErrors(errors: ErrorsServerInterface): void {
        this.errors = errors;
    }

    @action
    load(): Promise<Attributes> {
        return this.apiService.show(this.attributes.id.toString())
            .then(response => response.json())
            .then<Attributes>(data => {
                this.setAttribute(data);
                return data;
            });
    }

    postParams(): Record<string, unknown> {
        return this.paramsKey ? {[this.paramsKey]: this.attributes} : this.attributes;
    }

    postSettingsParams(): Record<string, unknown> {
        const settingAttributes = {
            coef_changing: (this.attributes as { coef_changing?: number }).coef_changing,
            consider_personal_limits: (this.attributes as { consider_personal_limits?: number }).consider_personal_limits,
            delay_for_bets_time: (this.attributes as { delay_for_bets_time?: number }).delay_for_bets_time,
            hide_creator: (this.attributes as { hide_creator?: number }).hide_creator
        };
        return this.paramsKey ? {[this.paramsKey]: settingAttributes} : settingAttributes;
    }

    get id(): number {
        return this.attributes.id;
    }

    defineValidator(esports: boolean): BaseValidator<Attributes> {
        if (esports === true) {
            return new this.Validator(this.attributes);
        }
        return new this.SportValidator(this.attributes);
    }

    @action
    async save(isSettings = false): Promise<{response: Response, attributes: Attributes}> {
        let response: Response | null = null;
        if (isSettings) {
            response = await this.updateSetting();
        } else {
            this.validation();
            response = await this.updateOrCreate();
        }

        const json = await response.json() as Attributes;

        if (response.status === UNPROCESSABLE_ENTITY) {
            throw json;
        }

        if (response.ok) {
            runInAction(() => {
                this.attributes = json;
            });
        }
        return {attributes: json, response};
    }

    validation = (): void => {
        const validator = this.defineValidator(this.attributes.esports);

        if (!validator.isValid(this.attributes.esports)) {
            this.setErrors(validator.errors);
            throw validator.errors;
        }
    };

    updateOrCreate = (): Promise<Response> => this.id
        ? this.apiService.update(this.id.toString(), this.postParams())
        : this.apiService.create(this.postParams());

    updateSetting = (): Promise<Response> => this.apiService.update(this.id.toString(), this.postSettingsParams());
}
