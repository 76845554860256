import React, {ReactNode, MouseEvent} from 'react';
import I18n from 'app/i18n';

interface Props {
    maxPlayers: number
    currentPlayers: number
    tableId: string
    openTable: (table_id: string) => void
}

export default class AsidePokerItem extends React.Component<Props> {
    onClickPlayButton = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        this.props.openTable(this.props.tableId);
    };

    render(): ReactNode {
        const {maxPlayers, currentPlayers} = this.props;

        return <div className="poker-table-item">
            <span className="game-about">NL 10</span>
            <span className="game-players">({currentPlayers}/{maxPlayers} {I18n.t('poker.players')})</span>
            <button className="button-adaptive button-adaptive_instyle" onClick={this.onClickPlayButton}>{I18n.t('play')}</button>
        </div>;
    }
}
