import React from 'react';

let recaptchaLoadAttemptsLeft = 6;

const isRecaptchaAvailable = () => window.grecaptchaReady;

export default class Captcha extends React.PureComponent {
    state = {egbCaptcha: null, recaptchaId: null};

    componentDidMount() {
        setTimeout(this.initializeRecaptcha, 100);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.reset !== this.props.reset && nextProps.reset) {
            if (this.state.recaptchaId !== null) {
                window.grecaptcha.reset(this.state.recaptchaId);
            }
        }
    }

    componentWillUnmount() {
        if (this.state.recaptchaId) {
            window.grecaptcha.reset(this.state.recaptchaId);
        }
    }

    initializeRecaptcha = () => {
        if (isRecaptchaAvailable()) {
            const recaptchaId = window.grecaptcha.render(this.recaptchaRef, {
                callback: this.props.onPass,
                sitekey: document.querySelector('meta[name="Recaptcha"]').content,
                tabindex: 0,
                theme: 'dark'
            });
            this.setState({recaptchaId});
        } else if (--recaptchaLoadAttemptsLeft >= 0) {
            setTimeout(this.initializeRecaptcha, 250);
        }
    };

    setRecaptchaRef = dom => this.recaptchaRef = dom;

    render() {
        return <div ref={this.setRecaptchaRef}/>;
    }
}
