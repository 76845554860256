
export const ONE_SECOND_MS = 1000;
export const HALF_OF_SECOND_MS = 500;
export const TEN_SECONDS = 10;
export const TIME_OUT = TEN_SECONDS * ONE_SECOND_MS;
export const ONE_YEAR_DAYS = 365;
export const FIVE_YEARS = 5;
export const COOKIES_EXPIRATION_TIME = ONE_YEAR_DAYS * FIVE_YEARS;

export const INDEX_OF_NOT_EXIST = -1;
export const THIRD_INDEX = 3;
export const FOURTH_INDEX = 4;
export const FIFTH_INDEX = 5;
